import { gql } from '@apollo/client'

import {
  ARCHIVE_DOG_PROFILE_ACTION_DOG_FRAGMENT,
  ARCHIVE_DOG_PROFILE_ACTION_QUERY_FRAGMENT
} from '../components/ArchiveDogProfileAction/fragments/archiveDogProfileActionFragment'
import { DAILY_PORTION_SIZE_FRAGMENT } from '../components/DailyPortionSize/fragments/dailyPortionSizeFragment'
import { DOG_PROFILE_PICTURE_FRAGMENT } from '../components/DogProfilePicture/fragments/dogProfilePictureFragment'
import { DOG_SELECTION_FRAGMENT } from '../components/DogSelection/fragments/dogSelectionFragment'
import { SHARE_PROFILE_FRAGMENT } from '../components/ShareProfile/fragment/shareProfileFragment'
import { RAF_PASS_FRAGMENT } from '../components/ShareRafPass/fragment/shareRafPassFragment'

const DOG_PROFILE_QUERY = gql`
  ${SHARE_PROFILE_FRAGMENT}
  ${RAF_PASS_FRAGMENT}
  ${DAILY_PORTION_SIZE_FRAGMENT}
  ${DOG_SELECTION_FRAGMENT}
  ${DOG_PROFILE_PICTURE_FRAGMENT}
  ${ARCHIVE_DOG_PROFILE_ACTION_QUERY_FRAGMENT}
  ${ARCHIVE_DOG_PROFILE_ACTION_DOG_FRAGMENT}
  query DogProfileQuery(
    $caloriesMultipleOf: Int!
    $percentageMultipleOf: Int!
    $lowerPercentage: Int!
    $upperPercentage: Int!
  ) {
    user {
      id
      ...DailyPortionSizeFragment
      firstName
      shippingCountryCode
      preferredLanguage
      shippingCountry {
        id
        showCare
        vetNurseCalendlyUrl
      }
      dogs {
        id
        gender
        eaterType
        snackingHabits
        weightInGrams
        bodyShape
        exercise
        neutered
        healthIssues {
          id
          name
        }
        allergens {
          id
          name
        }
        recommendedCareDetails {
          careType
        }
        ...DogSelectionFragment
        ...DogProfilePictureFragment
        ...ArchiveDogProfileActionDogFragment
      }
      ...ShareProfileFragment
      ...ShareRafPassFragment
    }
    ...ArchiveDogProfileActionQueryFragment
  }
`

export { DOG_PROFILE_QUERY }
