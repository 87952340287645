// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './EditDeliveryFrequencyBenefit.module.sass'

import ModalLayout from '../../layout'

type Props = {
  benefitType: string
}

const EditDeliveryFrequencyBenefit = ({ benefitType }: Props): JSX.Element => {
  const { t } = useTranslation('modals')

  return (
    <ModalLayout
      title={{
        text: `edit_delivery_frequency_benefit.${benefitType}.title`,
        namespace: 'modals'
      }}
    >
      <div className={STYLES.content}>
        <Image
          alt={t('edit_delivery_frequency_benefit.img_alt')}
          slug="open-box-of-butternut-meals-illustration"
          image={{
            width: 70,
            height: 70,
            resizeMode: 'resize_to_fill',
            retina: true
          }}
        />
        <Text
          text={`edit_delivery_frequency_benefit.${benefitType}.description`}
          namespace="modals"
          margin={false}
        />
      </div>
    </ModalLayout>
  )
}

export type { Props }

export { EditDeliveryFrequencyBenefit }
