// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/elements/atoms/Image/Image'
import ModalTrigger from '@/components/modals/trigger'

import STYLES from './HowToFeedModalTrigger.module.sass'

import type { SubscriptionOverviewFragment_subscription_plan_servingInformation as ServingInformation } from '../../fragments/__generated__/SubscriptionOverviewFragment'

type Props = {
  data: {
    firstDogName: string
    dogsAmount: number
    pouchSize: ServingInformation['servingSize']
    pouchesPerDay: ServingInformation['pouchesPerServing']
  }
}

const HowToFeedModalTriggerRightAdornment = (): JSX.Element => {
  const { t } = useTranslation('modals')

  return (
    <Image
      alt={t('how_to_feed.trigger.img_alt')}
      slug="duck-and-chicken-bowl"
      image={{
        width: 32,
        height: 32
      }}
    />
  )
}

const HowToFeedModalTrigger = ({ data }: Props): JSX.Element => (
  <ModalTrigger
    className={STYLES.container}
    modalId="how-to-feed-modal"
    text={{
      text: 'how_to_feed.trigger.title',
      namespace: 'modals',
      margin: false
    }}
    rightAdornment={HowToFeedModalTriggerRightAdornment}
    backgroundColor="brandYellow100"
    screenIdentifier="homeScreen"
    modalOptions={{ data }}
  />
)

export { HowToFeedModalTrigger }
