import { gql } from '@apollo/client'

const HOW_TO_FEED_MODAL_TRIGGER_DOG_FRAGMENT = gql`
  fragment HowToFeedModalTriggerDogFragment on Dog {
    id
    name
  }
`

const HOW_TO_FEED_MODAL_TRIGGER_PLAN_FRAGMENT = gql`
  fragment HowToFeedModalTriggerPlanFragment on Plan {
    id
    servingInformation {
      gramsPerDay
      numberOfServings
      pouchesPerServing
      roundedCaloriesPerDay
      servingSize
      servingType
    }
  }
`
export {
  HOW_TO_FEED_MODAL_TRIGGER_DOG_FRAGMENT,
  HOW_TO_FEED_MODAL_TRIGGER_PLAN_FRAGMENT
}
