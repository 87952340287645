// @noflow
import { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import React from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import Text from '@/components/elements/atoms/Text/Text'
import AdditionalProductItem from '@/components/elements/molecules/BoxItem/AdditionalProductItem/AdditionalProductItem'
import FreshMealList from '@/components/elements/organisms/FreshMealList/FreshMealList'

import STYLES from './OrderSummaryTab.module.sass'

import { Code as CountryCode } from '@/types'

import { checkoutPageState } from '../../../../CheckoutPage'
import { checkoutPricingState } from '../../../../hooks/useCheckoutPricing'
import OrderSummaryRowItem from '../OrderSummaryRowItem/OrderSummaryRowItem'

type Props = {
  namespace: string
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  shouldDisplayPrice: boolean
}

const OrderSummaryTab = ({
  namespace,
  shippingCountryCode,
  preferredLanguage,
  shouldDisplayPrice
}: Props): JSX.Element => {
  const copyContext = 'order_summary'

  const { firstOrderPricing, standardOrderPricing, productVariants } =
    useReactiveVar(checkoutPricingState)

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const { plan, user } = useReactiveVar(checkoutPageState)
  const { trialLengthInDays, pouchesPerDay } = plan
  const { dogs, funnelGiftCampaign } = user

  const {
    netTotalPrice,
    netCoreFoodPrice,
    totalSavedAmount,
    netDeliverySurchargePrice,
    flavourPricings,
    standardOrderCoreFoodGrossPrice
  } = firstOrderPricing

  const { netDeliverySurchargePrice: standardNetDeliverySurchargePrice } =
    standardOrderPricing

  const totalPouches = trialLengthInDays * pouchesPerDay

  const hasFreeDelivery = netDeliverySurchargePrice === 0

  const savingsApplied = totalSavedAmount > 0

  const price = shouldDisplayPrice ? netTotalPrice : 0

  const orderSummaryMeals =
    flavourPricings?.map((recipe) => {
      return {
        quantity: recipe.servings,
        price: recipe.grossStandardOrderPricePerServing * recipe.servings,
        discountedPrice: recipe.netFirstOrderPricePerServing * recipe.servings,
        flavour: {
          productLabels: [],
          name: recipe.flavourName,
          thumbnail:
            plan.flavours.find(
              ({ flavour }) => flavour.name === recipe.flavourName
            )?.flavour.primaryImage.src || '',
          slug: recipe.flavourName
        }
      }
    }) || []
  return (
    <>
      <div className={STYLES.cardContent}>
        <FreshMealList
          recipes={orderSummaryMeals}
          config={{ imageSize: 'medium', noBorder: true }}
          shippingCountryCode={shippingCountryCode}
          language={preferredLanguage}
          shouldSeeRecipeSurchargeTooltips={false}
          shouldSeeRecipeSurchargePriceIncreasePl={false}
          perDogLabel={dogs.length > 1}
        />
        {productVariants?.map((product) => (
          <AdditionalProductItem
            thumbnail={
              product.productVariant.productCollection.merchandisingImage.src
            }
            quantity={1} // You can only add one of each AP item in plans flow
            size={product.productVariant.name}
            key={product.productVariant.productCollection.name}
            language={preferredLanguage}
            shippingCountryCode={shippingCountryCode}
            name={product.productVariant.productCollection.name}
            price={product.productVariant.recurringDeliveryType?.netPrice || 0}
            discountedPrice={product.discountedPrice || undefined}
            config={{ imageSize: 'medium', noBorder: true }}
          />
        ))}
        {funnelGiftCampaign && funnelGiftCampaign.productVariant && (
          <AdditionalProductItem
            thumbnail={
              funnelGiftCampaign.productVariant.productCollection
                .merchandisingImage.src
            }
            quantity={1} // We only gift users one free product
            size={funnelGiftCampaign.productVariant.name}
            key={funnelGiftCampaign.productVariant.productCollection.name}
            language={preferredLanguage}
            shippingCountryCode={shippingCountryCode}
            name={funnelGiftCampaign.productVariant.productCollection.name}
            price={
              funnelGiftCampaign.productVariant.recurringDeliveryType?.netPrice
            }
            discountedPrice={0} // Funnel Gift Campaign products are free
            config={{ imageSize: 'medium', noBorder: true }}
          />
        )}
        <div className={STYLES.divider} />
        <div>
          <OrderSummaryRowItem
            label={{
              namespace,
              text: `${copyContext}.summary.total_pouches`,
              variables: {
                totalPouches
              }
            }}
            price={
              savingsApplied
                ? standardOrderCoreFoodGrossPrice
                : netCoreFoodPrice
            }
            discountedPrice={savingsApplied ? netCoreFoodPrice : null}
            locale={locale}
            currency={currency}
          />
          {productVariants?.map((product) => (
            <OrderSummaryRowItem
              key={product.productVariant.productCollection.name}
              label={{
                translate: false,
                text: product.productVariant.productCollection.name
              }}
              price={
                product.productVariant.recurringDeliveryType?.netPrice || 0
              }
              discountedPrice={product.discountedPrice}
              locale={locale}
              currency={currency}
            />
          ))}
          {funnelGiftCampaign && funnelGiftCampaign.productVariant && (
            <OrderSummaryRowItem
              key={funnelGiftCampaign.productVariant.productCollection.name}
              label={{
                translate: false,
                text: funnelGiftCampaign.productVariant.productCollection.name
              }}
              price={
                funnelGiftCampaign.productVariant.recurringDeliveryType
                  ?.netPrice || 0
              }
              discountedPrice={0}
              locale={locale}
              currency={currency}
              isFree
            />
          )}
          <OrderSummaryRowItem
            label={{
              namespace,
              text: `${copyContext}.summary.delivery`
            }}
            price={standardNetDeliverySurchargePrice}
            discountedPrice={netDeliverySurchargePrice}
            locale={locale}
            currency={currency}
            isFree={hasFreeDelivery}
          />
        </div>
      </div>
      <div className={STYLES.footerContent}>
        <div className={STYLES.footerTitle}>
          <Text
            text={`${copyContext}.box_total`}
            namespace={namespace}
            variant="display20"
            margin={false}
          />
          <Text
            text={formatCurrencyWithDecimal(price, {
              locale,
              currency
            })}
            translate={false}
            variant="display20"
            margin={false}
          />
        </div>
        <Text
          text={`${copyContext}.total_savings`}
          variables={{
            totalDiscountedAmount: formatCurrencyWithDecimal(totalSavedAmount, {
              locale,
              currency
            })
          }}
          namespace={namespace}
          margin={false}
          align="left"
        />
      </div>
    </>
  )
}

export default OrderSummaryTab
