import { registerModal } from '@/context/modalProvider'

import {
  EditDeliveryFrequencyBenefit,
  type EditDeliveryFrequencyBenefitProps
} from './components/EditDeliveryFrequencyBenefit'
import { HowToFeed, type HowToFeedProps } from './components/HowToFeed'
import { OrderSummaryModal } from '@/components/modals/OrderSummaryModal'

registerModal('order-summary-modal', OrderSummaryModal)
registerModal<HowToFeedProps>('how-to-feed-modal', HowToFeed)
registerModal<EditDeliveryFrequencyBenefitProps>(
  'edit-delivery-frequency-benefit-modal',
  EditDeliveryFrequencyBenefit
)
