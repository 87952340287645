// @noflow
import { useQuery } from '@apollo/client'
import React from 'react'

import useReactivationNotification from '@/hooks/useReactivationNotification'

import { ErrorState } from '@/components/elements/organisms/ErrorState'

import { DOG_PROFILE_QUERY } from './queries/dogProfileQuery'

import type { DogProfileV2Query } from './queries/__generated__/DogProfileV2Query'

import { DogProfileContent } from './DogProfileContent'

const DogProfile = (): JSX.Element | null => {
  useReactivationNotification()

  const { loading, data, error, refetch } = useQuery<DogProfileV2Query>(
    DOG_PROFILE_QUERY,
    {
      variables: {
        caloriesMultipleOf: 10,
        percentageMultipleOf: 5,
        lowerPercentage: 90,
        upperPercentage: 110,
        limit: 5,
        status: ['confirmed', 'pending', 'pending_cancellation', 'cancelled']
      }
    }
  )

  // TODO: Use correct error state component
  if (!loading && error) {
    return (
      <ErrorState
        error={{
          name: 'Error retrieving dog profile',
          message: error.message,
          apollo: error
        }}
      />
    )
  }

  return <DogProfileContent {...data} loading={loading} refetch={refetch} />
}

export { DogProfile }
