import {
  ClientInitQuery_user_subscription_alreadyRedeemedReactivationCampaign,
  ClientInitQuery_user_subscription_alreadyRedeemedReactivationCampaign_discountCode,
  ClientInitQuery_user_subscription_alreadyRedeemedReactivationCampaign_image,
  ClientInitQuery_user_subscription_defaultReactivationCampaign,
  ClientInitQuery_user_subscription_plan_downsizeablePlan,
  ClientInitQuery_user_subscription_plan_swappablePlan
} from '@/components/pages/App/queries/__generated__/ClientInitQuery'

import {
  DiscountCodePartDiscount,
  DiscountCodePartDiscountBasis
} from '@/types'

type DefaultReactivationCampaignObject = {
  name: string
  isActive: boolean
  expiryDate: null
  currentUserIsEligible: boolean
  redirectionEndpoint: string
  description: string
  image: ClientInitQuery_user_subscription_alreadyRedeemedReactivationCampaign_image
  discountCode: ClientInitQuery_user_subscription_alreadyRedeemedReactivationCampaign_discountCode
}

type DefaultClientInitSubscription = {
  __typename: 'Subscription'
  deliveriesReceived: 0
  nextNBoxes: []
  showOnboardingQuiz: boolean
  plan: {
    __typename: 'Plan'
    swappablePlan: ClientInitQuery_user_subscription_plan_swappablePlan | null
    downsizeablePlan: ClientInitQuery_user_subscription_plan_downsizeablePlan | null
  }
  paymentMethods: []
  defaultReactivationCampaign: {
    __typename: 'ReactivationCampaign'
  } & ClientInitQuery_user_subscription_defaultReactivationCampaign
  alreadyRedeemedReactivationCampaign: {
    __typename: 'ReactivationCampaign'
  } & ClientInitQuery_user_subscription_alreadyRedeemedReactivationCampaign
}

const defaultReactivationCampaignObject: DefaultReactivationCampaignObject = {
  name: '',
  isActive: false,
  expiryDate: null,
  currentUserIsEligible: false,
  redirectionEndpoint: '',
  description: '',
  image: {
    __typename: 'Image',
    src: ''
  },
  discountCode: {
    __typename: 'DiscountCode',
    id: '',
    code: '',
    discountCodeParts: [
      {
        __typename: 'DiscountCodePart',
        value: 0,
        discountBasis: DiscountCodePartDiscountBasis.percentage,
        discountType: DiscountCodePartDiscount.n_boxes,
        n: 0
      }
    ]
  }
}

const defaultClientInitSubscription: DefaultClientInitSubscription = {
  __typename: 'Subscription',
  deliveriesReceived: 0,
  nextNBoxes: [],
  showOnboardingQuiz: false,
  plan: {
    __typename: 'Plan',
    swappablePlan: null,
    downsizeablePlan: null
  },
  paymentMethods: [],
  defaultReactivationCampaign: {
    __typename: 'ReactivationCampaign',
    ...defaultReactivationCampaignObject
  },
  alreadyRedeemedReactivationCampaign: {
    __typename: 'ReactivationCampaign',
    ...defaultReactivationCampaignObject
  }
}

export { defaultReactivationCampaignObject, defaultClientInitSubscription }
