import { ACCOUNT_ROUTES } from '@/routes'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  SectionWrapper,
  SectionWrapperProps
} from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'

import { DeliveryFrequencyOverviewFragment } from './fragments/__generated__/DeliveryFrequencyOverviewFragment'

type Props = {
  boxId: string | undefined
  loading?: boolean
  margin?: SectionWrapperProps['margin']
} & Pick<
  DeliveryFrequencyOverviewFragment,
  'durationInWeeks' | 'numberOfPouches'
>

const DeliveryFrequencyOverview = ({
  boxId,
  numberOfPouches,
  durationInWeeks,
  loading = false,
  margin
}: Props): React.JSX.Element => {
  const navigate = useNavigate()

  const wrapperProps = {
    margin: margin ?? { top: 0, bottom: 0 }
  }

  const openDeliveryFrequency = (): void => {
    if (!boxId) return

    navigate(`${ACCOUNT_ROUTES.editDeliveryFrequency}/${boxId}`)
  }

  return (
    <SectionWrapper {...wrapperProps}>
      <ActionCard
        skeleton={{ isLoading: loading, height: '8rem' }}
        button={{
          text: {
            namespace: 'molecules',
            text: 'delivery_frequency_overview.cta',
            translate: true
          },
          onClick: openDeliveryFrequency
        }}
        padding={16}
        dataTestId="delivery-frequency-overview-card-content"
      >
        <Text
          text="delivery_frequency_overview.duration_in_weeks"
          namespace="molecules"
          variables={{
            durationInWeeks,
            count: durationInWeeks
          }}
          bold
          margin={false}
        />
        <Text
          text="delivery_frequency_overview.number_of_pouches"
          namespace="molecules"
          variables={{
            numberOfPouches
          }}
          margin={false}
        />
      </ActionCard>
    </SectionWrapper>
  )
}

export { DeliveryFrequencyOverview }
