import React from 'react'

import Text from '@/components/elements/atoms/Text'

import STYLES from './LineItem.module.sass'

type ProductPriceProps = {
  free?: boolean
  grossPrice?: string
  price: string
  discountedPrice: string | null
}

const ProductPrice = ({
  free,
  grossPrice,
  price,
  discountedPrice
}: ProductPriceProps): JSX.Element => {
  const shouldDisplayDiscountedPrice =
    (discountedPrice && discountedPrice !== price) ||
    (grossPrice && grossPrice !== price)

  const discountedPriceToShow = discountedPrice || price

  return (
    <div className={STYLES.price}>
      {free ? (
        <Text
          text="shop.free"
          margin={false}
          variant="textRegular14"
          colour="brandBlue400"
          namespace="order"
        />
      ) : shouldDisplayDiscountedPrice ? (
        <React.Fragment>
          <Text
            text="shop.price_each"
            namespace="order"
            margin={false}
            variables={{ price: discountedPriceToShow }}
            colour={'brandRed500'}
            bold
          />
          <Text
            text={`<accent type="strikeTrough">${grossPrice}</accent>`}
            margin={false}
            colour="brandBlue500"
            variant="textRegular14"
            translate={false}
          />
        </React.Fragment>
      ) : (
        <Text
          text="shop.price_each"
          namespace="order"
          variables={{ price }}
          margin={false}
          colour="brandBlue500"
        />
      )}
    </div>
  )
}

export default ProductPrice
