import { useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'

import { featureFlagsDataVar, userDataVar } from '@/services/apollo'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'

import {
  RAF_REMINDER_COOKIE_NAME,
  RAF_REMINDER_COOKIE_VALUE_SEEN
} from '@/components/elements/molecules/RafDiscountReminderModal/RafDiscountReminderModal'
import { DashboardQuery } from '@/components/pages/Dashboard/queries/__generated__/DashboardQuery'
import { DashboardV3Query } from '@/components/pages/DashboardV3/queries/__generated__/DashboardV3Query'
import { TreatmentsPausedPageQuery } from '@/components/pages/TreatmentsPausedPage/queries/__generated__/TreatmentsPausedPageQuery'

import { SubscriptionStatus } from '@/types'

type UseRAFDiscountReminder = {
  formattedDiscount: string
  shouldSeeRafDiscountModal: boolean
}

const useRAFDiscountReminder = (
  data:
    | DashboardV3Query
    | DashboardQuery
    | TreatmentsPausedPageQuery
    | undefined
): UseRAFDiscountReminder => {
  const user = useReactiveVar(userDataVar)

  const { shouldSeeRafDiscountReminderModal } =
    useReactiveVar(featureFlagsDataVar) || {}

  let box

  if (user?.subscription.status !== SubscriptionStatus.active) {
    box = data?.user.subscription.previewUncreatedFutureBox
  } else {
    box = data?.user.subscription.nextEditableBox
  }

  let discount = box?.discountValue || 0
  discount = Math.floor(discount / 100)

  const hasDiscount = discount > 0

  const { shippingCountry } = data?.user || {}

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountry?.code || 'GB',
    data?.user?.preferredLanguage || 'en'
  )

  const formattedDiscount = discount.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const alreadySeenRafReminder =
    Cookies.get(RAF_REMINDER_COOKIE_NAME) === RAF_REMINDER_COOKIE_VALUE_SEEN

  const shouldSeeRafDiscountModal =
    !alreadySeenRafReminder &&
    hasDiscount &&
    shouldSeeRafDiscountReminderModal === 'variant'

  return { formattedDiscount, shouldSeeRafDiscountModal }
}

export default useRAFDiscountReminder
