import { gql } from '@apollo/client'

const SMS_DELIVERY_REMINDER_QUERY = gql`
  query SMSDeliveryReminderQuery {
    user {
      id
      shouldSendSmsDeliveryReminder
    }
  }
`

export { SMS_DELIVERY_REMINDER_QUERY }
