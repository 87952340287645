import React from 'react'

import ButternutBoxSprite from 'assets/images/illustrations/loading-screens/meal-pouch-with-bowl-and-yellow-hearts-illustration--sprite.svg'

import SuccessOverlay from '@/components/elements/organisms/SuccessOverlay/SuccessOverlay'

type Props = {
  handleSuccessClose: () => void
}

const SubscriptionConfirmation = ({
  handleSuccessClose
}: Props): JSX.Element => {
  return (
    <SuccessOverlay
      handleClose={handleSuccessClose}
      sprite={ButternutBoxSprite}
      dataTestId="subscription-confirmation-overlay"
      typography={{
        namespace: 'account',
        text: 'thank_you_page_v3.order_confirmed',
        margin: false
      }}
    />
  )
}

export { SubscriptionConfirmation }
