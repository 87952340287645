import { gql } from '@apollo/client'

import {
  ARCHIVE_DOG_PROFILE_ACTION_DOG_FRAGMENT,
  ARCHIVE_DOG_PROFILE_ACTION_QUERY_FRAGMENT
} from '../../components/ArchiveDogProfileAction/fragments/archiveDogProfileActionFragment'
import { DOG_PROFILE_PICTURE_FRAGMENT } from '../../components/DogProfilePicture/fragments/dogProfilePictureFragment'
import { DOG_SELECTION_FRAGMENT } from '../../components/DogSelection/fragments/dogSelectionFragment'
import { SHARE_PROFILE_FRAGMENT } from '../../components/ShareProfile/fragment/shareProfileFragment'
import { RAF_PASS_FRAGMENT } from '../../components/ShareRafPass/fragment/shareRafPassFragment'
import {
  HOW_TO_FEED_MODAL_TRIGGER_DOG_FRAGMENT,
  HOW_TO_FEED_MODAL_TRIGGER_PLAN_FRAGMENT
} from '@/components/elements/molecules/HowToFeedModalTrigger/fragments/howToFeedModalFragment'

import { DOG_PROFILE_MEDICAL_FRAGMENT } from '../fragments/dogProfileMedicalFragment'
import { DOG_PROFILE_PERSONALITY_DETAILS_FRAGMENT } from '../fragments/dogProfilePersonalityDetailsFragment'
import { DOG_PROFILE_WEIGHT_AND_APPETITE_FRAGMENT } from '../fragments/dogProfileWeightAndAppetiteFragment'

const DOG_PROFILE_QUERY = gql`
  ${SHARE_PROFILE_FRAGMENT}
  ${RAF_PASS_FRAGMENT}
  ${HOW_TO_FEED_MODAL_TRIGGER_DOG_FRAGMENT}
  ${HOW_TO_FEED_MODAL_TRIGGER_PLAN_FRAGMENT}
  ${DOG_SELECTION_FRAGMENT}
  ${DOG_PROFILE_PICTURE_FRAGMENT}
  ${DOG_PROFILE_PERSONALITY_DETAILS_FRAGMENT}
  ${DOG_PROFILE_MEDICAL_FRAGMENT}
  ${DOG_PROFILE_WEIGHT_AND_APPETITE_FRAGMENT}
  ${ARCHIVE_DOG_PROFILE_ACTION_QUERY_FRAGMENT}
  ${ARCHIVE_DOG_PROFILE_ACTION_DOG_FRAGMENT}
  query DogProfileV2Query($caloriesMultipleOf: Int!) {
    user {
      id
      firstName
      shippingCountryCode
      preferredLanguage
      shippingCountry {
        id
        showCare
        vetNurseCalendlyUrl
      }
      subscription {
        id
        plan {
          id
          ...HowToFeedModalTriggerPlanFragment
        }
      }
      dogs {
        id
        ...DogProfilePictureFragment
        ...DogProfilePersonalityDetailsFragment
        ...DogProfileMedicalFragment
        ...DogProfileWeightAndAppetiteFragment
        ...DogSelectionFragment
        ...HowToFeedModalTriggerDogFragment
        ...ArchiveDogProfileActionDogFragment
      }
      ...ShareProfileFragment
      ...ShareRafPassFragment
    }
    ...ArchiveDogProfileActionQueryFragment
  }
`

export { DOG_PROFILE_QUERY }
