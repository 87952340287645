import { gql } from '@apollo/client'

const DOG_PROFILE_PICTURE_FRAGMENT = gql`
  fragment DogProfilePictureFragment on Dog {
    id
    name
    dogProfile {
      id
      avatarUrl
      avatarImageAttached
    }
  }
`

export { DOG_PROFILE_PICTURE_FRAGMENT }
