import * as Sentry from '@/utils/sentry'

import { ensureNever } from '@/typescript/utils'

import { Routes as PlansRoutes } from '../types/routes'

type Props = {
  route: PlansRoutes
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
  shouldSeeSkipPlanPage?: boolean
}

const currentRouteToCurrentStep = ({
  route,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans = false,
  shouldSeeSkipPlanPage = false
}: Props): number => {
  if (shouldSeeSkipPlanPage) {
    switch (route) {
      case PlansRoutes.PetParent:
      case PlansRoutes.RecommendedBox:
      case PlansRoutes.Recipes:
      case PlansRoutes.Plan:
        return 1
      case PlansRoutes.Extras:
        return 2
      case PlansRoutes.Checkout:
        if (hasRecommendedExtras) {
          return 3
        } else return 2
      default:
        ensureNever(route)
        return 1
    }
  } else {
    switch (route) {
      case PlansRoutes.PetParent:
        return shouldSeePetParentOnPlans ? 1 : 0
      case PlansRoutes.Recipes:
        return shouldSeePetParentOnPlans ? 2 : 1
      case PlansRoutes.Plan:
        return shouldSeePetParentOnPlans ? 3 : 2
      case PlansRoutes.Extras:
        return shouldSeePetParentOnPlans ? 4 : 3
      case PlansRoutes.Checkout:
        if (hasRecommendedExtras) {
          return shouldSeePetParentOnPlans ? 5 : 4
        } else return shouldSeePetParentOnPlans ? 4 : 3
      case PlansRoutes.RecommendedBox:
        Sentry.captureException(`${route} is not a valid route.`, {
          extra: route,
          tags: {
            product: Sentry.Product.PlansFlow
          }
        })
        return 1
      default:
        ensureNever(route)
        return 1
    }
  }
}

const currentRouteToTotalStep = (
  hasRecommendedExtras: boolean,
  shouldSeePetParentOnPlans: boolean,
  shouldSeeSkipPlanPage = false
): number => {
  if (shouldSeeSkipPlanPage) {
    return hasRecommendedExtras ? 3 : 2
  } else {
    if (hasRecommendedExtras) {
      return shouldSeePetParentOnPlans ? 5 : 4
    } else {
      return shouldSeePetParentOnPlans ? 4 : 3
    }
  }
}

const currentRouteToPercentFilled = ({
  route,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans = false,
  shouldSeeSkipPlanPage = false
}: Props): number => {
  const totalSteps = currentRouteToTotalStep(
    hasRecommendedExtras,
    shouldSeePetParentOnPlans,
    shouldSeeSkipPlanPage
  )
  if (shouldSeeSkipPlanPage) {
    switch (route) {
      case PlansRoutes.PetParent:
      case PlansRoutes.RecommendedBox:
      case PlansRoutes.Recipes:
      case PlansRoutes.Plan:
        return hasRecommendedExtras ? 33.33 : 50
      case PlansRoutes.Extras:
        return 66.66
      case PlansRoutes.Checkout:
        return 100
      default:
        ensureNever(route)
        return 100
    }
  } else {
    switch (route) {
      case PlansRoutes.PetParent:
        return shouldSeePetParentOnPlans ? 100 / totalSteps : 0
      case PlansRoutes.RecommendedBox:
      case PlansRoutes.Recipes:
        return shouldSeePetParentOnPlans
          ? 100 / totalSteps
          : (100 / totalSteps) * 2
      case PlansRoutes.Plan:
        return shouldSeePetParentOnPlans
          ? (100 / totalSteps) * 2
          : (100 / totalSteps) * 3
      case PlansRoutes.Extras:
        return shouldSeePetParentOnPlans
          ? (100 / totalSteps) * 3
          : (100 / totalSteps) * 4
      case PlansRoutes.Checkout:
        return 100
      default:
        ensureNever(route)
        return 100 / totalSteps
    }
  }
}

export {
  currentRouteToCurrentStep,
  currentRouteToTotalStep,
  currentRouteToPercentFilled
}
