// @noflow
import React from 'react'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import ModalTrigger from '@/components/modals/trigger'

type Props = {
  benefitType: string | null
}

const BenefitModalTrigger = ({
  benefitType
}: Props): React.JSX.Element | null => {
  if (!benefitType) return null

  return (
    <SectionWrapper margin={{ top: 32, bottom: 48 }}>
      <ModalTrigger
        modalId="edit-delivery-frequency-benefit-modal"
        text={{
          text: `edit_delivery_frequency_benefit.${benefitType}.trigger`,
          variant: 'display16',
          namespace: 'modals',
          margin: false
        }}
        screenIdentifier="editDeliveryFrequencyScreen"
        modalOptions={{ benefitType }}
        align="center"
      />
    </SectionWrapper>
  )
}

export { BenefitModalTrigger }
