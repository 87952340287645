// @noflow
import { Occasions, useOccasion } from '@/context/festiveTheme/festiveTheme'
import { useLocalisation } from '@/context/localisation'
import { format, isAfter, isToday } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import DiscountBadge from 'assets/images/icons/badges/discount-percentage-badge.svg'
import InfoNotification from 'assets/images/icons/info/info-notification.svg'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card, {
  CardVariantProp,
  generateVariant
} from '@/components/elements/atoms/Card/Card'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import SwiperSlider, {
  Props as SwiperSliderProps
} from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { Order } from '@/components/pages/Dashboard/components/upcomingBoxes/utilities'

import STYLES from './OrderCarousel.module.sass'

import { Descriptor } from '@/types'

type SlideProps = {
  id: string
  deliveryDate: Date
  selected: boolean
  onClick: (id: string) => void
  isDiscounted?: boolean
  isNext?: boolean
  isPast?: boolean
  isBoxDelivered?: boolean
  deliveryDateAdjustedBySystem?: boolean
}

const SLIDER_ID = 'order-carousel'

const slideVariant = (
  past: boolean,
  occasion?: Occasions
): { variant: CardVariantProp; textColour: TextProps['colour'] } => {
  if (occasion === Occasions.xmas) {
    return {
      variant: generateVariant(
        'brandBlue200',
        past ? 'brandBlue300' : 'brandBlue500',
        'brandBlue200'
      ),
      textColour: 'brandWhite'
    }
  }

  return {
    variant: generateVariant(
      'brandYellow100',
      'brandYellow500',
      'brandYellow200'
    ),
    textColour: past ? 'brandYellow600' : 'brandBlue500'
  }
}

const Slide = ({
  id,
  deliveryDate,
  selected,
  onClick,
  isDiscounted,
  isNext,
  isPast,
  isBoxDelivered,
  deliveryDateAdjustedBySystem
}: SlideProps): JSX.Element => {
  const { dateLocale } = useLocalisation()
  const { xmas, occasion, optedIn } = useOccasion()
  const { windowWidth } = useWindowSize()
  const now: Date = new Date()
  const past = !isToday(deliveryDate) && isAfter(now, deliveryDate)
  const { variant, textColour } = slideVariant(
    past,
    optedIn ? occasion : undefined
  )
  const isMobile = windowWidth < BREAKPOINTS.md
  const [initialised, setInitialised] = useState(false)

  const shouldDisplayDeliveryAdjustmentBadge =
    deliveryDateAdjustedBySystem && !isBoxDelivered && !isPast

  const onSlideClick = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialised(true)
    }, 100)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <Expand origin="center" show={initialised}>
      <div className={STYLES.item}>
        <Card
          variant={variant}
          border={past ? 'dashed' : 'solid'}
          element="time"
          dateTime={deliveryDate.toString()}
          background={xmas ? 'brandBlue100' : true}
          selected={selected}
          onClick={onSlideClick}
          padding={0}
          themed={xmas}
          identifier="order_carousel"
        >
          {(shouldDisplayDeliveryAdjustmentBadge || isDiscounted) && (
            <div className={STYLES.badgeWrapper}>
              <img
                className={STYLES.badge}
                src={
                  shouldDisplayDeliveryAdjustmentBadge
                    ? InfoNotification
                    : DiscountBadge
                }
                alt=""
              />
            </div>
          )}
          <div className={STYLES.cardContent}>
            <SectionWrapper padding={isNext ? 8 : 0} margin={0}>
              <Text
                text={format(new Date(deliveryDate), 'EEE', {
                  locale: dateLocale
                })}
                element="div"
                translate={false}
                variant="textRegular16"
                colour={textColour}
              />
              <div className={STYLES.date}>
                <Text
                  text={format(deliveryDate, 'dd', {
                    locale: dateLocale
                  })}
                  element="div"
                  translate={false}
                  variant={isMobile ? 'display20' : 'display28'}
                  colour={textColour}
                />
              </div>
              <Text
                text={format(deliveryDate, 'MMM', {
                  locale: dateLocale
                })}
                element="div"
                translate={false}
                variant="textRegular16"
                colour={textColour}
              />
            </SectionWrapper>
            {isNext && (
              <div className={STYLES.nextBoxWrapper}>
                <SectionWrapper
                  margin={0}
                  padding={8}
                  bgColour={xmas ? 'brandBlue400' : 'brandYellow300'}
                >
                  <Text
                    text="order_carousel.next_box"
                    namespace="organisms"
                    element="div"
                    variant="textRegular16"
                    colour={textColour}
                  />
                </SectionWrapper>
              </div>
            )}
          </div>
        </Card>
      </div>
    </Expand>
  )
}

type Props = Pick<SwiperSliderProps, 'onLazyLoadPrev' | 'onLazyLoadNext'> & {
  orders: Order[]
  onSlideClick: (id: string) => void
  selected?: string
  slider?: SwiperSliderProps['slider']
  initialSlide?: number
}

/**
 * A carousel of orders
 * @param orders
 * @param selected - toggles an order to a selected visible state
 * @param onSlideClick
 * @param onLazyLoadPrev
 * @param onLazyLoadNext
 * @param slider
 * @param placeholders
 * @constructor
 */
const OrderCarousel = ({
  orders,
  selected,
  initialSlide,
  onSlideClick,
  onLazyLoadPrev,
  onLazyLoadNext,
  slider
}: Props): JSX.Element | null => {
  const { xmas } = useOccasion()
  const { windowWidth } = useWindowSize()

  if (orders.length === 0) return null

  return (
    <SwiperSlider
      slider={slider}
      onLazyLoadPrev={onLazyLoadPrev}
      onLazyLoadNext={onLazyLoadNext}
      watchOverflow={false} // slider should be active even if there is not initially enough slides as prev/next triggers lazy load
      skeleton={{
        width: windowWidth < BREAKPOINTS.md ? '7.4rem' : '18.5rem',
        height: windowWidth < BREAKPOINTS.md ? '9.2rem' : '17rem'
      }}
      paddingOverride={24}
      id={SLIDER_ID}
      initialSlide={initialSlide}
      variant={xmas ? 'brandBlue100' : 'brandYellow300'}
    >
      {orders.map((order) => {
        const { id, deliveryDate } = order
        return (
          <Slide
            key={`${id}-order-carousel-item-key`}
            id={id}
            deliveryDate={deliveryDate}
            selected={selected === id}
            onClick={onSlideClick}
            isDiscounted={order.discounts && order.discounts.length > 0}
            isNext={order.descriptor === Descriptor.next}
            isPast={order.descriptor === Descriptor.past}
            isBoxDelivered={order.isBoxDelivered}
            deliveryDateAdjustedBySystem={order.deliveryDateAdjustedBySystem}
          />
        )
      })}
    </SwiperSlider>
  )
}

export { Props }
export default OrderCarousel
