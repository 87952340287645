// @noflow
import { useLanguage } from '@/context/injectedValues/injectedValues'
import { makeVar, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import { RESUME_SUBSCRIPTION } from '@/constants/RibbonEvents'

import useInAccountRaf from '@/hooks/useInAccountRaf/useInAccountRaf'
import { useMTFITB } from '@/hooks/useMFITB'
import useRAFDiscountReminder from '@/hooks/useRAFDiscountReminder'
import useReactivationNotification from '@/hooks/useReactivationNotification'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import RafCampaignModal from '@/components/elements/molecules/RafCampaignModal/RafCampaignModal'
import RafDiscountReminderModal from '@/components/elements/molecules/RafDiscountReminderModal/RafDiscountReminderModal'
import { SubscriptionOverview } from '@/components/elements/molecules/SubscriptionOverview'
import UpcomingBoxesV3 from '@/components/pages/DashboardV3/components/UpcomingBoxesV3/UpcomingBoxesV3'

import { DASHBOARD_QUERY } from './queries/dashboardQuery'

import type { DashboardV3Query } from './queries/__generated__/DashboardV3Query'

enum DASHBOARD_PAGE_SECTIONS {
  upcomingBoxes = 'upcoming-boxes'
}

const upcomingBoxesLoaded = makeVar(false)

const DashboardPage = (): JSX.Element | null => {
  const [showRafModal, setShowRafModal] = useState(true)
  const { userLanguage } = useLanguage()
  useReactivationNotification()
  useMTFITB()

  const { loading, data } = useQuery<DashboardV3Query>(DASHBOARD_QUERY)

  const { eligibleForRafCampaign, loading: rafDataLoading } = useInAccountRaf(
    'Valentines-Campaign-BNLX-2025'
  )

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const reactivation = urlParams.get('show_reactivation_confirmation')

    const reactivated = reactivation === 'true'

    if (reactivated) {
      window.ribbon && window.ribbon('trigger', RESUME_SUBSCRIPTION)
      history.replaceState(null, '', window.location.pathname)
    }
  })

  const { formattedDiscount, shouldSeeRafDiscountModal } =
    useRAFDiscountReminder(data)

  return (
    <>
      <SectionWrapper>
        <UpcomingBoxesV3 />
        <SubscriptionOverview
          loading={loading}
          dogs={data?.user.dogs || []}
          subscription={data?.user.subscription}
        />
      </SectionWrapper>
      {data && !rafDataLoading && eligibleForRafCampaign ? (
        <RafCampaignModal
          isModalOpen={showRafModal}
          setOpenModal={setShowRafModal}
          preferredLanguage={userLanguage}
        />
      ) : null}

      {shouldSeeRafDiscountModal && (
        <RafDiscountReminderModal discount={formattedDiscount} />
      )}
    </>
  )
}

export { DASHBOARD_PAGE_SECTIONS, upcomingBoxesLoaded }
export default DashboardPage
