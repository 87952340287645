import { Locale, format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Box from 'assets/images/icons/box.svg'

import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Text from '@/components/elements/atoms/Text'

import STYLES from '../ChangeDatePageV2.module.sass'

type DefaultFooterProps = {
  currentDeliveryDate: Date
  locale: Locale
  courierName?: string | null
}

const DefaultFooter = ({
  currentDeliveryDate,
  locale,
  courierName
}: DefaultFooterProps): JSX.Element => {
  const { t } = useTranslation('account')
  return (
    <FixedBase backgroundColor="cardboardBrown">
      <div className={STYLES.container}>
        <div className={STYLES.textWrapper}>
          <div>
            <Text
              text="change_date.next_delivery"
              namespace="account"
              colour={'brandWhite'}
              margin={false}
              variant={'textRegular18'}
            />
            <Text
              text={format(currentDeliveryDate, 'EEE d MMM', {
                locale
              })}
              colour={'brandWhite'}
              margin={false}
              bold
              variant="display24"
              translate={false}
            />
            {courierName && (
              <Text
                text={courierName}
                colour={'brandWhite'}
                margin={false}
                variant={'textRegular16'}
                translate={false}
              />
            )}
          </div>

          <img alt={t('change_date.box_illustration_image_alt')} src={Box} />
        </div>
      </div>
    </FixedBase>
  )
}

export { DefaultFooter }
