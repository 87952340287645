import { gql } from '@apollo/client'

const BOX_ORDER_FRAGMENT = gql`
  fragment upcomingBoxOrderFragment on Box {
    numberOfPouches
    durationInDays
    id
    descriptor
    deliveryDateAdjustedBySystem
    isOneOffBox
    isoDeliveryDate
    shouldOfferSelfService
    cutOffDate
    shippingDate
    isFreeBox
    isTrialBox
    isReplacementBox
    isNextEditableBox
    productSpacesFree
    discounts {
      applicableDiscountPart {
        discountBasis
        value
      }
    }
    consignment {
      status
    }
    planPricePerDay
    order {
      id
      orderProducts {
        id
        nonPaymentReason
      }
      planTotalPerDay
      planTotal
      surchargeTotal
      physicalProductsTotal
      deliveryFee
      discountTotal
      discountTotalPercentage
      totalPrice
    }
    amendable
    physicalOrderProducts {
      id
      nonPaymentReason
      quantity
      productVariant {
        id
        name
        productCollection {
          id
          name
          thumbnail {
            src
          }
        }
      }
    }
    meals {
      id
      flavour {
        id
        name
        thumbnail {
          src
        }
      }
      quantity
    }
    plan {
      id
      pouchSize
    }
  }
`

const NON_CORE_ORDER_FRAGMENT = gql`
  fragment nonCoreUpcomingOrderFragment on NonCoreOrder {
    id
    descriptor
    invoice {
      id
      status
      grossPrice
      discountedPrice
      discountTotal
    }
    orderParts {
      deliverySurcharge
      smallOrderSurcharge
      orderProductsTotal
      consignment {
        status
      }
      deliveryDate
      shippingDate
      orderProducts {
        id
        quantity
        productVariant {
          id
          name
          productCollection {
            id
            thumbnail {
              src
            }
          }
        }
      }
    }
  }
`

export { BOX_ORDER_FRAGMENT, NON_CORE_ORDER_FRAGMENT }
