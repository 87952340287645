// @noflow
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import last from 'lodash/last'
import throttle from 'lodash/throttle'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { pronounContext } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

// Assets
import BigOlChomper1 from 'assets/images/illustrations/munches/big-ol-chomper-1.svg'
import BigOlChomper2 from 'assets/images/illustrations/munches/big-ol-chomper-2.svg'
import LittleNibbler from 'assets/images/illustrations/munches/little-nibbler.svg'

// Components
import PlansStickyNavigation from './components/PlansStickyNavigation/PlansStickyNavigation'
import SecondaryRecipesSection from './components/SecondaryRecipesSection/SecondaryRecipesSection'
import StarterBoxSection from './components/StarterBoxSection/StarterBoxSection'
import Text from '@/components/elements/atoms/Text'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import { Recipe } from '@/components/elements/molecules/RecipeCard/RecipeCard'
import FlavourInformationModal, {
  modalData as flavourModalData
} from '@/components/elements/organisms/FlavourInformationModal/FlavourInformationModal'
import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'
import {
  Flavour,
  deliveryCadenceState,
  plansPageAnalyticState,
  plansPageWizardState
} from '@/components/pages/SimplifiedPlansPage/SimplifiedPlansPage'
import LoadingScreenContent from '@/components/pages/SimplifiedPlansPage/components/LoadingScreenContent/LoadingScreenContent'

// Styles
import STYLES from './Recipes.module.sass'

// Queries
import GUEST_QUERY from '../../queries/GuestQuery'
import PENDING_SUBSCRIPTION_ORDER_PRICING_QUERY from '../../queries/PendingSubscriptionOrderPricingQuery'
import RECIPES_SCREEN_QUERY from '../../queries/RecipesScreenQuery'

import {
  PendingSubscriptionOrderPricingQuery,
  PendingSubscriptionOrderPricingQueryVariables
} from '../../queries/__generated__/PendingSubscriptionOrderPricingQuery'
import {
  RecipesScreenQuery_guest_planRecommendation_individual_dog_allergens_flavours as AllergenFlavour,
  RecipesScreenQuery_guest_planRecommendation_individual_dog_healthIssues_flavours as HealthIssueFlavour,
  RecipesScreenQuery
} from '../../queries/__generated__/RecipesScreenQuery'
import { SimplifiedGuestQuery } from '../../queries/__generated__/SimplifiedGuestQuery'

import { PlanState, plansPageState } from '../../SimplifiedPlansPage'
import {
  trackRecipeMoreInfoClicked,
  trackRecipeSelectionToggled,
  trackRecipeTooltipToggled
} from '../../helpers/analytics'
import {
  currentRouteToCurrentStep,
  currentRouteToPercentFilled,
  currentRouteToTotalStep
} from '../../helpers/plansNavigation'
import useIdealPlan from '../../hooks/useIdealPlan'
import type {
  PlansAnalyticState,
  TooltipLocation
} from '../../types/analyticsProperties'
import { FlowType, SelectionStatus } from '../../types/analyticsProperties'
import { Routes as PlansRoutes } from '../../types/routes'

type Props = {
  namespace: string
  maxFlavours: number
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
  shouldSeeSkipPlanPage: boolean
}

type MunchIllustration = {
  image: string
  class: string
  alt: string
}

const munches: Array<MunchIllustration> = [
  {
    image: LittleNibbler,
    class: STYLES.littleNibbler,
    alt: 'little_nibble_alt'
  },
  {
    image: BigOlChomper1,
    class: STYLES.bigOlChomper1,
    alt: 'big_ol_chomper_alt'
  },
  {
    image: BigOlChomper2,
    class: STYLES.bigOlChomper2,
    alt: 'big_ol_chomper_alt'
  },
  {
    image: BigOlChomper1,
    class: STYLES.bigOlChomper3,
    alt: 'big_ol_chomper_alt'
  }
]

const Recipes = ({
  namespace,
  maxFlavours,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans,
  shouldSeeSkipPlanPage
}: Props): JSX.Element | null => {
  const plansState: PlanState = useReactiveVar(plansPageState)
  const plansAnalyticState: PlansAnalyticState = useReactiveVar(
    plansPageAnalyticState
  )
  const deliveryCadence = useReactiveVar(deliveryCadenceState)

  const [recommendedRecipes, setRecommendedRecipes] = useState<Array<Recipe>>(
    []
  )
  const [secondaryRecipes, setSecondaryRecipes] = useState<Array<Recipe>>([])
  const [maxFlavoursReached, setMaxFlavoursReached] = useState<boolean>(false)
  const [noRecommendedRecipes, setNoRecommendedRecipes] =
    useState<boolean>(false)
  const [strikethroughPrice, setStrikethroughPrice] = useState(0)

  const copyContext = 'recipes'
  const { t } = useTranslation(namespace)

  const wizardState = useReactiveVar(plansPageWizardState)

  const { discountedPricePerDay, selectedPlan } = useIdealPlan()

  const skipRecipesLoadingScreenStorageKey = 'skipLoadingScreen'
  const skipRecipesLoadingScreenState = localStorage.getItem(
    skipRecipesLoadingScreenStorageKey
  )
  const showLoadingScreen = !JSON.parse(
    skipRecipesLoadingScreenState || 'false'
  )

  const maxFlavourSelectionNotification = (
    maxFlavours: number,
    namespace: string
  ): JSX.Element => (
    <NotificationContent
      copy={{
        text: `${copyContext}.max_flavours`,
        namespace: namespace,
        variables: {
          max: maxFlavours
        }
      }}
    />
  )

  // Sets flavours in the plans state
  const setSelectedFlavours = useCallback(
    (selectedRecipes: Array<Recipe>) => {
      const recipes = selectedRecipes.reduce(
        (prev, curr) =>
          curr.selected ? [...prev, { id: curr.id, slug: curr.slug }] : prev,
        new Array<Flavour>()
      )
      const updatedPendingSubscription: PlanState = {
        ...plansState,
        flavours: recipes
      }

      if (!isEqual(updatedPendingSubscription, plansState))
        plansPageState(updatedPendingSubscription)
    },
    [plansState]
  )

  // Sets planId in the plans state
  const setPlanId = useCallback(
    (planId: string) => {
      const updatedPendingSubscription: PlanState = {
        ...plansState,
        planId
      }

      if (!isEqual(updatedPendingSubscription, plansState))
        plansPageState(updatedPendingSubscription)
    },
    [plansState]
  )

  const updateRecommendedRecipes = useCallback(
    (selected: boolean, recipe: Recipe) => {
      const cloneArray: Array<Recipe> = [...recommendedRecipes]
      const recipeIndex: number = recommendedRecipes.findIndex(
        (selectedRecipe: Recipe) => selectedRecipe?.id === recipe?.id
      )
      cloneArray[recipeIndex].selected = selected

      setRecommendedRecipes(cloneArray)
      const allRecipes = [...recommendedRecipes, ...secondaryRecipes]
      setSelectedFlavours(allRecipes)
      const showMaxRecipesToast =
        allRecipes.filter((recipe) => recipe.selected).length === maxFlavours
      if (showMaxRecipesToast) {
        toast(maxFlavourSelectionNotification(maxFlavours, namespace))
      }
      trackRecipeSelectionToggled({
        route: PlansRoutes.Recipes,
        plansState,
        wizardState,
        deliveryCadence,
        selectionStatus: selected
          ? SelectionStatus.selected
          : SelectionStatus.deselected,
        flavour: recipe.name,
        flowType: FlowType.new,
        plansAnalyticState
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      deliveryCadence,
      maxFlavours,
      namespace,
      plansState,
      recommendedRecipes,
      secondaryRecipes,
      setSelectedFlavours,
      wizardState
    ]
  )

  const updateSecondaryRecipes = useCallback(
    (selected: boolean, recipe: Recipe) => {
      const cloneArray: Array<Recipe> = [...secondaryRecipes]
      const recipeIndex: number = secondaryRecipes.findIndex(
        (selectedRecipe: Recipe) => selectedRecipe?.id === recipe?.id
      )
      cloneArray[recipeIndex].selected = selected

      setSecondaryRecipes(cloneArray)
      setSelectedFlavours([...recommendedRecipes, ...secondaryRecipes])
      if (
        [...recommendedRecipes, ...secondaryRecipes].filter(
          (recipe) => recipe.selected
        ).length === maxFlavours
      ) {
        toast(maxFlavourSelectionNotification(maxFlavours, namespace))
      }
      trackRecipeSelectionToggled({
        route: PlansRoutes.Recipes,
        plansState,
        wizardState,
        deliveryCadence,
        selectionStatus: selected
          ? SelectionStatus.selected
          : SelectionStatus.deselected,
        flavour: recipe.name,
        flowType: FlowType.new,
        plansAnalyticState
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      deliveryCadence,
      maxFlavours,
      namespace,
      plansState,
      recommendedRecipes,
      secondaryRecipes,
      setSelectedFlavours,
      wizardState
    ]
  )
  const navigate = useNavigate()
  const handleBackButton = useCallback(() => {
    if (shouldSeeSkipPlanPage) {
      navigate(PlansRoutes.RecommendedBox)
    } else if (shouldSeePetParentOnPlans) {
      navigate(PlansRoutes.PetParent)
    } else {
      window.location.href = '/wizard/pet-parent'
    }
  }, [navigate, shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  const handleTooltipOpened = useCallback((location: TooltipLocation) => {
    trackRecipeTooltipToggled({
      route: PlansRoutes.Recipes,
      location
    })
  }, [])

  const showFlavourModal = useCallback(
    (slug: string): void => {
      flavourModalData({ isOpen: true, slug: slug })
      trackRecipeMoreInfoClicked(
        PlansRoutes.Recipes,
        plansState,
        wizardState,
        deliveryCadence,
        slug,
        FlowType.new,
        plansAnalyticState
      )
    },
    [deliveryCadence, plansAnalyticState, plansState, wizardState]
  )

  const handleOnClick = useCallback(() => {
    deliveryCadenceState('default')
    localStorage.setItem(skipRecipesLoadingScreenStorageKey, 'true')
    navigate(PlansRoutes.Plan)
  }, [navigate])

  const {
    data: guestData,
    error: guestError,
    loading: guestLoading
  } = useQuery<SimplifiedGuestQuery>(GUEST_QUERY)

  const planId =
    guestData?.currentUser?.__typename === 'Guest'
      ? selectedPlan?.id
      : undefined
  const skip = planId === undefined

  const {
    data: recipesScreenData,
    loading: recipesScreenLoading,
    error: recipesScreenError
  } = useQuery<RecipesScreenQuery>(RECIPES_SCREEN_QUERY, {
    variables: { planId },
    skip
  })

  const [getPricing, { error: pendingSubscriptionOrderPricingError }] =
    useLazyQuery<
      PendingSubscriptionOrderPricingQuery,
      PendingSubscriptionOrderPricingQueryVariables
    >(PENDING_SUBSCRIPTION_ORDER_PRICING_QUERY, {
      onCompleted: (data) => {
        // NOTE: Since the last object in the array is a standard order, and the first is the first box order.
        // To get the standard order prices, we need the last object in the array
        const standardOrderPrices = last(data.pendingSubscriptionOrderPrices)

        setStrikethroughPrice(
          standardOrderPrices?.standardOrderCoreFoodGrossPricePerDay || 0
        )
      }
    })

  const throttleGetPricing = useMemo(
    () =>
      throttle((variables) => {
        getPricing({ variables })
      }, 1000),
    [getPricing]
  )

  if (guestError || recipesScreenError) {
    Sentry.captureException(`Error in Plans Flow - Recipes step`, {
      extra: {
        guestError,
        recipesScreenError
      },
      tags: {
        product: Sentry.Product.PlansFlow
      }
    })
  }

  if (pendingSubscriptionOrderPricingError) {
    Sentry.captureException(
      `Error in Plans Flow - Recipes step: : PendingSubscriptionOrderPricingQuery`,
      {
        extra: {
          pendingSubscriptionOrderPricingError
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  }

  useEffect(() => {
    if (plansState.planId && !isEmpty(plansState.flavours)) {
      throttleGetPricing({
        planId: !isNull(plansState.planId) ? plansState.planId : '',
        productVariantIds: !isNull(plansState.productVariants)
          ? plansState.productVariants.map(({ id }) => id)
          : undefined,
        flavourQuantities: !isNull(plansState.flavours)
          ? plansState.flavours?.reduce(
              (breakdown: { [key: string]: number }, flavour: Flavour) => {
                breakdown[camelCase(flavour.slug)] = 0
                return breakdown
              },
              {}
            )
          : undefined,
        discountCodeId:
          guestData?.currentUser?.__typename === 'Guest'
            ? guestData?.currentUser.discountCode?.id ?? ''
            : ''
      })
    }
  }, [throttleGetPricing, plansState, guestData?.currentUser])

  useEffect(() => {
    // Set initial state of recipe carousels
    if (recipesScreenData?.guest.planRecommendation) {
      const {
        guest: { planRecommendation }
      } = recipesScreenData
      const {
        combinedRecommendedFlavours,
        combinedOtherFlavours,
        combinedNotRecommendedFlavours,
        individual
      } = planRecommendation

      if (selectedPlan) {
        setPlanId(selectedPlan.id)

        if (
          combinedRecommendedFlavours?.length === 0 &&
          combinedOtherFlavours?.length === 0
        ) {
          setNoRecommendedRecipes(true)
        }
        const allergenFlavours = individual.reduce(
          (prev, { dog }) => [
            ...prev,
            ...dog.allergens.reduce(
              (prev, curr) => [...prev, ...curr.flavours],
              new Array<AllergenFlavour>()
            )
          ],
          new Array<AllergenFlavour>()
        )
        const healthIssueFlavours = individual.reduce(
          (prev, { dog }) => [
            ...prev,
            ...dog.healthIssues.reduce(
              (prev, curr) => [...prev, ...curr.flavours],
              new Array<HealthIssueFlavour>()
            )
          ],
          new Array<HealthIssueFlavour>()
        )
        const tempRecommendedRecipes: Array<Recipe> =
          combinedRecommendedFlavours.map((flavour) => {
            return {
              id: flavour.id,
              name: flavour.name,
              description: flavour.shortDescription,
              thumbnail: flavour.primaryImage.src,
              discountedPrice: selectedPlan.trialDiscountedPricePerDay,
              price: flavour.standardPricePerDay,
              hasAllergies: false,
              hasHealthIssues: false,
              selected: isNull(plansState?.flavours)
                ? true
                : plansState?.flavours?.some(({ id }) => id === flavour.id),
              recipeSurcharge: flavour.recipeSurcharge || 0,
              slug: flavour.slug
            }
          })
        const tempOtherRecipes: Array<Recipe> = combinedOtherFlavours.map(
          (flavour) => {
            return {
              id: flavour.id,
              name: flavour.name,
              description: flavour.shortDescription,
              thumbnail: flavour.primaryImage.src,
              discountedPrice: selectedPlan.trialDiscountedPricePerDay,
              price: flavour.standardPricePerDay,
              hasAllergies: false,
              hasHealthIssues: false,
              selected: isNull(plansState?.flavours)
                ? false
                : plansState?.flavours?.some(({ id }) => id === flavour.id),
              recipeSurcharge: flavour.recipeSurcharge || 0,
              slug: flavour.slug
            }
          }
        )
        const tempNotRecommendedRecipes: Array<Recipe> =
          combinedNotRecommendedFlavours
            .map((flavour) => {
              return {
                id: flavour.id,
                name: flavour.name,
                description: flavour.shortDescription,
                thumbnail: flavour.primaryImage.src,
                discountedPrice: selectedPlan.trialDiscountedPricePerDay,
                price: flavour.standardPricePerDay,
                hasAllergies: allergenFlavours.some(
                  ({ id }) => flavour.id === id
                ),
                hasHealthIssues: healthIssueFlavours.some(
                  ({ id }) => flavour.id === id
                ),
                selected: isNull(plansState?.flavours)
                  ? false
                  : plansState?.flavours?.some(({ id }) => id === flavour.id),
                recipeSurcharge: flavour.recipeSurcharge || 0,
                slug: flavour.slug
              }
            })
            .sort((a, b) =>
              a.hasHealthIssues === b.hasHealthIssues
                ? 0
                : a.hasHealthIssues
                ? -1
                : 1
            )
        const allRecipes = [
          ...tempRecommendedRecipes,
          ...tempOtherRecipes,
          ...tempNotRecommendedRecipes
        ]
        setRecommendedRecipes(tempRecommendedRecipes)
        setSecondaryRecipes([...tempOtherRecipes, ...tempNotRecommendedRecipes])
        setSelectedFlavours(allRecipes)
      }
    }
  }, [
    recipesScreenData,
    plansState?.flavours,
    setSelectedFlavours,
    setPlanId,
    selectedPlan
  ])

  useEffect(() => {
    if (
      !isNull(plansState.flavours) &&
      plansState.flavours.length >= maxFlavours
    ) {
      setMaxFlavoursReached(true)
    } else {
      setMaxFlavoursReached(false)
    }
  }, [maxFlavours, maxFlavoursReached, plansState.flavours])

  useEffect(() => {
    plansPageAnalyticState({
      ...plansAnalyticState,
      numberOfRecommendedRecipes: recommendedRecipes.length,
      numberOfNonRecommendedRecipes: secondaryRecipes.length,
      idealGramsAmount:
        recipesScreenData?.guest.planRecommendation?.idealGramsAmount
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansState, recommendedRecipes, secondaryRecipes])

  const currentStep = useCallback(() => {
    return currentRouteToCurrentStep({
      route: PlansRoutes.Recipes,
      hasRecommendedExtras,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    })
  }, [hasRecommendedExtras, shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  const totalSteps = useCallback(() => {
    return currentRouteToTotalStep(
      hasRecommendedExtras,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    )
  }, [hasRecommendedExtras, shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  if (guestData?.currentUser?.__typename !== 'Guest') return null

  const { dogs, assumedShippingCountry, preferredLanguage } =
    guestData?.currentUser

  const { code: shippingCountryCode } = assumedShippingCountry

  const dogNames = dogs.map(({ name }) => name)
  const dogGenders = dogs.map(({ gender }) => gender)
  const dogPronoun = pronounContext(dogGenders, preferredLanguage)

  const foodCategory = dogs.map((dog) =>
    dog.previousFoodTypes.map(({ foodItem }) => foodItem)
  )[0][0]

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  return (
    <div className={STYLES.container}>
      {showLoadingScreen && !shouldSeePetParentOnPlans && (
        <LoadingScreenContent
          namespace={namespace}
          foodCategory={foodCategory}
          loading={guestLoading}
          variant={PlansRoutes.Recipes}
        />
      )}
      <WizardNavigation
        variant="simplified"
        percentFilled={currentRouteToPercentFilled({
          route: PlansRoutes.Recipes,
          hasRecommendedExtras,
          shouldSeePetParentOnPlans,
          shouldSeeSkipPlanPage
        })}
        currentStep={currentStep()}
        totalSteps={totalSteps()}
        backOnClick={handleBackButton}
        customText={
          shouldSeeSkipPlanPage
            ? {
                text: 'recommended_box_navigation.your_box',
                namespace,
                variant: 'textRegular14',
                margin: false,
                variables: {
                  currentStep: currentStep(),
                  totalSteps: totalSteps()
                }
              }
            : undefined
        }
      />
      <div className={STYLES.headerSection}>
        <Text
          dataTestId="hero-text-title"
          namespace={namespace}
          text={
            shouldSeeSkipPlanPage
              ? `${copyContext}.recommended_box_title`
              : `${copyContext}.title`
          }
          variables={{
            count: dogs.length,
            dogName: dogNames
          }}
          variant="display24"
          element="h1"
          margin={false}
        />
        <Text
          namespace={namespace}
          text={
            shouldSeeSkipPlanPage
              ? `${copyContext}.recommended_box_subtitle`
              : `${copyContext}.subtitle`
          }
          variables={{
            count: dogs.length,
            dogName: dogNames,
            context: dogPronoun
          }}
          variant="textRegular16"
          colour="brandBlue400"
          align="center"
          margin={false}
        />
      </div>
      <div className={STYLES.primarySection}>
        <div className={STYLES.containerTop}>
          {munches.map((munch, index) => (
            <img
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={munch.class}
              src={munch.image}
              alt={t(`${copyContext}.illustrations.${munch.alt}`)}
            />
          ))}
        </div>
        <StarterBoxSection
          dogNames={dogNames}
          namespace={namespace}
          copyContext={copyContext}
          loading={guestLoading || recipesScreenLoading}
          preferredLanguage={preferredLanguage}
          shippingCountryCode={shippingCountryCode}
          updateRecipes={updateRecommendedRecipes}
          recipes={recommendedRecipes}
          maxFlavoursReached={maxFlavoursReached}
          noRecommendedRecipes={noRecommendedRecipes}
          handleTooltipOpened={handleTooltipOpened}
          onReadMoreClick={showFlavourModal}
        />
        <div className={STYLES.containerBottom}>
          {munches.slice(1).map((munch, index) => (
            <img
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={munch.class}
              src={munch.image}
              alt={t(`${copyContext}.illustrations.${munch.alt}`)}
            />
          ))}
        </div>
      </div>
      <div className={STYLES.secondarySection}>
        <div className={STYLES.secondaryRecipes}>
          <SecondaryRecipesSection
            dogNames={dogNames}
            namespace={namespace}
            copyContext={copyContext}
            loading={guestLoading || recipesScreenLoading}
            preferredLanguage={preferredLanguage}
            shippingCountryCode={shippingCountryCode}
            updateRecipes={updateSecondaryRecipes}
            recipes={secondaryRecipes}
            maxFlavoursReached={maxFlavoursReached}
            handleTooltipOpened={handleTooltipOpened}
            onReadMoreClick={showFlavourModal}
          />
        </div>
      </div>
      <PlansStickyNavigation
        currency={currency}
        locale={locale}
        namespace={namespace}
        disabled={
          isNull(plansState?.flavours) || plansState?.flavours?.length === 0
        }
        discountedPrice={discountedPricePerDay}
        onNextClick={handleOnClick}
        ongoingPrice={strikethroughPrice}
        showStrikethroughPrice
        showNoRecipesSelectedMessage={isEmpty(plansState.flavours)}
        showPrice={false}
      />
      <NotificationContainer autoClose={5000} />
      <FlavourInformationModal />
    </div>
  )
}

export default Recipes
