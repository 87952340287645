// @flow

import * as ACTIONS from '../actions'

import type { Dispatch, Thunk } from 'redux'
import type { Activity } from '../../../shared_types/rails_models/dog_activity'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { State } from '../reducers'

type DogIdActivities = {|
  dogId: ID,
  activities: Array<Activity>
|}

type ResponseBody = {|
  data?: {
    id: ID
  },
  errors?: [string]
|}

const { fetch } = global

/**
 * Updates activities attributed to a users dog profiles
 *
 * Body: {
 *   token: Matches User#token in the Postgres schema
 *   query: GraphQL mutation to add dog activities to a dog profile
 * }
 *
 * Response: {
 *    data: { Present if the request succeeded
 *      id: User_id
 *    }
 *    error: Present if the request failed
 * }
 */
const activitiesUpdate = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startRequest('activitiesUpdate'))
    const { globalAttributes, dogProfiles } = getState()
    const { token, csrfToken, userID } = globalAttributes

    // Ensure that the userID is a number
    if (typeof userID !== 'number') {
      throw new Error(`User ID (${userID}) should be of type number`)
    }

    // eslint-disable-next-line flowtype/no-flow-fix-me-comments
    // $FlowFixMe[cannot-infer-type]
    const activitiesInput: Array<DogIdActivities> = Object.keys(dogProfiles)
      .map((dogId: ID): DogIdActivities => {
        return {
          dogId: dogId,
          // eslint-disable-next-line flowtype/no-flow-fix-me-comments
          // $FlowFixMe[cannot-infer-type]
          activities: Object.keys(dogProfiles[dogId].activities)
            .reduce((acc: Array<Activity>, activity: Activity): Array<Activity> => {
              if (dogProfiles[dogId].activities[activity]) {
                acc.push(activity)
              }
              return acc
            }, [])
        }
      })

    const endpoint = '/graphql'

    const query = `
      mutation ActivitiesUpdate(
        $userId: ID!,
        $activitiesInput: [ActivitiesInput!]!,
      ) {
        activitiesUpdate(
          userId: $userId,
          activitiesInput: $activitiesInput
        ) {
          id
        }
      }
    `
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }
    const method = 'POST'
    const credentials = 'same-origin'
    const body = JSON.stringify({
      token,
      query,
      variables: {
        userId: userID,
        activitiesInput: activitiesInput
      }
    })

    return new Promise((resolve: () => void): void => {
      fetch(endpoint, { headers, method, credentials, body })
        .then((res: Response): void => {
          res.json().then(({ data, errors }: ResponseBody): void => {
            if (!errors) {
              if (!data) {
                throw new Error(`There was no error but data is empty`)
              }
              dispatch(ACTIONS.completeRequest(
                'activitiesUpdate',
                {}
              ))
              resolve()
            } else {
              dispatch(ACTIONS.completeRequest(
                'activitiesUpdate',
                {
                  error: `Error for user with id ${userID}: ${errors.map((error: string): string => JSON.stringify(error)).join()}`
                }
              ))
              resolve()
            }
          })
        })
    })
  }
}

export default activitiesUpdate
