import { gql } from '@apollo/client'

import { USER_ADDRESS_FRAGMENT } from '../../AddressBookPage/fragments/addressFragment'
import { PERSONAL_DETAILS_FRAGMENT } from '../fragments/personalDetailsFragment'

const PERSONAL_DETAILS = gql`
  ${PERSONAL_DETAILS_FRAGMENT}
  ${USER_ADDRESS_FRAGMENT}
  query PersonalDetailsQuery {
    user {
      preferredLanguage
      ...personalDetailsFragment
      ...UserAddressFragment
      shippingCountryCode
      currentDeliveryAreaPreference {
        id
        preferredCarrierService {
          id
          deliveryNotesLimit
        }
      }
      subscription {
        id
        status
        pauseUrl
        nextEditableBox {
          id
          editable
          isoDeliveryDate
          order {
            id
            discountTotal
            discountTotalPercentage
          }
          shippingCountry {
            id
            code
          }
        }
      }
    }
  }
`

export { PERSONAL_DETAILS }
