// @noflow

/**
 * Only very high level logic belongs here.
 * Ideally this file should be not be changed unless absolutely necessary.
 */
import {
  AccessManagementProvider,
  useAccessManagement,
  userHasActivePaymentMethod
} from '@/context/accessManagement/accessManagement'
import { FestiveThemeProvider } from '@/context/festiveTheme/festiveTheme'
import {
  InjectedValues,
  WithInjectedValues,
  useLanguage,
  useShippingCountry
} from '@/context/injectedValues/injectedValues'
import { LocalisationProvider } from '@/context/localisation/localisation'
import { ModalManagerProvider } from '@/context/modalProvider'
import {
  NotificationProvider,
  useNotifications
} from '@/context/notifications/notifications'
import { ProductUpsellProvider } from '@/context/productUpsellModal/productUpsellModal'
import initI18n from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { createAccountRouteObject } from '@/routes/account'
import { useQuery, useReactiveVar } from '@apollo/client'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import Cookies from 'js-cookie'
import isNull from 'lodash/isNull'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useParams,
  useRouteError
} from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'

import {
  dogsDataVar,
  dynamicDefaultTrackEventPropertiesVar,
  featureFlagsDataVar,
  hasNotificationsVar,
  settingsVar,
  shippingCountryDataVar,
  subscriptionDataVar,
  userDataVar
} from '@/services/apollo'
import { trackEvent } from '@/services/segment'

import { possessive, pronounContext } from '@/utils/StringHelper'
import { MAX_PUPPY_AGE_IN_MONTHS } from '@/utils/butternutbox/constants/digital_product'
import cookies from '@/utils/cookies'

import BREAKPOINTS from '@/constants/Breakpoints'

import withApollo from '@/components/apollo/withApollo'
import { ErrorState } from '@/components/elements/organisms/ErrorState'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import '@/components/modals/registerModals'
import serverCookieNotificationHandler from '@/components/pages/App/serverCookieNotificationHandler/serverCookieNotificationHandler'
import { ScreenWithDataProvider } from '@/components/templates/Screen'

import { CLIENT_INIT_QUERY } from './queries/clientInitQuery'

import type {
  ClientInitQuery,
  ClientInitQueryVariables,
  ClientInitQuery_user_subscription
} from './queries/__generated__/ClientInitQuery'
import { ArchivedType, Language as Languages } from '@/types'
import { Eater } from '@/types/index'

import ErrorPage from '../ErrorPage/ErrorPage'
import NotFoundErrorPage from '../NotFoundErrorPage/NotFoundErrorPage'

const widget = document.getElementsByClassName(
  'dixa-messenger-wrapper'
)[0] as HTMLElement

// initially hide dixa, add `showDixa` to `createAccountRouteObject.children.handler` to show dixa
if (widget) widget.style.display = 'none'

// TODO - Error handling page - add event to track errors
const ErrorBoundary = () => {
  const error = useRouteError()
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    // https://github.com/remix-run/react-router/discussions/9628#discussioncomment-5555901
    if (isRouteErrorResponse(error)) setErrorMessage(error.statusText)
    else if (error instanceof Error) setErrorMessage(error.message)
    else if (typeof error === 'string') setErrorMessage(error)
    else setErrorMessage('Unknown error')
  }, [error])

  useEffect(() => {
    Sentry.captureException(errorMessage)
  }, [errorMessage])

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFoundErrorPage data={error.data} />
  }

  return <ErrorPage error={errorMessage} />
}

type NavigateParams = (route: string, legacy: string) => void

/**
 * TEMP - a helper to navigate via react router is using the SPA, or via a full refresh if not
 */
const NavigateContext = createContext<NavigateParams>((route, legacy) => {
  window.location.href = legacy
})

const theme = createTheme({
  breakpoints: {
    values: BREAKPOINTS
  }
})

type PreviousRoute = {
  pathname: string
  params: Record<string, unknown>
}

type Props = {
  subscriptionData: ClientInitQuery_user_subscription | null
}

/**
 * The top level template. Contains functionality that should be available across every page on the site.
 */
const Base = ({ subscriptionData }: Props) => {
  const params = useParams()
  const navigate = useNavigate()
  const accessManagement = useAccessManagement()
  const location = useLocation()
  const { shippingCountry } = useShippingCountry()
  const { userLanguage } = useLanguage()
  const notificationHandlers = useNotifications()
  const [previous, setPrevious] = useState<PreviousRoute | null>(null)
  const [pathToCheck, setPathToCheck] = useState<string | null>(null)

  /**
   * TEMP - a helper to navigate via react router if using the SPA, or via a full refresh if not
   */
  const navigateCallback = useCallback<NavigateParams>(
    (route, legacy) => {
      if (route.indexOf(ACCOUNT_ROUTES.base) === 0) navigate(route)
      else window.location.href = legacy
    },
    [navigate]
  )

  /**
   * location.pathname is used to restore scroll position for specific routes
   *   In order to add this behaviour to the route, add the following to the route object:
   *   handle: { scrollMode: 'pathname' }
   *
   * location.key is the default behaviour for react router
   *
   * @see https://reactrouter.com/en/main/components/scroll-restoration#getkey
   */
  const handleScrollRestorationKey = useCallback((location, matches) => {
    const match = matches.find(
      ({ handle }: { handle?: { scrollMode?: string } }) => handle?.scrollMode
    )

    if (match?.handle?.scrollMode === 'pathname') {
      return location.pathname
    }

    return location.key
  }, [])

  // on route change
  useEffect(() => {
    if (isNull(accessManagement)) {
      setPathToCheck(location.pathname)
      return
    }

    if (previous?.pathname === location.pathname) return
    const redirectPath = accessManagement.getRedirectPath(location.pathname)

    if (redirectPath) navigate(redirectPath)
    else {
      // store the previous route info in react router state
      location.state = {
        ...location.state,
        previous
      }

      // watch for cookies set by the server which are used
      // to pop client side notifications
      serverCookieNotificationHandler(notificationHandlers)

      setPrevious({
        params,
        pathname: location.pathname
      })
    }

    trackEvent('Account navigation', {
      component_identifier: 'location'
    })
  }, [
    accessManagement,
    location,
    notificationHandlers,
    params,
    previous,
    navigate
  ])

  useEffect(() => {
    if (pathToCheck && !isNull(accessManagement)) {
      const redirectPath = accessManagement.getRedirectPath(pathToCheck)

      if (redirectPath) navigate(redirectPath)
      setPathToCheck(null)
    }
  }, [accessManagement, pathToCheck, navigate])

  if (pathToCheck) {
    return (
      <LoadingScreen
        isOpen={!!pathToCheck}
        title={{
          text: 'loading_screen.title',
          namespace: 'shared'
        }}
      />
    )
  }

  return (
    <LocalisationProvider
      preferredLanguage={userLanguage}
      shippingCountryCode={shippingCountry}
    >
      <NotificationProvider>
        <NavigateContext.Provider value={navigateCallback}>
          <ThemeProvider theme={theme}>
            <FestiveThemeProvider>
              <ParallaxProvider>
                <ProductUpsellProvider>
                  <Outlet />
                </ProductUpsellProvider>
                <ScrollRestoration getKey={handleScrollRestorationKey} />
              </ParallaxProvider>
            </FestiveThemeProvider>
          </ThemeProvider>
        </NavigateContext.Provider>
      </NotificationProvider>
    </LocalisationProvider>
  )
}

const initVariables: ClientInitQueryVariables = {
  nextNBoxes: 3,
  archivedType: ArchivedType.all
}

const App = withApollo((props: InjectedValues): JSX.Element | null => {
  const subscriptionData = useReactiveVar(subscriptionDataVar)
  const dogsData = useReactiveVar(dogsDataVar)
  const featureFlagData = useReactiveVar(featureFlagsDataVar)
  const userData = useReactiveVar(userDataVar)

  const showMFITBPlanOfferOnce =
    Cookies.get(cookies.swapToMFITBPlanOfferSeen) !== 'true'

  const { data, loading, error } = useQuery<
    ClientInitQuery,
    ClientInitQueryVariables
  >(CLIENT_INIT_QUERY, {
    variables: initVariables
  })
  const [initialised, setInitialised] = useState(false)
  const language: Languages = props.language || Languages.en
  const hideOrderPages = data?.shouldSeePlanManagementPhase3 === 'Variant'

  initI18n(language)

  useEffect(() => {
    if (data && !initialised) {
      const { user } = data
      const { dogs, subscription } = user
      const { id: subscriptionID, status, deliveriesReceived } = subscription
      /**
       * Set analytics dynamic default properties
       */
      const hasPuppy = dogs?.some(
        ({ ageInMonths }) => ageInMonths <= MAX_PUPPY_AGE_IN_MONTHS
      )
      const hasFussyEater = dogs?.some(
        ({ eaterType }) => eaterType === Eater.fussy_eater
      )
      dynamicDefaultTrackEventPropertiesVar({
        subscription_id: subscriptionID ?? '',
        subscription_status: status ?? '',
        deliveries_received: deliveriesReceived ?? 0,
        has_puppy: hasPuppy ?? false,
        has_fussy_eater: hasFussyEater ?? false
      })
      // Set dogs data
      dogsDataVar(
        !isNull(dogs) && dogs.length > 0
          ? {
              dogs: dogs,
              pronoun: pronounContext(
                dogs.map(({ gender }) => gender),
                language
              ),
              possessive: dogs.reduce(
                (previous, dog) => ({
                  ...previous,
                  [dog.id]: possessive(dog.name, language)
                }),
                {}
              ),
              has_puppy: hasPuppy ?? false,
              has_fussy_eater: hasFussyEater ?? false
            }
          : null
      )

      // Set subscription data
      subscriptionDataVar(
        !isNull(subscriptionData)
          ? {
              ...subscriptionData,
              ...subscription
            }
          : subscription
      )

      // Set user data
      userDataVar(
        !isNull(userData)
          ? {
              ...userData,
              ...user
            }
          : user
      )

      // Set shipping country data
      shippingCountryDataVar(data?.user.shippingCountry)

      featureFlagsDataVar({
        shouldSeeXmasTheme: data?.shouldSeeXmasTheme,
        shouldSeeOnlyMoveThisBoxCheckbox:
          data?.shouldSeeOnlyMoveThisBoxCheckbox,
        shouldSeeUpdatedDeliveryCalendarUI:
          data?.shouldSeeUpdatedDeliveryCalendarUI,
        shouldSeePlanManagementPhase3: data?.shouldSeePlanManagementPhase3,
        shouldSeeDogProfileV2: data?.shouldSeeDogProfileV2,
        shouldTrackErrorsOnErrorState: data?.shouldTrackErrorsOnErrorState,
        shouldSeeUpcomingPriceRiseInfo: data?.shouldSeeUpcomingPriceRiseInfo,
        shouldSeeSelfResolutions: data?.shouldSeeSelfResolutions,
        shouldSeeStap: data?.shouldSeeStap,
        systemShouldShowAppContent: data?.systemShouldShowAppContent,
        shouldSeeSwapToMFITBPlanOfferPage:
          data?.shouldSeeSwapToMFITBPlanOfferPage,
        shouldSeeThankYouPagev3: data?.shouldSeeThankYouPagev3,
        shouldSeeRafDiscountReminderModal:
          data?.shouldSeeRafDiscountReminderModal
      })

      hasNotificationsVar(data?.notifications?.length > 0)

      settingsVar(
        data?.settings.reduce(
          (acc: Record<string, boolean>, { name, value }) => {
            acc[name] = value
            return acc
          },
          {}
        )
      )

      setInitialised(true)
    }
  }, [data, initialised, language, subscriptionData, userData])

  if (error) {
    const errorDetails = {
      name: 'Error in client init query',
      message: error.message,
      apollo: error
    }
    return <ErrorState second_line={null} error={errorDetails} />
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Base subscriptionData={subscriptionData} />,
      errorElement: <ErrorBoundary />, // error handling page
      children: [
        {
          path: ACCOUNT_ROUTES.base,
          element: <ScreenWithDataProvider />,
          children: createAccountRouteObject(
            dogsData,
            subscriptionData,
            featureFlagData,
            !!data?.currentAdminUser?.id,
            userData?.affiliateType !== 'not_affiliate'
          )
        }
      ]
    }
  ])

  /**
   * On route transition scroll to hash if provided, otherwise keep the behaviour defined by
   * the ScrollRestoration provided by react-router-dom.
   *
   * To target the user going back through the state:
   * @example
   *    if (state.historyAction === 'POP' && state.navigation.state === 'idle')
   */
  router.subscribe((state) => {
    if (state.historyAction === 'PUSH' && state.navigation.state === 'idle') {
      const element: HTMLElement | null = state.location.hash
        ? document.querySelector(state.location.hash)
        : null

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  })

  return (
    <>
      {data && (
        <WithInjectedValues {...props}>
          <AccessManagementProvider
            status={data.user.subscription.status}
            dogs={data.user.dogs?.length}
            shippingCountry={data.user.shippingCountry.code}
            disableExtras={!data.user.shippingCountry.showExtras}
            hasPaymentMethod={userHasActivePaymentMethod(
              data.user.subscription?.paymentMethods
            )}
            isAmbassador={data.user.affiliateType === 'ambassador'}
            isInfluencer={data.user.affiliateType === 'influencer'}
            isEligibleForSwapAndSave={
              !isNull(data.user.subscription.plan.swappablePlan)
            }
            isEligibleForDownsizeAndSave={
              !isNull(data.user.subscription.plan.downsizeablePlan)
            }
            hideOrderPages={hideOrderPages}
            isEligibleForMFITBPlan={
              !isNull(data.user.subscription.increasedDeliveryCadencePlan) &&
              showMFITBPlanOfferOnce
            }
          >
            <ModalManagerProvider>
              <RouterProvider router={router} />
            </ModalManagerProvider>
          </AccessManagementProvider>
        </WithInjectedValues>
      )}
      <LoadingScreen
        isOpen={loading}
        title={{
          text: 'loading_screen.title',
          namespace: 'shared'
        }}
      />
    </>
  )
})

export { NavigateContext, BREAKPOINTS, initVariables }
export default App
