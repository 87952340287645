// @noflow
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from 'swiper'
import {
  Swiper,
  SwiperClass,
  SwiperProps,
  SwiperRef,
  SwiperSlide
} from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import { SwiperEvents, SwiperModule } from 'swiper/types'

import BREAKPOINTS from '@/constants/Breakpoints'

import { SliderHookVar } from '@/hooks/useSlider/useSlider'

import { SkeletonSlide } from './components/SkeletonSlide'
import { Props as CardSkeletonProps } from '@/components/elements/atoms/Card/CardSkeleton'
import IconButton from '@/components/elements/molecules/IconButton/IconButton'

import STYLES from './SwiperSlider.module.sass'

type ArrowColours = 'brandBlue100' | 'brandYellow200' | 'brandYellow300'

type Props = Pick<
  SwiperProps,
  'onActiveIndexChange' | 'watchOverflow' | 'spaceBetween' | 'allowTouchMove'
> & {
  variant?: ArrowColours
  children: JSX.Element | null | Array<JSX.Element | null>
  slidesPerView?: number | 'auto'
  bullets?: boolean
  arrows?: boolean
  thumbs?: boolean
  autoPlay?: boolean
  arrowsInside?: boolean
  arrowPosition?: number
  initialSlide?: number
  slider?: SwiperProps['onSwiper']
  skeleton?: Pick<CardSkeletonProps, 'width' | 'height'>
  className?: string
  autoHeight?: boolean
  paddingOverride?: 0 | 8 | 16 | 24 | 32
  id?: string
  loop?: boolean
  centeredSlides?: boolean
  onArrowClick?: () => void
  onLazyLoadNext?: () => void
  onLazyLoadPrev?: () => void
  onSlideChange?: SwiperEvents['slideChange']
  onSliderFirstMove?: SwiperEvents['sliderFirstMove']
  onThumbnailClick?: SwiperEvents['click']
}

const SwiperSlider = ({
  variant = 'brandYellow200',
  children,
  slidesPerView = 'auto',
  bullets = false,
  arrows,
  thumbs = false,
  autoPlay = false,
  arrowsInside = false,
  arrowPosition,
  spaceBetween = 16,
  initialSlide = 0,
  slider,
  skeleton,
  watchOverflow,
  className,
  autoHeight = false,
  paddingOverride = 0,
  id,
  allowTouchMove = true,
  loop = false,
  centeredSlides = false,
  onArrowClick,
  onLazyLoadNext,
  onLazyLoadPrev,
  onSlideChange,
  onSliderFirstMove,
  onThumbnailClick
}: Props): JSX.Element | null => {
  // Refs
  const swiperRef = useRef<SwiperRef | null>(null)
  const navigationPrevRef = useRef<HTMLDivElement>(null)
  const navigationNextRef = useRef<HTMLDivElement>(null)
  const previousSlidesCountRef = useRef(0)

  // State
  const [thumbnailSwiper, setThumbnailSwiper] = useState<SwiperClass | null>(
    null
  )
  const [totalSlides, setTotalSlides] = useState<number>(0)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isLoadingPrevious, setIsLoadingPrevious] = useState<boolean>(false)
  const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false)
  const [isNavigationPrevDisabled, setIsNavigationPrevDisabled] =
    useState(false)
  const [isNavigationNextDisabled, setIsNavigationNextDisabled] =
    useState(false)
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(0)

  // External state
  const sliderIndexUpdate = useReactiveVar(SliderHookVar)

  // Computed values
  const slideNodes = isArray(children)
    ? children
    : !isNull(children)
    ? [children]
    : []

  const isMobileDevice = window.innerWidth < BREAKPOINTS.md

  const shouldShowArrows = useMemo(
    () => arrows || (isUndefined(arrows) && !isMobileDevice),
    [arrows, isMobileDevice]
  )

  const minimumSlidesPerView = slidesPerView === 'auto' ? 3 : slidesPerView

  const isPreviousButtonDisabled = isLoadingPrevious || isNavigationPrevDisabled
  const isNextButtonDisabled = isLoadingNext || isNavigationNextDisabled

  // Utility functions
  const getSwiperModules = useCallback(
    (
      arrows: boolean,
      bullets: boolean,
      thumbs: boolean,
      autoPlay: boolean
    ): SwiperModule[] => {
      const modules = []

      if (arrows) modules.push(Navigation)
      if (bullets) modules.push(Pagination)
      if (thumbs) modules.push(Thumbs)
      if (autoPlay) modules.push(Autoplay)

      return modules
    },
    []
  )

  // Event handlers
  /**
   * Updates navigation buttons disabled state based on:
   * - Current slide position (beginning/end)
   * - Availability of lazy load callbacks
   */
  const handleNavigationStateChange = useCallback(
    (swiper: SwiperClass) => {
      setIsNavigationPrevDisabled(swiper.isBeginning && !onLazyLoadPrev)
      setIsNavigationNextDisabled(swiper.isEnd && !onLazyLoadNext)
    },
    [onLazyLoadPrev, onLazyLoadNext]
  )

  /**
   * Counts visible slides by querying slide content elements
   * and updates the visible slides count state
   */
  const handleVisibleSlidesChange = useCallback((swiper: SwiperClass) => {
    const visibleSlides = swiper.el.querySelectorAll(`.${STYLES.slideContent}`)
    setVisibleSlidesCount(visibleSlides.length)
  }, [])

  /**
   * Central update handler that synchronizes swiper state:
   * - Updates internal swiper state
   * - Updates navigation buttons state
   * - Updates visible slides count
   */
  const handleSwiperUpdate = useCallback(
    (swiper: SwiperClass) => {
      swiper.update()
      handleNavigationStateChange(swiper)
      handleVisibleSlidesChange(swiper)
    },
    [handleNavigationStateChange, handleVisibleSlidesChange]
  )

  /**
   * Handles lazy loading of slides based on:
   * - Swipe direction or forced direction
   * - Current position (beginning/end)
   * - Availability of lazy load callbacks
   */
  const handleLazyLoad = useCallback(
    (swiper: SwiperClass, forcedDirection?: 'prev' | 'next') => {
      const direction = forcedDirection || swiper.swipeDirection

      if (
        !isUndefined(onLazyLoadPrev) &&
        swiper.isBeginning &&
        direction === 'prev'
      ) {
        setIsLoadingPrevious(true)
        onLazyLoadPrev()
      }

      if (
        !isUndefined(onLazyLoadNext) &&
        swiper.isEnd &&
        direction === 'next'
      ) {
        setIsLoadingNext(true)
        onLazyLoadNext()
      }
    },
    [onLazyLoadPrev, onLazyLoadNext]
  )

  /**
   * Handles navigation arrow clicks:
   * - Determines which arrow was clicked
   * - Triggers appropriate lazy loading
   * - Calls optional click callback
   */
  const handleNavigationClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      const swiper = swiperRef.current?.swiper
      if (!swiper) return

      const target = e.currentTarget as HTMLElement

      const isNavigationButton = (button: React.RefObject<HTMLDivElement>) =>
        button.current?.contains(target)

      const isPreviousButton = isNavigationButton(navigationPrevRef)
      const isNextButton = isNavigationButton(navigationNextRef)

      if (isPreviousButton) {
        handleLazyLoad(swiper, 'prev')
      } else if (isNextButton) {
        handleLazyLoad(swiper, 'next')
      }

      onArrowClick?.()
    },
    [handleLazyLoad, onArrowClick]
  )

  /**
   * Initializes swiper with a slight delay to ensure
   * all refs and DOM elements are properly set up
   */
  const handleInit = useCallback(
    (swiper: SwiperClass) => {
      setTimeout(() => {
        handleSwiperUpdate(swiper)
      }, 0)
    },
    [handleSwiperUpdate]
  )

  /**
   * Handles slide change events:
   * - Updates swiper state
   * - Calls optional change callback
   */
  const handleSlideChange = useCallback(
    (swiper: SwiperClass) => {
      handleSwiperUpdate(swiper)
      onSlideChange?.(swiper)
    },
    [onSlideChange, handleSwiperUpdate]
  )

  /**
   * Handles skeleton slide loading for previous slides
   */
  const handleSkeletonLoadPrev = useCallback(() => {
    if (onLazyLoadPrev) {
      onLazyLoadPrev()
      setIsLoadingPrevious(true)
    }
  }, [onLazyLoadPrev])

  /**
   * Handles skeleton slide loading for next slides
   */
  const handleSkeletonLoadNext = useCallback(() => {
    if (onLazyLoadNext) {
      onLazyLoadNext()
      setIsLoadingNext(true)
    }
  }, [onLazyLoadNext])

  /**
   * Handles swiper instance initialization:
   * - Sets up navigation buttons
   * - Calls optional slider callback
   */
  const handleSwiper = useCallback(
    (swiper: SwiperClass): void => {
      if (slider) slider(swiper)

      // Delay execution for the refs to be defined
      const navigation = swiper.params.navigation
      if (
        typeof navigation === 'boolean' ||
        !shouldShowArrows ||
        isUndefined(navigation)
      ) {
        return
      }

      setTimeout(() => {
        // Override prevEl & nextEl now that refs are defined
        navigation.prevEl = navigationPrevRef.current
        navigation.nextEl = navigationNextRef.current

        if (isUndefined(swiper?.navigation)) {
          return
        }

        // Re-init navigation
        swiper.navigation.destroy()
        swiper.navigation.init()
        swiper.navigation.update()
      })
    },
    [slider, shouldShowArrows]
  )

  /**
   * Handles slider movement:
   * - Triggers lazy loading based on swipe direction
   */
  const handleSliderMove = useCallback(
    (swiper: SwiperClass) => {
      handleLazyLoad(swiper)
    },
    [handleLazyLoad]
  )

  // Effects
  /**
   * Handles swiper initialization and state synchronization.
   * Initialization:
   * - Sets initial slide position when component mounts
   * - Only runs once when all conditions are met
   *
   * State synchronization:
   * - Updates navigation buttons state
   * - Updates visible slides count
   * - Updates internal swiper state
   */
  useEffect(() => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) return

    // Handle initialization
    if (
      !isInitialized &&
      swiper.slides.length > 0 &&
      swiper.slides.length >= totalSlides
    ) {
      swiper.slideTo(initialSlide)
      setIsInitialized(true)
      return // Skip sync update during initialization
    }

    // Handle state synchronization
    handleSwiperUpdate(swiper)
  }, [initialSlide, isInitialized, totalSlides, handleSwiperUpdate])

  /**
   * Updates slider state when new slides are dynamically added.
   * Handles:
   * - Repositioning to slide 1 when loading previous slides
   * - Resetting loading states for both directions
   * - Updating total slides count
   * - Tracking previous slides count for comparison
   */
  useEffect(() => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) return

    const prevCount = previousSlidesCountRef.current
    const newSlidesAdded = visibleSlidesCount - prevCount

    if (newSlidesAdded > 0) {
      if (isLoadingPrevious && !isUndefined(onLazyLoadPrev)) {
        swiper.slideTo(1)
      }

      setIsLoadingNext(false)
      setIsLoadingPrevious(false)
      setTotalSlides(swiper.slides.length)
    }

    previousSlidesCountRef.current = visibleSlidesCount
  }, [
    isLoadingNext,
    isLoadingPrevious,
    onLazyLoadPrev,
    totalSlides,
    visibleSlidesCount
  ])

  /**
   * Handles external slide navigation requests via sliderIndexUpdate.
   * Supports:
   * - Moving to first/last slide
   * - Moving to next/previous slide
   * - Moving to a specific slide index
   * Resets the update hook after navigation is complete.
   */
  useEffect(() => {
    if (isNull(sliderIndexUpdate) || sliderIndexUpdate.id !== id) return

    const swiper = swiperRef.current?.swiper
    if (!swiper) return

    switch (sliderIndexUpdate.slide) {
      case 'first':
        swiper.slideTo(0)
        break
      case 'last':
        swiper.slideTo(totalSlides - 1)
        break
      case 'next':
        swiper.slideNext()
        break
      case 'prev':
        swiper.slidePrev()
        break
      default:
        swiper.slideTo(sliderIndexUpdate.slide)
        break
    }

    SliderHookVar(null)
  }, [sliderIndexUpdate, id, totalSlides])

  // Style computations
  const baseArrowStyles: CSSProperties | undefined = useMemo(
    () =>
      !isUndefined(arrowPosition)
        ? {
            // eslint-disable-next-line i18next/no-literal-string
            transform: `translate(0, ${arrowPosition}rem)`
          }
        : undefined,
    [arrowPosition]
  )

  const navigationStyles = useMemo(
    () => ({
      prev: {
        ...baseArrowStyles,
        opacity: isPreviousButtonDisabled ? 0.5 : 1
      },
      next: {
        ...baseArrowStyles,
        opacity: isNextButtonDisabled ? 0.5 : 1
      }
    }),
    [baseArrowStyles, isPreviousButtonDisabled, isNextButtonDisabled]
  )

  const sliderClassNames = useMemo(
    () =>
      classNames(STYLES.swiperSlider, {
        [STYLES.withThumbs]: thumbs,
        [STYLES.withBullets]: bullets,
        [STYLES.arrowsInside]: shouldShowArrows && arrowsInside,
        [STYLES[`withPaddingOverride${paddingOverride}`]]: paddingOverride !== 0
      }),
    [thumbs, bullets, shouldShowArrows, arrowsInside, paddingOverride]
  )

  return (
    <div className={sliderClassNames} id={id}>
      <div className={STYLES.sliderWrapper}>
        <Swiper
          ref={swiperRef}
          spaceBetween={spaceBetween}
          slidesOffsetBefore={paddingOverride}
          slidesOffsetAfter={paddingOverride}
          slidesPerView={slidesPerView}
          modules={getSwiperModules(
            shouldShowArrows,
            bullets,
            thumbs,
            autoPlay
          )}
          onInit={handleInit}
          navigation={
            shouldShowArrows
              ? {
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current
                }
              : shouldShowArrows
          }
          pagination={bullets ? { clickable: true } : bullets}
          thumbs={{ swiper: thumbs ? thumbnailSwiper : null }}
          autoplay={
            autoPlay ? { delay: 2500, disableOnInteraction: false } : autoPlay
          }
          initialSlide={initialSlide}
          className={className}
          observer
          onSwiper={handleSwiper}
          onSlideChange={handleSlideChange}
          onSliderMove={handleSliderMove}
          watchOverflow={watchOverflow}
          autoHeight={autoHeight}
          effect="fade"
          centeredSlidesBounds
          onSliderFirstMove={onSliderFirstMove}
          allowTouchMove={allowTouchMove}
          centeredSlides={centeredSlides}
          loop={loop}
        >
          {skeleton && !isUndefined(onLazyLoadPrev) && isInitialized && (
            <SwiperSlide key="loading-prev">
              <SkeletonSlide
                width={skeleton.width}
                height={skeleton.height}
                isLoading={isLoadingPrevious}
                onLoad={handleSkeletonLoadPrev}
              />
            </SwiperSlide>
          )}
          {slideNodes.map((child) => {
            if (isNull(child)) return null
            return (
              <SwiperSlide key={child.key}>
                <div className={STYLES.slideContent}>{child}</div>
              </SwiperSlide>
            )
          })}
          {skeleton && !isUndefined(onLazyLoadNext) && isInitialized && (
            <SwiperSlide key="loading-next">
              <SkeletonSlide
                width={skeleton.width}
                height={skeleton.height}
                isLoading={isLoadingNext}
                onLoad={handleSkeletonLoadNext}
              />
            </SwiperSlide>
          )}
          {skeleton &&
            !isUndefined(onLazyLoadPrev) &&
            !isLoadingPrevious &&
            visibleSlidesCount < minimumSlidesPerView && (
              <>
                {Array.from({
                  length: minimumSlidesPerView - visibleSlidesCount
                }).map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SwiperSlide key={`empty-${index}`}>
                    <div style={{ width: skeleton.width ?? 'auto' }} />
                  </SwiperSlide>
                ))}
              </>
            )}
        </Swiper>
        {shouldShowArrows && (
          <>
            <div
              className={STYLES.arrowLeft}
              ref={navigationPrevRef}
              style={navigationStyles.prev}
              role="button"
              aria-label="Previous slide"
              aria-disabled={isPreviousButtonDisabled}
            >
              <IconButton
                icon="chevron"
                iconColor="brandBlue500"
                size={48}
                variant={variant}
                iconSize={0.4}
                iconDirection="left"
                onClick={handleNavigationClick}
                disabled={isPreviousButtonDisabled}
                aria-hidden="true"
              />
            </div>
            <div
              className={STYLES.arrowRight}
              ref={navigationNextRef}
              style={navigationStyles.next}
              role="button"
              aria-label="Next slide"
              aria-disabled={isNextButtonDisabled}
            >
              <IconButton
                icon="chevron"
                iconColor="brandBlue500"
                size={48}
                variant={variant}
                iconSize={0.4}
                onClick={handleNavigationClick}
                disabled={isNextButtonDisabled}
                aria-hidden="true"
              />
            </div>
          </>
        )}
      </div>
      {thumbs && (
        <Swiper
          watchSlidesProgress
          onSwiper={setThumbnailSwiper}
          slidesPerView="auto"
          spaceBetween={8}
          freeMode
          modules={[FreeMode, Navigation, Thumbs]}
          grabCursor
          onClick={onThumbnailClick}
        >
          {slideNodes.map((child) => {
            if (isNull(child)) return null
            return (
              <SwiperSlide key={child.key}>
                <div className={STYLES.slideThumb}>{child}</div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </div>
  )
}

export type { Props }
export { SliderHookVar }

export default SwiperSlider
