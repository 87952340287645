import { gql } from '@apollo/client'

const DOG_DETAILS_QUERY = gql`
  query DogDetailsQuery {
    user {
      id
      dogs {
        id
        name
        gender
        dogProfile {
          id
          avatarUrl
        }
        breed {
          id
          key
          name
        }
        ageInMonths
        weightInGrams
        bodyShape
        exercise
        healthIssues {
          id
          name
        }
        allergens {
          id
          name
        }
        neutered
      }
    }
    healthIssues {
      id
      name
      warning
      information
    }
    allergens {
      id
      name
    }
  }
`

const UPDATE_DOG_DETAILS_MUTATION = gql`
  mutation DogsUpdate($userId: ID!, $attributes: [DogUpdateInput!]!) {
    dogsUpdate(userId: $userId, attributes: $attributes) {
      dogs {
        id
        name
        gender
        ageInMonths
        ageClassificationByUserWhenCreated
        dailyCalories
        weightInGrams
        bodyShape
        exercise
        healthIssues {
          id
        }
        allergens {
          id
        }
        dogProfile {
          avatarUrl
        }
        neutered
      }
      subscription {
        id
        idealPlan {
          id
          numberOfPouches
          durationInDays
          pricePerPouch
          pouchSize
          pricingCurve {
            pricingCohort
          }
          group
        }
      }
    }
  }
`

export { DOG_DETAILS_QUERY, UPDATE_DOG_DETAILS_MUTATION }
