import camelCase from 'lodash/camelCase'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

import { isBoxDelivered } from '@/utils/orderHelper'

import { Props as BoxDetailsProps } from '@/components/pages/Dashboard/components/boxDetails/BoxDetails'
import {
  upcomingBoxQuery_user_ordersByDate_Box as Box,
  upcomingBoxQuery_user_ordersByDate_NonCoreOrder as NonCoreOrder
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/__generated__/upcomingBoxQuery'

import { ApplicableDiscountPartDiscountBasis } from '@/types'

type Order = {
  id: string
  type: 'box' | 'nonCoreOrder' | 'oneOffBox'
  descriptor: Box['descriptor']
  amendable: boolean
  deliveryDate: Date
  cutOffDate: Date
  shippingDate: Date
  meals?: BoxDetailsProps['meals']
  extras?: BoxDetailsProps['extras']
  shouldOfferSelfService?: boolean
  discounts?: number[]
  deliveryStatus?: BoxDetailsProps['deliveryStatus']
  isBoxDelivered?: boolean
  deliveryDateAdjustedBySystem?: boolean
  pouchSize?: BoxDetailsProps['pouchSize']
  numberOfPouches?: BoxDetailsProps['numberOfPouches']
  durationInDays?: BoxDetailsProps['durationInDays']
  isFull?: boolean
  isTrialBox?: boolean
  isFreeBox?: boolean
  isReplacementBox?: boolean
  pricing?: BoxDetailsProps['pricing']
}

/**
 * Convert box and nonCoreOrders received from GraphQL to a common interface (Order)
 * @param order
 */
const unifyOrder = (order: Box | NonCoreOrder): Order => ({
  id: order.id,
  type:
    order.__typename === 'Box' && order.isOneOffBox
      ? 'oneOffBox'
      : (camelCase(order.__typename) as Order['type']),
  isTrialBox: 'isTrialBox' in order && order.isTrialBox,
  isFreeBox: 'isFreeBox' in order && order.isFreeBox,
  isReplacementBox: 'isReplacementBox' in order && order.isReplacementBox,
  descriptor: order.descriptor,
  amendable: 'amendable' in order ? order.amendable : false,
  deliveryDate:
    'isoDeliveryDate' in order
      ? new Date(order.isoDeliveryDate)
      : new Date(order.orderParts[0].deliveryDate),
  cutOffDate:
    'cutOffDate' in order
      ? new Date(order.cutOffDate)
      : new Date(order.orderParts[0].shippingDate),
  shippingDate:
    'shippingDate' in order
      ? new Date(order.shippingDate)
      : new Date(order.orderParts[0].shippingDate),
  meals:
    'meals' in order && !isNull(order.meals)
      ? order.meals
          .map((meal) => ({
            image: meal.flavour.thumbnail.src,
            alt: meal.flavour.name,
            quantity: meal.quantity
          }))
          .filter((meal) => meal.quantity > 0)
      : undefined,
  extras:
    'physicalOrderProducts' in order && isArray(order.physicalOrderProducts)
      ? order.physicalOrderProducts.map((extra) => ({
          image: extra.productVariant.productCollection.thumbnail.src,
          alt: extra.productVariant.name,
          quantity: extra.quantity,
          size: extra.productVariant.name
        }))
      : 'orderParts' in order
      ? order.orderParts
          .map((extras) =>
            extras.orderProducts.map((extra) => ({
              image: extra.productVariant.productCollection.thumbnail.src,
              alt: extra.productVariant.name,
              quantity: extra.quantity,
              size:
                'name' in extra.productVariant
                  ? extra.productVariant.name
                  : undefined
            }))
          )
          .flat()
      : undefined,
  shouldOfferSelfService:
    'shouldOfferSelfService' in order ? order.shouldOfferSelfService : false,
  discounts:
    'discounts' in order
      ? order.discounts
          ?.filter((discount) => {
            const { discountBasis, value } =
              discount.applicableDiscountPart ?? {}
            return (
              !isUndefined(discountBasis) &&
              !isUndefined(value) &&
              discountBasis === ApplicableDiscountPartDiscountBasis.percentage
            )
          })
          .map((discount) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { value } = discount.applicableDiscountPart!
            return value
          })
      : undefined,
  deliveryStatus:
    order.__typename === 'Box'
      ? order.consignment?.status
      : order.__typename === 'NonCoreOrder'
      ? order.orderParts[0]?.consignment?.status
      : undefined,
  isBoxDelivered: isBoxDelivered(order),
  deliveryDateAdjustedBySystem:
    'deliveryDateAdjustedBySystem' in order
      ? order.deliveryDateAdjustedBySystem
      : undefined,
  pouchSize: 'plan' in order ? order.plan.pouchSize : undefined,
  isFull:
    'productSpacesFree' in order ? order.productSpacesFree < 1 : undefined,
  numberOfPouches:
    'numberOfPouches' in order ? order.numberOfPouches : undefined,
  durationInDays: 'durationInDays' in order ? order.durationInDays : undefined,
  pricing:
    order.__typename === 'Box'
      ? {
          ...order.order,
          planPricePerDay: order.planPricePerDay
        }
      : order.__typename === 'NonCoreOrder'
      ? {
          invoice: order.invoice,
          deliverySurcharge: order.orderParts[0]?.deliverySurcharge,
          smallOrderSurcharge: order.orderParts[0]?.smallOrderSurcharge,
          orderProductsTotal: order.orderParts[0]?.orderProductsTotal
        }
      : undefined
})

export { unifyOrder, Order }
