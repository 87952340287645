// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { animated, useSpring, useTransition } from 'react-spring'

import { useLocalisationHelper } from '@/hooks/useLocalisationHelper/useLocalisationHelper'

import Arrow from 'assets/images/icons/arrows/arrow--blue.svg'

import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './PlanOverview.module.sass'

import type { EditDeliveryFrequencyQuery_user_subscription_plan as Plan } from '../../queries/__generated__/EditDeliveryFrequencyQuery'

type Props = {
  currentPlan: Plan | null
  chosenPlan: Plan | null
  benefitType: string | null
  hasNumberOfPouchesChanged: boolean
  hasPlanChanged: boolean
  loading?: boolean
}

const PlanOverview = ({
  currentPlan,
  chosenPlan,
  benefitType,
  hasNumberOfPouchesChanged,
  hasPlanChanged,
  loading = false
}: Props): React.JSX.Element => {
  const { t } = useTranslation('account')
  const { formatPrice } = useLocalisationHelper()

  const currentNumberOfPouchesAnimatedStyle = useSpring({
    opacity: hasNumberOfPouchesChanged && !loading ? 0.5 : 1,
    config: {
      duration: 200
    }
  })

  const chosenNumberOfPouchesTransitions = useTransition(
    hasNumberOfPouchesChanged && !loading,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 200 }
    }
  )

  const cardTransitions = useTransition(hasPlanChanged, {
    from: { opacity: 0, transform: 'translateY(-10px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-10px)' },
    config: { duration: 200 }
  })

  if (!loading && (!currentPlan || !chosenPlan)) {
    return (
      <Text
        namespace="account"
        text="edit_delivery_frequency.plan_overview.error"
        margin={false}
        variant="textRegular20"
        colour="brandRed500"
      />
    )
  }

  return (
    <SectionWrapper margin={{ top: 0, bottom: 0 }}>
      <SectionWrapper
        bgColour="brandYellow200"
        borderRadius={16}
        padding={{ top: 48, right: 16, bottom: 48, left: 16 }}
        margin={{ top: 0, bottom: 8 }}
        className={STYLES.planWrapper}
      >
        <div className={STYLES.contentWrapper}>
          <Image
            alt={t('edit_delivery_frequency.plan_overview.bg_img_alt')}
            slug="beef-bowl"
            image={{
              width: 220,
              height: 220
            }}
            className={STYLES.bgImage}
          />
          <Image
            alt={t('edit_delivery_frequency.plan_overview.pouches_img_alt')}
            slug="two-pouches"
            image={{
              width: 96,
              height: 100
            }}
          />
          <SectionWrapper margin={{ top: 0, bottom: 0 }}>
            <div className={STYLES.numberOfPouchesWrapper}>
              {loading ? (
                <SkeletonParagraph
                  align="left"
                  count={1}
                  shortLine={false}
                  width={28}
                  height={28}
                  margin="0 0 .8rem"
                />
              ) : (
                <animated.div style={currentNumberOfPouchesAnimatedStyle}>
                  <Text
                    text={`${currentPlan?.numberOfPouches ?? 0}`}
                    variant="display24"
                    translate={false}
                    margin={false}
                  />
                </animated.div>
              )}
              {chosenNumberOfPouchesTransitions(
                (style, show) =>
                  show && (
                    <animated.div
                      style={style}
                      className={STYLES.chosenNumberOfPouches}
                    >
                      <img alt="" role="presentation" src={Arrow} />
                      <Text
                        text={`${chosenPlan?.numberOfPouches}`}
                        variant="display24"
                        translate={false}
                        margin={false}
                      />
                    </animated.div>
                  )
              )}
            </div>
            <Text
              namespace="account"
              text="edit_delivery_frequency.plan_overview.description"
              margin={false}
            />
          </SectionWrapper>
        </div>
      </SectionWrapper>
      {cardTransitions(
        (style, show) =>
          show && (
            <animated.div style={style}>
              <div
                className={STYLES.triangle}
                role="presentation"
                aria-label="triangle"
              />
              <Card padding={24}>
                <Text
                  namespace="account"
                  text="edit_delivery_frequency.plan_overview.new_price_per_day"
                  margin={false}
                  variant="textRegular20"
                  variables={{
                    pricePerDay: formatPrice(chosenPlan?.pricePerDay ?? 0)
                  }}
                />
                <Text
                  namespace="account"
                  text="edit_delivery_frequency.plan_overview.current_price_per_day"
                  margin={false}
                  shouldScale={false}
                  colour="brandBlue400"
                  variables={{
                    pricePerDay: formatPrice(currentPlan?.pricePerDay ?? 0)
                  }}
                />
                {benefitType && (
                  <SectionWrapper margin={{ top: 16, bottom: 0 }}>
                    <Text
                      namespace="account"
                      text="edit_delivery_frequency.plan_overview.benefit"
                      margin={false}
                      shouldScale={false}
                      colour="supportGreen500"
                      variables={{
                        context: benefitType
                      }}
                    />
                  </SectionWrapper>
                )}
              </Card>
            </animated.div>
          )
      )}
    </SectionWrapper>
  )
}

export { PlanOverview }
