import { format } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import useWindowSize from '@/hooks/useWindowSize'

import PausedCalendar from './components/Calendar'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text'
import IconCard from '@/components/elements/molecules/IconCard/IconCard'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './PausedDatePicker.module.sass'

import { TreatmentsPausedPageQuery_user } from '../../queries/__generated__/TreatmentsPausedPageQuery'

type Props = {
  user: TreatmentsPausedPageQuery_user
  pausedDeliveryDate: Date
  setPausedDeliveryDate: (selectedDate: Date) => void
}

const PausedDatePicker = ({
  user,
  pausedDeliveryDate,
  setPausedDeliveryDate
}: Props): JSX.Element | null => {
  const [modalOpen, setmodalOpen] = useState(false)
  const [displayDate, setDisplayDate] = useState(pausedDeliveryDate)

  useEffect(() => setDisplayDate(pausedDeliveryDate), [pausedDeliveryDate])

  const { windowWidth } = useWindowSize()

  const toggleModal = useCallback(() => setmodalOpen((prev) => !prev), [])

  const date = new Date(displayDate)

  const handleDateChange = useCallback(
    (newDate) => {
      setDisplayDate(newDate)
    },
    [setDisplayDate]
  )
  const dateLocale = localeToDateLocale(
    user.shippingCountryCode,
    user.preferredLanguage
  )

  const handleSubmitDateChange = useCallback(
    (date) => {
      setPausedDeliveryDate(date)
      setDisplayDate(date)
      toggleModal()
    },
    [setPausedDeliveryDate, setDisplayDate, toggleModal]
  )

  return (
    <>
      <div className={STYLES.dateWrapper}>
        <IconCard
          padding={16}
          variant={{
            border: 'transparent',
            background: 'brandBlue100',
            selected: 'transparent'
          }}
          heading={{
            text: format(date, 'EEE dd MMM', { locale: dateLocale }),
            translate: false
          }}
          icon={{ asset: 'van' }}
          onClick={toggleModal}
          border={'solid'}
          identifier="paused_date_picker"
        />
      </div>

      <Modal
        isModalOpen={modalOpen}
        setOpenModal={toggleModal}
        width={800}
        bottomSticky={windowWidth < BREAKPOINTS.md}
        textAlign="center"
        backgroundColour="brandYellow100"
      >
        <Text
          text="home.upcoming_boxes.edit_delivery_date_cta"
          namespace="account"
          variant="display24"
          align="center"
        />

        <PausedCalendar
          userId={user.id}
          setSelectedDate={handleDateChange}
          pausedDeliveryDate={new Date(pausedDeliveryDate)}
          handleSubmit={handleSubmitDateChange}
        />
      </Modal>
    </>
  )
}

export { PausedDatePicker }
