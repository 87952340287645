// @noflow
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import last from 'lodash/last'
import throttle from 'lodash/throttle'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Utils
import { pronounContext } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import useIntersectionObserver from '@/hooks/useIntersectionObserver/useIntersectionObserver'
// Hooks
import useWindowSize from '@/hooks/useWindowSize'

import PlansStickyNavigation from '../Recipes/components/PlansStickyNavigation/PlansStickyNavigation'
// Components
import BoxPlanCard from './components/BoxPlanCard/BoxPlanCard'
import StarterBoxCard from './components/StarterBoxCard/StarterBoxCard'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text'
import Trustpilot, {
  Theme,
  Variant
} from '@/components/elements/atoms/TrustpilotWidget/TrustpilotWidget'
import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'

// Styles
import STYLES from './plan.module.sass'

// Queries
import GUEST_QUERY from '../../queries/GuestQuery'
import PENDING_SUBSCRIPTION_ORDER_PRICING_QUERY from '../../queries/PendingSubscriptionOrderPricingQuery'

import {
  PendingSubscriptionOrderPricingQuery,
  PendingSubscriptionOrderPricingQueryVariables
} from '../../queries/__generated__/PendingSubscriptionOrderPricingQuery'
import { SimplifiedGuestQuery } from '../../queries/__generated__/SimplifiedGuestQuery'
import { Language } from '@/types'

import { Flavour, PlanState, plansPageState } from '../../SimplifiedPlansPage'
import {
  trackSizeTooltipToggled,
  trackTrustpilotSectionSeen
} from '../../helpers/analytics'
// Routes
import {
  currentRouteToCurrentStep,
  currentRouteToPercentFilled,
  currentRouteToTotalStep
} from '../../helpers/plansNavigation'
import useCreatePendingSubscription from '../../hooks/useCreatePendingSubscription'
import useIdealPlan from '../../hooks/useIdealPlan'
import { Routes as PlansRoutes } from '../../types/routes'

type Props = {
  namespace: string
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
  shouldSeeSkipPlanPage: boolean
}

const Plan = ({
  namespace,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans,
  shouldSeeSkipPlanPage
}: Props): JSX.Element | null => {
  const copyContext = 'plan'

  const trustpilotSectionRef = useRef<HTMLDivElement>(null)

  const [strikethroughPrice, setStrikethroughPrice] = useState(0)
  const plansState: PlanState = useReactiveVar(plansPageState)

  const { windowWidth } = useWindowSize()
  // A custom breakpoint is need here, as we use MUI Grid to change the layout and they use a different breakpoint,
  // failing to do so will result on the arrow pointing at the wrong direction in a certain screen size
  const isMobile = windowWidth < 960

  const {
    selectedPlan,
    idealAllPlan,
    idealMixPlan,
    discountedPricePerDay,
    updateSelectedPlan
  } = useIdealPlan()

  const idealPlans = [idealAllPlan, idealMixPlan].filter((plan) => !isNil(plan))

  const { data, loading, error } = useQuery<SimplifiedGuestQuery>(GUEST_QUERY)

  const [getPricing, { error: pendingSubscriptionOrderPricingError }] =
    useLazyQuery<
      PendingSubscriptionOrderPricingQuery,
      PendingSubscriptionOrderPricingQueryVariables
    >(PENDING_SUBSCRIPTION_ORDER_PRICING_QUERY, {
      onCompleted: (data) => {
        // NOTE: Since the last object in the array is a standard order, and the first is the first box order.
        // To get the standard order prices, we need the last object in the array
        const standardOrderPrices = last(data.pendingSubscriptionOrderPrices)

        setStrikethroughPrice(
          standardOrderPrices?.standardOrderCoreFoodGrossPricePerDay || 0
        )
      }
    })

  const throttleGetPricing = useMemo(
    () =>
      throttle((variables) => {
        getPricing({ variables })
      }, 1000),
    [getPricing]
  )

  const { createPendingSubscription, createPendingSubscriptionLoading } =
    useCreatePendingSubscription()

  const handleTooltipOpened = useCallback(() => {
    trackSizeTooltipToggled()
  }, [])

  const trustpilotSectionSeen = useIntersectionObserver(trustpilotSectionRef, {
    threshold: [0.2]
  })

  useEffect(() => {
    if (trustpilotSectionSeen) {
      trackTrustpilotSectionSeen()
    }
  }, [trustpilotSectionSeen])

  if (error) {
    Sentry.captureException(
      `Error in Plans Flow - Plan step: SimplifiedGuestQuery`,
      {
        extra: {
          error
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  }

  if (pendingSubscriptionOrderPricingError) {
    Sentry.captureException(
      `Error in Plans Flow - Plan step: PendingSubscriptionOrderPricingQuery`,
      {
        extra: {
          pendingSubscriptionOrderPricingError
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  }

  useEffect(() => {
    if (plansState.planId && !isEmpty(plansState.flavours)) {
      throttleGetPricing({
        planId: !isNull(plansState.planId) ? plansState.planId : '',
        productVariantIds: !isNull(plansState.productVariants)
          ? plansState.productVariants.map(({ id }) => id)
          : undefined,
        flavourQuantities: !isNull(plansState.flavours)
          ? plansState.flavours?.reduce(
              (breakdown: { [key: string]: number }, flavour: Flavour) => {
                breakdown[camelCase(flavour.slug)] = 0
                return breakdown
              },
              {}
            )
          : undefined,
        discountCodeId:
          data?.currentUser?.__typename === 'Guest'
            ? data?.currentUser.discountCode?.id ?? ''
            : ''
      })
    }
  }, [throttleGetPricing, plansState, data?.currentUser])

  const navigate = useNavigate()

  const handleOnClick = useCallback(
    (hasRecommendedExtras: boolean) => {
      if (hasRecommendedExtras) {
        navigate(PlansRoutes.Extras)
      } else {
        createPendingSubscription()
      }
    },
    [createPendingSubscription, navigate]
  )

  const currentStep = useCallback(() => {
    return currentRouteToCurrentStep({
      route: PlansRoutes.Plan,
      hasRecommendedExtras,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    })
  }, [hasRecommendedExtras, shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  const totalSteps = useCallback(() => {
    return currentRouteToTotalStep(
      hasRecommendedExtras,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    )
  }, [hasRecommendedExtras, shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  const { locale, currency } = countryCodeToLocaleCurrency(
    data?.currentUser?.__typename === 'Guest'
      ? data?.currentUser?.assumedShippingCountry.code
      : 'GB',
    data?.currentUser?.__typename === 'Guest'
      ? data?.currentUser?.preferredLanguage
      : Language.en
  )

  if (data?.currentUser?.__typename !== 'Guest') return null

  const {
    dogs,
    assumedShippingCountry,
    preferredLanguage,
    numberOfAffiliateFreeBoxes
  } = data?.currentUser

  const dogNames = dogs.map(({ name }) => name)
  const dogGenders = dogs.map(({ gender }) => gender)
  const dogPronoun = pronounContext(dogGenders, preferredLanguage)

  return (
    <div className={STYLES.container}>
      <WizardNavigation
        variant="simplified"
        percentFilled={currentRouteToPercentFilled({
          route: PlansRoutes.Plan,
          hasRecommendedExtras,
          shouldSeePetParentOnPlans,
          shouldSeeSkipPlanPage
        })}
        currentStep={currentStep()}
        totalSteps={totalSteps()}
        backUrl={PlansRoutes.Recipes}
        customText={
          shouldSeeSkipPlanPage
            ? {
                text: 'recommended_box_navigation.your_box',
                namespace,
                variant: 'textRegular14',
                margin: false,
                variables: {
                  currentStep: currentStep(),
                  totalSteps: totalSteps()
                }
              }
            : undefined
        }
      />
      <Container maxWidth="lg">
        <Grid>
          <div className={STYLES.titleWrapper}>
            <Text
              dataTestId="hero-text-title"
              namespace={namespace}
              variant="display24"
              text={`${copyContext}.title`}
              variables={{ dogName: dogNames, count: dogNames.length }}
              align="center"
              margin={false}
            />
          </div>
          <Grid container justifyContent="center">
            <Grid container justifyContent="center" item xs={12} md={5} lg={5}>
              <StarterBoxCard
                copyContext={copyContext}
                dogNames={dogNames}
                dogPronoun={dogPronoun}
                namespace={namespace}
                idealPlans={idealPlans}
                selectedPlan={selectedPlan}
                loading={loading}
                handleTooltipOpened={handleTooltipOpened}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <div className={STYLES.arrowWrapper}>
                <Icon
                  asset="arrow"
                  size={40}
                  width={40}
                  direction={isMobile ? 'down' : 'right'}
                  accentColour="cardboardBrown"
                />
              </div>
            </Grid>
            <Grid container justifyContent="center" item xs={12} md={5}>
              <BoxPlanCard
                copyContext={copyContext}
                dogNames={dogNames}
                dogPronoun={dogPronoun}
                namespace={namespace}
                loading={loading}
                preferredLanguage={preferredLanguage}
                idealPlans={idealPlans}
                updateSelectedPlan={updateSelectedPlan}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {!!assumedShippingCountry.trustpilotBusinessUnitId && (
        <div className={STYLES.trustpilotSection} ref={trustpilotSectionRef}>
          <div className={STYLES.trustpilotReview}>
            <Text
              namespace={namespace}
              variant="textRegular16"
              colour="brandWhite"
              text={`${copyContext}.trustpilot_review`}
              align="center"
              margin={false}
            />
            <div className={STYLES.trustpilotWidget}>
              <Trustpilot
                businessUnitId={assumedShippingCountry.trustpilotBusinessUnitId}
                width="300px"
                height="25px"
                variant={Variant.Slim}
                theme={Theme.Dark}
              />
            </div>
          </div>
        </div>
      )}
      <PlansStickyNavigation
        currency={currency}
        locale={locale}
        namespace={namespace}
        disabled={createPendingSubscriptionLoading}
        discountedPrice={
          numberOfAffiliateFreeBoxes < 1 ? discountedPricePerDay : 0
        }
        // eslint-disable-next-line react/jsx-no-bind
        onNextClick={() => handleOnClick(hasRecommendedExtras)}
        ongoingPrice={strikethroughPrice}
        showStrikethroughPrice
        showNoRecipesSelectedMessage={false}
      />
    </div>
  )
}

export default Plan
