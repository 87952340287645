// @noflow
import { Language } from '@/packs/localisation'
import { isUndefined } from 'lodash'
import React, { ReactNode } from 'react'

// Utils
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

// Components
import ProductThumb from '@/components/elements/atoms/ProductThumb/ProductThumb'
import Text from '@/components/elements/atoms/Text/Text'

// Styles
import STYLES from './../boxitem.module.sass'

// Types
import { Code } from '@/types'

type Config = {
  imageSize?: 'xsmall' | 'small' | 'medium'
  noBorder?: boolean
}

type Props = {
  name: string
  quantity: number
  size: string
  thumbnail: string
  shippingCountryCode: Code
  language: Language
  children?: ReactNode
  price?: number
  discountedPrice?: number
  config?: Config
}

const AdditionalProductItem = ({
  name,
  quantity,
  size,
  thumbnail,
  children,
  shippingCountryCode,
  language,
  price,
  discountedPrice,
  config
}: Props): JSX.Element => {
  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    language
  )

  const namespace = 'shared'
  const copyContext = 'box_breakdown'

  const isFree = discountedPrice === 0
  const isDiscounted = discountedPrice != null

  return (
    <div
      className={`${STYLES.boxItemRow} ${
        config?.noBorder ? STYLES.noBorder : ''
      }`}
    >
      <div className={STYLES.details}>
        <div className={STYLES.freshMealItemImage}>
          {!isUndefined(thumbnail) && (
            <ProductThumb
              image={thumbnail}
              alt=""
              size={config?.imageSize || 'xsmall'}
            />
          )}
        </div>
        <div className={STYLES.main}>
          <div className={STYLES.nameAndPrice}>
            <div className={STYLES.name}>
              <Text text={name} margin={false} translate={false} align="left" />
            </div>
            {price && (
              <div className={STYLES.discountedPrice}>
                {isDiscounted && (
                  <Text
                    text={
                      isFree
                        ? `${copyContext}.free`
                        : formatCurrencyWithDecimal(discountedPrice, {
                            locale,
                            currency
                          })
                    }
                    margin={false}
                    translate={isFree}
                    colour="brandRed500"
                    bold
                    namespace={namespace}
                  />
                )}
                <Text
                  text={
                    isDiscounted
                      ? `<accent type='strikeThrough'>${formatCurrencyWithDecimal(
                          price,
                          {
                            locale,
                            currency
                          }
                        )}</accent>`
                      : formatCurrencyWithDecimal(price, {
                          locale,
                          currency
                        })
                  }
                  margin={false}
                  translate={false}
                  colour={isDiscounted ? 'brandBlue400' : 'brandBlue500'}
                  bold={!isDiscounted}
                />
              </div>
            )}
          </div>
          <div className={STYLES.boxItemDescription}>
            <Text
              text={`${copyContext}.additional_product_quantity`}
              variables={{
                quantity,
                size
              }}
              namespace={namespace}
              variant="textRegular14"
              colour="brandBlue400"
              margin={false}
              element="span"
            />
          </div>
        </div>
      </div>
      {children && <div className={STYLES.children}>{children}</div>}
    </div>
  )
}

export { Props }
export default AdditionalProductItem
