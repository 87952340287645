import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useWindowSize from '@/hooks/useWindowSize'

import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './RafDiscountReminderModal.module.sass'

import { Button } from '../../atoms/Button'
import Image from '../../atoms/Image'
import Modal from '../../atoms/Modal/Modal'
import Text from '../../atoms/Text'

const copyContext = 'raf_reminder_modal'
const namespace = 'dashboard'

const RAF_REMINDER_COOKIE_NAME = 'raf_reminder_modal_seen'
const RAF_REMINDER_COOKIE_VALUE_SEEN = 'true'

const RafDiscountReminderModal = ({
  discount
}: {
  discount: string
}): JSX.Element => {
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    const seen =
      Cookies.get(RAF_REMINDER_COOKIE_NAME) === RAF_REMINDER_COOKIE_VALUE_SEEN

    setShowModal(!seen)
  }, [showModal])

  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const toggleModal = useCallback(() => {
    Cookies.set(RAF_REMINDER_COOKIE_NAME, RAF_REMINDER_COOKIE_VALUE_SEEN)
    setShowModal((prev) => !prev)
  }, [])

  const { t } = useTranslation(namespace)

  return (
    <Modal
      setOpenModal={toggleModal}
      onCloseButtonClick={toggleModal}
      isModalOpen={showModal}
      width={450}
      bottomSticky={isMobile}
      padding={false}
    >
      <article className={STYLES.inner}>
        <Image
          slug="refer-a-friend-discount-reminder"
          image={{
            width: 700,
            height: 394,
            quality: 'auto:best'
          }}
          alt={t(`${copyContext}.image_alt`)}
        />

        <section className={STYLES.copy}>
          <div className={STYLES.title}>
            <Text
              margin={false}
              variant="display20"
              text={`${copyContext}.title`}
              namespace={namespace}
              variables={{ discount }}
              align="center"
              colour="brandBlue500"
            />
          </div>

          <Text
            text={`${copyContext}.description`}
            variables={{ discount }}
            namespace={namespace}
            colour="brandBlue500"
            align="center"
            margin={false}
          />

          <Button
            fullWidth
            typography={{
              text: `${copyContext}.button`,
              namespace
            }}
            onClick={toggleModal}
            identifier="discount_reminder_modal.primary_button"
          />
        </section>
      </article>
    </Modal>
  )
}

export default RafDiscountReminderModal
export { RAF_REMINDER_COOKIE_NAME, RAF_REMINDER_COOKIE_VALUE_SEEN }
