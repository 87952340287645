import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'

// Styles
import STYLES from './RecommendedBox.module.sass'

import {
  currentRouteToCurrentStep,
  currentRouteToPercentFilled,
  currentRouteToTotalStep
} from '../../helpers/plansNavigation'
import { Routes as PlansRoutes } from '../../types/routes'

type Props = {
  namespace: string
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
  shouldSeeSkipPlanPage: boolean
}

const RecommendedBox = ({
  namespace,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans,
  shouldSeeSkipPlanPage
}: Props): JSX.Element => {
  const navigate = useNavigate()

  const handleBackButton = useCallback(() => {
    if (shouldSeePetParentOnPlans) {
      navigate(PlansRoutes.PetParent)
    } else {
      window.location.href = '/wizard/pet-parent'
    }
  }, [navigate, shouldSeePetParentOnPlans])

  const currentStep = useCallback(() => {
    return currentRouteToCurrentStep({
      route: PlansRoutes.RecommendedBox,
      hasRecommendedExtras,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    })
  }, [hasRecommendedExtras, shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  const totalSteps = useCallback(() => {
    return currentRouteToTotalStep(
      hasRecommendedExtras,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    )
  }, [hasRecommendedExtras, shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  return (
    <div className={STYLES.container}>
      <WizardNavigation
        variant="simplified"
        percentFilled={currentRouteToPercentFilled({
          route: PlansRoutes.RecommendedBox,
          hasRecommendedExtras,
          shouldSeePetParentOnPlans,
          shouldSeeSkipPlanPage
        })}
        currentStep={currentStep()}
        totalSteps={totalSteps()}
        backOnClick={handleBackButton}
        customText={{
          text: 'recommended_box_navigation.your_box',
          namespace,
          variant: 'textRegular14',
          margin: false,
          variables: { currentStep: currentStep(), totalSteps: totalSteps() }
        }}
      />
    </div>
  )
}

export default RecommendedBox
