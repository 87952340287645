import { gql } from '@apollo/client'

import { EDIT_DELIVERY_FREQUENCY_FRAGMENT } from '../fragments/editDeliveryFrequencyFragment'

const EDIT_DELIVERY_FREQUENCY_QUERY = gql`
  ${EDIT_DELIVERY_FREQUENCY_FRAGMENT}
  query EditDeliveryFrequencyQuery($scopeType: AvailablePlansScopeType) {
    user {
      id
      ...EditDeliveryFrequencyFragment
    }
  }
`

export { EDIT_DELIVERY_FREQUENCY_QUERY }
