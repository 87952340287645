import { gql } from '@apollo/client'

import { DELIVERY_FREQUENCY_OVERVIEW_FRAGMENT } from '@/components/elements/molecules/DeliveryFrequencyOverview/fragments/deliveryFrequencyOverviewFragment'

const DELIVERY_DATE_USER_FRAGMENT = gql`
  ${DELIVERY_FREQUENCY_OVERVIEW_FRAGMENT}
  fragment DeliveryDateUserFragment on User {
    id
    shippingCountryCode
    shippingCountry {
      id
      showUpcomingOrderSeeMoreExtras
    }
    subscription {
      id
      nextNBoxes(num: $nextNBoxes) {
        id
        isoDeliveryDate
        editable
      }
      box(boxId: $boxId) {
        id
        isoDeliveryDate
        editable
      }
      plan {
        ...DeliveryFrequencyOverviewFragment
      }
    }
  }
`

export { DELIVERY_DATE_USER_FRAGMENT }
