// @noflow
import classNames from 'classnames'
import React from 'react'

import { transformIndents } from '@/utils/transformIndents'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { HeaderRightAdornment } from './components/HeaderRightAdornment'
import type { HeaderRightAdornmentProps } from './components/HeaderRightAdornment'
import Text from '@/components/elements/atoms/Text'
import type { TextProps } from '@/components/elements/atoms/Text'

import STYLES from './SectionWrapper.module.sass'

type IndentValues = 0 | 4 | 8 | 16 | 24 | 32 | 48
type Indent = {
  top?: IndentValues
  right?: IndentValues
  bottom?: IndentValues
  left?: IndentValues
}

type BorderRadius = 0 | 16

type BgColour =
  | 'transparent'
  | 'brandWhite'
  | 'brandWhite90'
  | 'brandYellow100'
  | 'brandYellow200'
  | 'brandYellow300'
  | 'cardboardBrown'
  | 'brandBlue200'
  | 'brandBlue300'
  | 'brandBlue400'

type Props = {
  children?: JSX.Element | JSX.Element[] | null
  headerTypography?: TextProps
  headerRightAdornment?: HeaderRightAdornmentProps
  headerLeftAdornment?: HeaderRightAdornmentProps
  headerAlignment?: 'start' | 'center' | 'end'
  margin?: Indent | IndentValues
  padding?: Indent | IndentValues
  bgColour?: BgColour
  borderRadius?: BorderRadius
  id?: HTMLDivElement['id']
  className?: string
  headerClassName?: string
}

/**
 * SectionWrapper
 *
 * Use this component to wrap a section with a possibility to add a title, right adornment,
 * indents etc.
 *
 * @example
  ```
  <SectionWrapper
    headerTypography={{
      text: 'path.to.title.translation.key'
    }}
    headerRightAdornment={{
      variant: 'text',
      typography: {
        text: 'path.to.right_adornment.translation.key'
      }
    }}
    margin={{
      left: -16,
      right: -16
    }}
    padding={{
      left: 16,
      right: 16
    }}
    bgColour="brandYellow100"
  >
    <Text text="path.to.content.translation.key" />
  </SectionWrapper>
  ```
 * @param {TextProps} [headerTypography] - Header typography
 * @param {HeaderRightAdornmentProps} [headerRightAdornment] - Header right adornment
 * @param {Indent} [margin] - Margin
 * @param {Indent} [padding] - Padding
 * @param {BgColour} [bgColour] - Background colour
 * @param {BorderRadius} [borderRadius] - Border radius
 * @param {string} [headerAlignment] - Header alignment
 * @returns {JSX.Element} - SectionWrapper component
 * @category Components
 * @subcategory Atoms
 * @component SectionWrapper
 */
const SectionWrapper = ({
  children,
  id,
  headerTypography,
  headerRightAdornment,
  headerLeftAdornment,
  margin,
  padding,
  bgColour = 'transparent',
  borderRadius = 0,
  headerAlignment = 'start',
  className,
  headerClassName = ''
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const defaultIndents = {
    margin: {
      top: isMobile ? 16 : 32,
      bottom: isMobile ? 16 : 32
    }
  } as const

  const { marginStyles, paddingStyles } = transformIndents({
    margin,
    padding,
    defaultIndents
  })

  const cn = classNames(
    STYLES.wrapper,
    STYLES[bgColour],
    STYLES[`borderRadius${borderRadius}`],
    className
  )

  return (
    <div
      className={cn}
      style={{ ...marginStyles, ...paddingStyles }}
      data-testid="section-wrapper"
      id={id}
    >
      {(headerTypography || headerLeftAdornment || headerRightAdornment) && (
        <div
          className={`${STYLES.header} ${
            !headerTypography ? STYLES.headerWithoutTypography : ''
          }
        ${STYLES[`${headerAlignment}Align`]}`}
        >
          {(headerTypography || headerLeftAdornment) && (
            <div
              className={`${STYLES.title} ${
                headerTypography?.margin === false ? STYLES.noMargin : ''
              } ${headerClassName}`}
            >
              {headerTypography && (
                <Text
                  element="h2"
                  variant="display24"
                  {...headerTypography}
                  margin={false}
                />
              )}

              {headerLeftAdornment && (
                <div className={STYLES.headerLeftAdornment}>
                  <HeaderRightAdornment {...headerLeftAdornment} />
                </div>
              )}
            </div>
          )}

          {headerRightAdornment && (
            <HeaderRightAdornment {...headerRightAdornment} />
          )}
        </div>
      )}
      {children && <div className={STYLES.content}>{children}</div>}
    </div>
  )
}

export type { Props, Indent }

export { SectionWrapper }
