import React from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import Image from '@/components/shared/elements/Image/Image'
import Text from '@/components/shared/elements/Text/Text'

const cardCopyContext = 'puppy.social_reviews.cards'

const TestimonialsCarousel = (): React.ReactElement => {
  const { windowWidth } = useWindowSize()
  const namespace = 'paid_traffic'
  const { t } = useTranslation(namespace)

  const testimonialData = [
    { name: 'Dexter', age: 5, card: 1 },
    { name: 'Maya', age: 3, card: 2 },
    { name: 'Winnie', age: 5, card: 3 },
    { name: 'Bobby', age: 3, card: 4 },
    { name: 'Goldie', age: 2, card: 5 },
    { name: 'Donut', age: 2, card: 6 },
    { name: 'Betty', age: 3, card: 7 }
  ]

  const imageWidth = () => {
    if (windowWidth >= BREAKPOINTS.lg) return 336
    if (windowWidth >= BREAKPOINTS.md) return 300
    else return 283
  }

  const imageHeight = () => {
    if (windowWidth >= BREAKPOINTS.lg) return 258
    if (windowWidth >= BREAKPOINTS.md) return 222
    else return 212
  }

  return (
    <div className="testimonials-carousel-wrapper">
      <SwiperSlider
        className="testimonials-carousel-wrapper__slider"
        arrows={windowWidth > BREAKPOINTS.lg}
        arrowsInside={windowWidth > BREAKPOINTS.lg}
        bullets={false}
        spaceBetween={0}
        centeredSlides={windowWidth > BREAKPOINTS.md}
      >
        {testimonialData.map(({ name, age, card }): React.ReactElement => {
          return (
            <div key={name} className="testimonials-carousel-wrapper__card">
              <div className="testimonials-carousel-wrapper__image-frame">
                <Image
                  alt={t(`${cardCopyContext}.card_${card}.img_alt`)}
                  slug={`pt_puppy_testimonials_${card}`}
                  image={{
                    width: imageWidth(),
                    height: imageHeight(),
                    resizeMode: 'resize_to_fit',
                    quality: 'auto:best',
                    retina: true
                  }}
                />
              </div>
              <div className="testimonials-carousel-wrapper__text">
                <Text
                  namespace={namespace}
                  variant="textRegular18"
                  text={name}
                  colour="grey900"
                  bold
                  shouldScale={false}
                  translate={false}
                />
                <div className="testimonials-carousel-wrapper__breed-age">
                  <Text
                    element="span"
                    namespace={namespace}
                    variant="textRegular14"
                    colour="grey600"
                    text={`${cardCopyContext}.card_${card}.breed`}
                    shouldScale={false}
                  />
                  <div className="testimonials-carousel-wrapper__text-divider" />
                  <Text
                    element="span"
                    namespace={namespace}
                    variant="textRegular14"
                    colour="grey600"
                    text={`${cardCopyContext}.age`}
                    shouldScale={false}
                    variables={{ age }}
                  />
                </div>
                <Text
                  namespace={namespace}
                  variant="textRegular16"
                  colour="grey900"
                  text={`${cardCopyContext}.card_${card}.text`}
                  shouldScale={false}
                />
              </div>
            </div>
          )
        })}
      </SwiperSlider>
    </div>
  )
}

export default TestimonialsCarousel
