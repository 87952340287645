import { gql } from '@apollo/client'

const MERCH_RAF_CARD_QUERY = gql`
  query MerchRafCardQuery {
    user {
      id
      shippingCountryCode
      preferredLanguage
      subscription {
        id
        referralLink {
          referrerDiscount {
            applicableDiscountParts {
              discountBasis
              value
            }
          }
        }
      }
    }
  }
`

export { MERCH_RAF_CARD_QUERY }
