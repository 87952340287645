// @noflow
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { Locale } from '@/utils/countryCodeHelper'
import { Currency } from '@/utils/currency'

import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
import useDiscountCopy from '@/components/pages/ReferAFriendPage/hooks/useDiscountCopy'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from './Discounts.module.sass'

import Referal from './Referal'

type Props = {
  locale: Locale
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  currency: Currency
  copyLinkMessageNative: string
}

const Discounts = ({
  referralLink,
  locale,
  currency,
  copyLinkMessageNative
}: Props): JSX.Element => {
  const { t } = useTranslation('account')
  const cn = classNames(STYLES.title, STYLES.padder)
  const { isMultiDiscount, referralDiscount, referrerDiscount } =
    useDiscountCopy(referralLink, locale, currency)

  return (
    <Card className={STYLES.card} padding={0} dataTestId="share-section">
      <div className={cn}>
        <Text
          namespace="account"
          variant="display20"
          text="thank_you_page_v3.raf.title"
          variables={{
            referrerDiscount: referrerDiscount.copy
          }}
          margin={false}
          element="h4"
        />
        <Text
          namespace="account"
          text={
            isMultiDiscount
              ? 'thank_you_page_v3.raf.description_tiered'
              : 'thank_you_page_v3.raf.description'
          }
          variables={{
            referrerDiscount: referrerDiscount.copy,
            referralDiscountTier1: referralDiscount[0].copy,
            referralDiscountTier2: referralDiscount[1].copy
          }}
          margin={false}
          colour="brandBlue400"
        />
      </div>

      <div className={STYLES.padder}>
        <SectionWrapper margin={{ top: 24 }}>
          <Referal copyLinkMessageNative={copyLinkMessageNative} />
        </SectionWrapper>
      </div>
      <div className={STYLES.imgWrap}>
        <Image
          className={STYLES.image}
          alt={t('thank_you_page_v3.raf.img_alt')}
          slug={'thank-you-page-raf'}
          image={{
            height: 293,
            width: 430
          }}
        />
      </div>
    </Card>
  )
}

export default Discounts
