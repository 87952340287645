// @noflow
import isNull from 'lodash/isNull'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import Interactive from '@/components/elements/atoms/Interactive/Interactive'
import Text from '@/components/elements/atoms/Text'

import STYLES from './SelectableDogAvatar.module.sass'

import { SelectableDogAvatarFragment as Dog } from './fragments/__generated__/SelectableDogAvatarFragment'

import { ACCOUNT_ROUTES } from '../../../../../routes'
import { DogAvatarImage } from './DogAvatarImage'

type Props = {
  dog: Dog
  link?: string | null
  selectedDogId?: string
  onSelectAction?: (dogId: string) => void
}

const SelectableDogAvatar = ({
  dog,
  link,
  selectedDogId,
  onSelectAction
}: Props): JSX.Element => {
  const { id, name } = dog
  const navigate = useNavigate()

  const isDogSelected = selectedDogId === id

  const onDogSelect = useCallback((): void => {
    /**
     * onSelectAction is an optional handler that is called when a new dog is selected.
     * If the dog is already selected, onSelectAction is not called.
     */
    if (!isDogSelected) {
      if (!isNull(link)) navigate(link || `${ACCOUNT_ROUTES.profile}/${id}`)
      if (onSelectAction) onSelectAction(id)
    }
  }, [id, isDogSelected, link, navigate, onSelectAction])

  const navLinkClassNames = useMemo(
    () => `${selectedDogId === id ? STYLES.selected : ''} ${STYLES.wrapper}`,
    [id, selectedDogId]
  )

  return (
    <Interactive
      className={navLinkClassNames}
      onClick={onDogSelect}
      focusHighlight={false}
    >
      <DogAvatarImage dog={dog} />
      <div className={STYLES.dogNameWrapper}>
        <Text
          text={upperFirst(name)}
          translate={false}
          margin={false}
          colour={isDogSelected ? 'brandBlue500' : 'brandBlue400'}
        />
      </div>
    </Interactive>
  )
}

export { SelectableDogAvatar }
