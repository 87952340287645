import { gql } from '@apollo/client'

import {
  HOW_TO_FEED_MODAL_TRIGGER_DOG_FRAGMENT,
  HOW_TO_FEED_MODAL_TRIGGER_PLAN_FRAGMENT
} from '../../HowToFeedModalTrigger/fragments/howToFeedModalFragment'

const SUBSCRIPTION_OVERVIEW_FRAGMENT = gql`
  ${HOW_TO_FEED_MODAL_TRIGGER_PLAN_FRAGMENT}
  ${HOW_TO_FEED_MODAL_TRIGGER_DOG_FRAGMENT}
  fragment SubscriptionOverviewFragment on User {
    dogs {
      id
      name
      planPortionInGrams
      gender
      ageInMonths
      dogProfile {
        id
        avatarUrl
      }
      ...HowToFeedModalTriggerDogFragment
    }
    subscription {
      id
      status
      plan {
        id
        ...HowToFeedModalTriggerPlanFragment
      }
      previewUncreatedFutureBox {
        discountValue
      }
      nextEditableBox {
        id
        price
        discountValue
      }
    }
  }
`

export { SUBSCRIPTION_OVERVIEW_FRAGMENT }
