// @noflow
import React, { useEffect } from 'react'
import { useSwiperSlide } from 'swiper/react'

import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'

import { Expand } from '../../../../atoms/Animated/Animated'

type Props = {
  isLoading: boolean
  onLoad: () => void
  width?: number | string
  height?: number | string
}

const SkeletonSlide = ({
  width,
  height,
  isLoading,
  onLoad
}: Props): JSX.Element => {
  const { isActive } = useSwiperSlide()
  const shouldShow = isLoading || isActive

  useEffect(() => {
    if (!isActive || isLoading) return

    onLoad()
  }, [isActive, isLoading, onLoad])

  return (
    <Expand origin="center" show={shouldShow}>
      <CardSkeleton width={width} height={height} />
    </Expand>
  )
}

export { SkeletonSlide }
