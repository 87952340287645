import React, { useCallback } from 'react'

import { Currency, Locale, formatCurrencyWithDecimal } from '@/utils/currency'

import Text from '@/components/elements/atoms/Text'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'

import STYLES from './plansstickynavigation.module.sass'

type Props = {
  namespace: string
  disabled: boolean
  onNextClick: () => void
  showNoRecipesSelectedMessage: boolean
  discountedPrice: number
  ongoingPrice: number
  showStrikethroughPrice: boolean
  locale: Locale
  currency: Currency
  loading?: boolean
  showPrice?: boolean
}

const PlansStickyNavigation = ({
  disabled,
  namespace,
  onNextClick,
  showNoRecipesSelectedMessage,
  discountedPrice,
  ongoingPrice,
  showStrikethroughPrice,
  locale,
  currency,
  loading = false,
  showPrice = true
}: Props): JSX.Element => {
  const copyContext = 'plans_steps_navigation'

  const onClick = useCallback(
    (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLButtonElement>
    ) => {
      e.preventDefault()
      onNextClick()
    },
    [onNextClick]
  )

  return (
    <StickyNavigation
      variant="oneButton"
      disabled={disabled}
      buttonOne={{
        dataTestId: 'next-button',
        variant: 'primary',
        text: `${copyContext}.next`,
        iconColour: 'brandWhite',
        namespace,
        fullWidth: true,
        onClick: (e) => onClick(e)
      }}
      fullHeight
    >
      {showNoRecipesSelectedMessage && (
        <div className={STYLES.noRecipesSelected}>
          <Text
            namespace={namespace}
            text={`${copyContext}.no_recipes`}
            variant="textRegular18"
            margin={false}
            align="center"
          />
        </div>
      )}
      {showPrice && (
        <div className={STYLES.priceSection}>
          <div className={STYLES.starterBoxPrice}>
            <Text
              namespace={namespace}
              text={`${copyContext}.starter_box_recipes`}
              variant="display16"
              margin={false}
            />
            {!loading && (
              <div>
                <Text
                  namespace={namespace}
                  text={formatCurrencyWithDecimal(discountedPrice, {
                    locale,
                    currency
                  })}
                  variant="display18"
                  margin={false}
                  translate={false}
                  colour={
                    showStrikethroughPrice ? 'brandPink500' : 'brandBlue500'
                  }
                  element="span"
                />
                <Text
                  namespace={namespace}
                  text={`${copyContext}.per_day`}
                  variant="textRegular14"
                  margin={false}
                  element="span"
                />
              </div>
            )}
          </div>
          {showStrikethroughPrice && (
            <div className={STYLES.ongoingPrice}>
              <Text
                namespace={namespace}
                text={`${copyContext}.ongoing_recipes`}
                margin={false}
              />
              <div>
                <Text
                  text={formatCurrencyWithDecimal(ongoingPrice, {
                    locale,
                    currency
                  })}
                  translate={false}
                  margin={false}
                  element="span"
                />
                <Text
                  namespace={namespace}
                  text={`${copyContext}.per_day`}
                  variant="textRegular14"
                  margin={false}
                  element="span"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </StickyNavigation>
  )
}

export default PlansStickyNavigation
