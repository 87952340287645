import { useLanguage } from '@/context/injectedValues/injectedValues'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { format } from 'date-fns'
import times from 'lodash/times'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  capitaliseFirstLetter,
  toLocalisedSentence
} from '@/utils/StringHelper'
import { localeToDateLocale } from '@/utils/countryCodeHelper'

import segmentTrack from '@/components/analytics/Analytics'
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import ProductUpsellableCard from '@/components/elements/molecules/ProductUpsellableCard'
import ProductUpsellableCardSkeleton from '@/components/elements/molecules/ProductUpsellableCard/ProductUpsellableCardSkeleton'
import { getProductPricesFromCollection } from '@/components/pages/ExtrasPage/screens/ProductCollection/components/molecules/ProductPrice/ProductPrice'
import { errorCopy } from '@/components/pages/ExtrasPage/screens/ProductCollection/helpers/copies'

import STYLES from './ProductsUpsellModal.module.sass'

// GraphQL
import { GET_UPSELLABLE_PRODUCTS } from './queries/getUpSellableProducts'
import { ORDER_PRODUCTS_UPDATE } from './queries/orderProductsUpdate'
import { RECURRING_ORDER_PRODUCTS_UPDATE } from './queries/recurringOrderProductsUpdate'

import {
  GetUpsellableProducts,
  GetUpsellableProductsVariables,
  GetUpsellableProducts_user_subscription_nextNBoxes_physicalOrderProducts as PhysicalOrderProducts
} from './queries/__generated__/GetUpsellableProducts'
import {
  OrderProductsUpdate,
  OrderProductsUpdateVariables
} from './queries/__generated__/OrderProductsUpdate'
import {
  RecurringOrderProductsUpdate,
  RecurringOrderProductsUpdateVariables
} from './queries/__generated__/RecurringOrderProductsUpdate'
import { Code, Frequency, VariantDelivery } from '@/types'

import Icon from '../../atoms/Icon/Icon'
import Text, { TextProps } from '../../atoms/Text'

type Product = {
  productVariantId: string
  quantity: number
}

enum ProductUpsellAnalyticTypes {
  deliveryDateChange = 'AP Upsell on Delivery Date Change',
  recipeChange = 'AP Upsell on Recipe Change'
}

type Props = {
  isModalOpen: boolean
  headerText: TextProps | TextProps[]
  deliveryType: VariantDelivery
  analyticsType?: ProductUpsellAnalyticTypes | null
  onClose?: () => void
  onSuccess?: () => void
}

const ProductsUpsellModal = ({
  isModalOpen,
  headerText,
  deliveryType,
  analyticsType,
  onClose,
  onSuccess
}: Props): JSX.Element | null => {
  const namespace = 'organisms'
  const copyContext = 'products_upsell_modal'
  const { userLanguage } = useLanguage()

  const [products, setProducts] = React.useState<Product[]>([])
  const [isProductsUpsellModalOpen, setIsProductsUpsellModalOpen] =
    React.useState(isModalOpen)
  const { t } = useTranslation(namespace)

  const defaultHeaderTextProps: Pick<
    TextProps,
    'variant' | 'colour' | 'namespace' | 'margin'
  > = {
    variant: 'display24',
    colour: 'brandBlue500',
    namespace,
    margin: false
  }

  const closeModal = useCallback(() => {
    setIsProductsUpsellModalOpen(false)
    onClose && onClose()
  }, [onClose])

  const [getUpsellableProducts, { data, loading, error }] = useLazyQuery<
    GetUpsellableProducts,
    GetUpsellableProductsVariables
  >(GET_UPSELLABLE_PRODUCTS, {
    variables: {
      deliveryType,
      num: 1
    }
  })

  const { user } = data || {}
  const { subscription, dogs, shippingCountryCode } = user || {}
  const { nextNBoxes } = subscription || {}

  const dogNames =
    dogs &&
    dogs.length > 0 &&
    toLocalisedSentence({
      arr: dogs.map((dog) => capitaliseFirstLetter(dog.name)),
      lng: userLanguage || 'en'
    })
  const dateLocale = localeToDateLocale(
    shippingCountryCode || Code.GB,
    userLanguage || 'en'
  )
  const deliveryDate =
    nextNBoxes?.[0]?.isoDeliveryDate || new Date().toISOString()

  const [
    orderProductsUpdate,
    { loading: orderProductsUpdateLoading, error: orderProductsUpdateError }
  ] = useMutation<OrderProductsUpdate, OrderProductsUpdateVariables>(
    ORDER_PRODUCTS_UPDATE,
    {
      refetchQueries: ['NewExtrasSectionQuery'],
      onError: (error) => {
        Sentry.captureException(
          'Encountered error in ORDER_PRODUCTS_UPDATE mutation',
          { extra: { error } }
        )
      },
      onCompleted: () => {
        onSuccess && onSuccess()

        toast.success(
          <NotificationContent
            copy={{
              namespace: namespace,
              text:
                deliveryType === VariantDelivery.one_off
                  ? `${copyContext}.success_notification_one_off`
                  : `${copyContext}.success_notification_recurring`,
              variables: {
                count: products.reduce(
                  (acc, product) => acc + product.quantity,
                  0
                )
              }
            }}
          />,
          { toastId: 'alert-success-sample-added-to-plan' }
        )

        products.forEach((product) => {
          const productCollectionSlug = data?.extrasUpsellRecommendations.find(
            ({ id }) => id === product.productVariantId
          )?.productCollection.slug
          const sizeSlug = data?.extrasUpsellRecommendations.find(
            ({ id }) => id === product.productVariantId
          )?.name

          return segmentTrack('Shop: Additional Product Added', {
            type: analyticsType,
            productCollectionSlug: productCollectionSlug,
            quantity: product.quantity,
            sizeSlug: sizeSlug,
            purchaseType: deliveryType
          })
        })

        setProducts([])
        closeModal()
      }
    }
  )
  const [
    recurringOrderProductsUpdate,
    {
      loading: recurringOrderProductsUpdateLoading,
      error: recurringOrderProductsUpdateError
    }
  ] = useMutation<
    RecurringOrderProductsUpdate,
    RecurringOrderProductsUpdateVariables
  >(RECURRING_ORDER_PRODUCTS_UPDATE, {
    refetchQueries: ['NewExtrasSectionQuery'],
    onError: (error) => {
      Sentry.captureException(
        'Encountered error in RECURRING_ORDER_PRODUCTS_UPDATE mutation',
        { extra: { error } }
      )
    },
    onCompleted: () => {
      onSuccess && onSuccess()

      toast.success(
        <NotificationContent
          copy={{
            namespace: namespace,
            text:
              deliveryType === VariantDelivery.one_off
                ? `${copyContext}.success_notification_one_off`
                : `${copyContext}.success_notification_recurring`,
            variables: {
              count: products.reduce(
                (acc, product) => acc + product.quantity,
                0
              )
            }
          }}
        />,
        { toastId: 'alert-success-sample-added-to-plan' }
      )

      products.forEach((product) => {
        const productCollectionSlug = data?.extrasUpsellRecommendations.find(
          ({ id }) => id === product.productVariantId
        )?.productCollection.slug
        const sizeSlug = data?.extrasUpsellRecommendations.find(
          ({ id }) => id === product.productVariantId
        )?.name

        return segmentTrack('Shop: Additional Product Added', {
          type: analyticsType,
          productCollectionSlug: productCollectionSlug,
          quantity: product.quantity,
          sizeSlug: sizeSlug,
          purchaseType: deliveryType
        })
      })

      setProducts([])
      closeModal()
    }
  })

  const handleOnClick = useCallback(
    (productVariantId?: string, quantity?: number) => {
      if (!productVariantId || quantity === undefined) return

      setProducts((prevProducts) => {
        const existingProduct = prevProducts.find(
          (p) => p.productVariantId === productVariantId
        )

        // If the quantity is 0, remove the product from the array
        if (quantity === 0) {
          return prevProducts.filter(
            (p) => p.productVariantId !== productVariantId
          )
        }

        // If the product already exists in the array, update it's quantity
        if (existingProduct) {
          return prevProducts.map((product) => {
            if (product.productVariantId === existingProduct.productVariantId) {
              return {
                ...product,
                quantity
              }
            }

            return product
          })
        }

        // Otherwise, add the product to the array
        return [
          ...prevProducts,
          {
            productVariantId,
            quantity
          }
        ]
      })
    },
    []
  )

  const existingAndNewProductsByType = useCallback(
    (
      deliveryType: Frequency,
      physicalOrderProducts: PhysicalOrderProducts[]
    ) => {
      const orderProducts = physicalOrderProducts.filter(
        ({ frequency }) => frequency === deliveryType
      )
      const existingOrderProducts = orderProducts.map(
        ({ quantity, productVariant }) => ({
          productVariantId: productVariant.id,
          quantity: quantity
        })
      )

      return existingOrderProducts.concat(products)
    },
    [products]
  )

  const handleProductsUpdate = useCallback(() => {
    if (data) {
      if (!nextNBoxes?.[0]) return []

      const { physicalOrderProducts, id: boxId } = nextNBoxes[0]

      switch (deliveryType) {
        case 'recurring':
          recurringOrderProductsUpdate({
            variables: {
              recurringOrderProducts: existingAndNewProductsByType(
                Frequency.recurring,
                physicalOrderProducts
              ),
              userId: data.user.id,
              num: 3
            }
          })
          break
        case 'one_off': {
          orderProductsUpdate({
            variables: {
              orderProducts: existingAndNewProductsByType(
                Frequency.one_off,
                physicalOrderProducts
              ),
              userId: user?.id || '',
              boxId
            }
          })
        }
      }
    } else {
      Sentry.captureException('Data is missing from GET_UPSELLABLE_PRODUCTS')
    }
  }, [
    data,
    nextNBoxes,
    deliveryType,
    recurringOrderProductsUpdate,
    existingAndNewProductsByType,
    orderProductsUpdate,
    user?.id
  ])

  const handleErrorMessage = (
    orderProductsUpdateError: ApolloError | undefined,
    recurringOrderProductsUpdateError: ApolloError | undefined,
    error: ApolloError | undefined
  ) => {
    if (orderProductsUpdateError) {
      return orderProductsUpdateError.message
    }
    if (recurringOrderProductsUpdateError) {
      return recurringOrderProductsUpdateError.message
    }
    if (error) {
      return error?.message
    }
    return t(`${copyContext}.generic_error`)
  }

  useEffect(() => {
    setIsProductsUpsellModalOpen(isModalOpen)
  }, [isModalOpen])

  useEffect(() => {
    if (analyticsType) {
      segmentTrack('Seen AP Upsell Modal', {
        type: analyticsType
      })
    }
  }, [analyticsType])

  useEffect(() => {
    if (isProductsUpsellModalOpen && !data && !loading) {
      getUpsellableProducts()
    }
  }, [isProductsUpsellModalOpen, getUpsellableProducts, data, loading])

  return (
    <Modal
      isModalOpen={isProductsUpsellModalOpen}
      setOpenModal={closeModal}
      width={800}
      showCloseButton
      padding={false}
      bottomSticky={false}
      fullHeight
    >
      <div className={STYLES.container}>
        <div className={STYLES.header}>
          <div className={STYLES.headerTitle}>
            <Icon
              asset="checkmark"
              size={26}
              accentColour="successGreen300"
              width={26}
            />
            <div>
              {Array.isArray(headerText) ? (
                headerText.map((text, index) => (
                  <Text
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    {...{ ...defaultHeaderTextProps, ...text }}
                  />
                ))
              ) : (
                <Text {...{ ...defaultHeaderTextProps, ...headerText }} />
              )}
            </div>
          </div>
          {(orderProductsUpdateError ||
            recurringOrderProductsUpdateError ||
            error) && (
            <div className={STYLES.errorWrapper}>
              <AlertCard
                variant="error"
                message={{
                  align: 'left',
                  shouldScale: false,
                  variant: 'textRegular14',
                  ...errorCopy({
                    mutationError: handleErrorMessage(
                      orderProductsUpdateError,
                      recurringOrderProductsUpdateError,
                      error
                    ),
                    recuringType: deliveryType,
                    quantity: products.length
                  })
                }}
              />
            </div>
          )}
        </div>
        <div className={STYLES.productsWrapper}>
          <Text
            text="products_upsell_modal.sub_title"
            variant="display24"
            colour="brandBlue500"
            namespace="organisms"
            margin={false}
          />
          <Text
            namespace="organisms"
            text="products_upsell_modal.sub_title_information"
            variant="textRegular18"
            align={'left'}
            margin={false}
            colour="brandBlue400"
            variables={{
              deliveryDate: format(new Date(deliveryDate), 'EEEE do MMMM', {
                locale: dateLocale
              }),
              dogNames
            }}
          />
          <div className={STYLES.productsGrid}>
            {loading || !data
              ? times(15, (n) => {
                  return (
                    <ProductUpsellableCardSkeleton
                      orientation="vertical"
                      key={n}
                    />
                  )
                })
              : data.extrasUpsellRecommendations
                  .filter(({ recurringDeliveryType, oneOffDeliveryType }) => {
                    switch (deliveryType) {
                      case 'recurring':
                        return recurringDeliveryType !== null
                      case 'one_off':
                        return oneOffDeliveryType !== null
                      default:
                        return false
                    }
                  })
                  .map(({ id, name, productCollection }) => (
                    <ProductUpsellableCard
                      key={id}
                      variant="quantitySelector"
                      disableButton={
                        loading ||
                        orderProductsUpdateLoading ||
                        recurringOrderProductsUpdateLoading
                      }
                      name={name}
                      onClick={handleOnClick}
                      preferredLanguage={data.user.preferredLanguage}
                      productName={productCollection.name}
                      productVariantId={id}
                      shippingCountryCode={data.user.shippingCountryCode}
                      src={productCollection.thumbnail.src}
                      orientation="vertical"
                      shadow={false}
                      deliveryType={deliveryType}
                      ctaVariant="secondary"
                      {...getProductPricesFromCollection(
                        id,
                        deliveryType,
                        productCollection
                      )}
                    />
                  ))}
          </div>
        </div>
        {products.length > 0 && (
          <div className={STYLES.stickyContainer}>
            <div className={STYLES.footer}>
              <Button
                fullWidth
                typography={{
                  namespace,
                  text:
                    deliveryType === 'one_off'
                      ? `${copyContext}.one_off_cta`
                      : `${copyContext}.recurring_cta`
                }}
                onClick={handleProductsUpdate}
                disabled={
                  orderProductsUpdateLoading ||
                  recurringOrderProductsUpdateLoading
                }
                disableAnalytics
              />
              <Button
                typography={{
                  namespace,
                  text: `${copyContext}.skip`
                }}
                variant="link"
                onClick={closeModal}
                disabled={
                  orderProductsUpdateLoading ||
                  recurringOrderProductsUpdateLoading
                }
                disableAnalytics
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export { Props, ProductUpsellAnalyticTypes }
export default ProductsUpsellModal
