// @noflow
import React, { useCallback } from 'react'

import Text, { TextProps } from '@/components/elements/atoms/Text'

import STYLES from './OptionSelector.module.sass'

import { OptionSelectorSkeleton } from './OptionSelectorSkeleton'

type Option = {
  id: string
  typography: TextProps
}

type Props = {
  options: Array<Option>
  selectedOption: Option | undefined
  onOptionChange: (option: Option) => void
  disabled?: boolean
  loading?: boolean
}

/**
 * OptionSelector
 *
 * A component for selecting between multiple options with previous/next navigation
 * It also supports loading state with a skeleton loader and disabled state for the buttons.
 *
 * @example
 * ```tsx
 * <OptionSelector
 *   options={[
 *     { id: '1', typography: { text: 'Option 1' } },
 *     { id: '2', typography: { text: 'Option 2' } }
 *   ]}
 *   selectedOption={{ id: '1', typography: { text: 'Option 1' } }}
 *   onOptionChange={(option) => console.log('Selected:', option)}
 * />
 * ```
 *
 * Using state
 * @example
 * ```tsx
 * const [selectedOption, setSelectedOption] = useState(initialOption)
 *
 * <OptionSelector
 *   options={[
 *     { id: '1', typography: { text: 'Option 1' } },
 *     { id: '2', typography: { text: 'Option 2' } }
 *   ]}
 *   selectedOption={selectedOption}
 *   onOptionChange={setSelectedOption}
 * />
 * ```
 *
 * @param {Array<Option>} options - Array of options to display in the selector
 * @param {Option} selectedOption - Currently selected option
 * @param {(option: Option) => void} onOptionChange - Callback function when an option is selected
 * @param {boolean} [disabled] - Whether the selector is disabled
 * @param {boolean} [loading] - Whether the selector is in loading state
 */
const OptionSelector = ({
  options,
  selectedOption,
  onOptionChange,
  disabled = false,
  loading = false
}: Props): React.JSX.Element | null => {
  const currentIndex = options.findIndex(
    (option) => option.id === selectedOption?.id
  )

  const isPreviousDisabled = disabled || currentIndex === 0
  const isNextDisabled = disabled || currentIndex === options.length - 1

  const handlePrevious = useCallback(() => {
    if (!isPreviousDisabled) {
      onOptionChange(options[currentIndex - 1])
    }
  }, [isPreviousDisabled, currentIndex, onOptionChange, options])

  const handleNext = useCallback(() => {
    if (!isNextDisabled) {
      onOptionChange(options[currentIndex + 1])
    }
  }, [currentIndex, onOptionChange, options, isNextDisabled])

  if (loading) {
    return <OptionSelectorSkeleton />
  }

  if (!loading && !selectedOption) {
    return null
  }

  return (
    <div className={STYLES.container}>
      <button
        className={`${STYLES.button} ${STYLES.previous}`}
        onClick={handlePrevious}
        disabled={isPreviousDisabled}
        type="button"
      >
        <Text
          text="-"
          variant="display28"
          translate={false}
          margin={false}
          element="span"
          tight
        />
      </button>
      <div className={STYLES.selectedOption}>
        <Text
          text=""
          variant="display28"
          margin={false}
          {...selectedOption?.typography}
        />
      </div>
      <button
        className={`${STYLES.button} ${STYLES.next}`}
        onClick={handleNext}
        disabled={isNextDisabled}
        type="button"
      >
        <Text
          text="+"
          variant="display28"
          translate={false}
          margin={false}
          element="span"
          tight
        />
      </button>
    </div>
  )
}

export type { Props }

export { OptionSelector }
