// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import React from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { OrderDate } from './components/OrderDate'
import {
  SwiperSlider,
  SwiperSliderProps
} from '@/components/elements/molecules/SwiperSlider/v2'
import { Order } from '@/components/pages/Dashboard/components/upcomingBoxes/utilities'

import { Descriptor } from '@/types'

const SLIDER_ID = 'order-carousel'

type Props = {
  orders: Order[]
  selected?: string
  slider?: SwiperSliderProps['slider']
  initialSlide?: number
  onSlideClick: (id: string) => void
} & Pick<SwiperSliderProps, 'onLazyLoadPrev' | 'onLazyLoadNext'>

/**
 * OrderCarousel
 */
const OrderCarousel = ({
  orders,
  selected,
  initialSlide,
  onSlideClick,
  onLazyLoadPrev,
  onLazyLoadNext,
  slider
}: Props): JSX.Element | null => {
  const { xmas } = useOccasion()
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md
  const isTablet = windowWidth >= BREAKPOINTS.md && windowWidth < BREAKPOINTS.lg

  if (orders.length === 0) return null

  return (
    <SwiperSlider
      slider={slider}
      onLazyLoadPrev={onLazyLoadPrev}
      onLazyLoadNext={onLazyLoadNext}
      watchOverflow={false}
      skeleton={{
        width: isMobile ? '13.2rem' : '100%',
        height: isMobile ? '13.6rem' : '16.2rem'
      }}
      paddingOverride={isMobile ? 16 : 0}
      id={SLIDER_ID}
      initialSlide={initialSlide}
      variant={xmas ? 'brandBlue100' : 'brandYellow200'}
      spaceBetween={8}
      slidesPerView={isMobile ? 'auto' : isTablet ? 3 : 4}
    >
      {orders.map(
        ({
          id,
          deliveryDate,
          discounts,
          descriptor,
          type,
          isTrialBox,
          isBoxDelivered,
          deliveryDateAdjustedBySystem
        }) => (
          <OrderDate
            key={`${id}-order-carousel-item-key`}
            id={id}
            deliveryDate={deliveryDate}
            selected={selected === id}
            isDiscounted={discounts && discounts.length > 0}
            isTrialBox={isTrialBox}
            isNonCoreOrder={type === 'nonCoreOrder'}
            isOneOffBox={type === 'oneOffBox'}
            isNextBox={descriptor === Descriptor.next}
            isPastBox={descriptor === Descriptor.past}
            isBoxDelivered={isBoxDelivered}
            deliveryDateAdjustedBySystem={deliveryDateAdjustedBySystem}
            onClick={onSlideClick}
          />
        )
      )}
    </SwiperSlider>
  )
}

export type { Props }

export { OrderCarousel }
