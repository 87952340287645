// @noflow
import { useQuery } from '@apollo/client'
import React from 'react'

import { ErrorState } from '@/components/elements/organisms/ErrorState'

import { EDIT_DELIVERY_FREQUENCY_QUERY } from './queries/editDeliveryFrequencyQuery'

import type { EditDeliveryFrequencyQuery } from './queries/__generated__/EditDeliveryFrequencyQuery'
import { AvailablePlansScopeType } from '@/types'

import { EditDeliveryFrequencyContent } from './EditDeliveryFrequencyContent'

const EditDeliveryFrequency = (): React.JSX.Element => {
  const { loading, data, error } = useQuery<EditDeliveryFrequencyQuery>(
    EDIT_DELIVERY_FREQUENCY_QUERY,
    {
      variables: {
        scopeType: AvailablePlansScopeType.duration
      }
    }
  )

  if (!loading && error) {
    return (
      <ErrorState
        error={{
          name: 'Error retrieving page data',
          message: error.message,
          apollo: error
        }}
      />
    )
  }

  if (!loading && !data) {
    return (
      <ErrorState
        error={{
          name: 'Could not find page data',
          message: 'Could not find page data'
        }}
      />
    )
  }

  return <EditDeliveryFrequencyContent {...data} loading={loading} />
}

export { EditDeliveryFrequency }
