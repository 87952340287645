import { gql } from '@apollo/client'

const EXTRAS_LIST_QUERY = gql`
  query ExtrasListQuery {
    user {
      id
      shippingCountryCode
      preferredLanguage
    }
    productGroups {
      id
      name
      slug
      description
      thumbnail {
        src
      }
      productCollections {
        id
        slug
        unavailableObject {
          reason
          reasonCopy
        }
        productSticker {
          image {
            src
          }
        }
        name
        discount {
          discountBasis
          value
        }
        productLabel {
          name
        }
        primaryImage {
          src
        }
        productVariants {
          id
          name
          discountedPrice
          grossPrice
          productVariantDeliveryTypes {
            deliveryType
            adjustedGrossPrice
            netPrice
          }
        }
      }
    }
  }
`
export { EXTRAS_LIST_QUERY }
