// @noflow
import { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import i18next from 'i18next'
import React from 'react'

import { lngStringToLngType } from '@/utils/StringHelper'

// Utils
import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

// Components
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Image from '@/components/elements/atoms/Image/Image'
import SkeletonImage from '@/components/elements/atoms/SkeletonImage/SkeletonImage'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import Text from '@/components/elements/atoms/Text/Text'
import ImageCard from '@/components/elements/organisms/ImageCard/ImageCard'
import { FeedingTipsQuery } from '@/components/pages/Dashboard/components/FeedingTipsCard/__generated__/FeedingTipsQuery'

import STYLES from './FeedingTipsCard.module.sass'

// Types
import type { FeedingTipsQuery_user_dogs as Dog } from './__generated__/FeedingTipsQuery'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { Code, Language as LanguageEnum } from '@/types'

// Apollo
import { FEEDING_TIPS_QUERY } from './queries'

const FeedingTipsCardLoading = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  if (isMobile)
    return (
      <>
        <SkeletonImage height={'23rem'} />
        <SkeletonParagraph margin=".5rem 0" shortLine={false} />
      </>
    )
  return (
    <div className={STYLES.desktopCard}>
      <CardSkeleton height="24rem" />
      <CardSkeleton height="24rem" />
    </div>
  )
}

const FeedingTipsCard = (): JSX.Element | null => {
  const namespace = 'account'
  const copyContext = 'home.hero_section.feeding_tips'
  const { windowWidth } = useWindowSize()
  const { loading, data, error } =
    useQuery<FeedingTipsQuery>(FEEDING_TIPS_QUERY)

  if (loading) return <FeedingTipsCardLoading />

  if (error) {
    Sentry.captureException(`Error in FEEDING_TIPS_QUERY`, {
      extra: {
        error
      }
    })
    return null // TODO: handle error state better
  }

  if (!data) return null

  const {
    user: { shippingCountryCode, dogs }
  } = data

  const fussyDogsNumber = dogs
    ? dogs?.filter(
        ({ eaterType }: Dog): boolean =>
          eaterType === 'fussy_eater' || eaterType === 'picky_eater'
      ).length
    : 0

  const isMobile = windowWidth < BREAKPOINTS.md

  const nonSupportedRegions = [Code.BE]

  const localisedTransitioningPDFPath = (language: Language) => {
    switch (language) {
      case LanguageEnum.nl:
      case LanguageEnum.nl_BE:
        return 'Transitioning_Page_Dutch'
      case LanguageEnum.pl:
      case LanguageEnum.pl_PL:
        return 'Transitioning_Page_Polish'
      case LanguageEnum.de_DE:
        return 'Transitioning_Page_German'
      default:
        return 'Transitioning_Page'
    }
  }

  const localisedFussyEaterPDFPath = (language: Language) => {
    switch (language) {
      case LanguageEnum.nl:
      case LanguageEnum.nl_BE:
        return 'Fussy_Eater_Page_Dutch'
      case LanguageEnum.pl:
      case LanguageEnum.pl_PL:
        return 'Fussy_Eater_Page_Polish'
      case LanguageEnum.de_DE:
        return 'Fussy_Eater_Page_German'
      default:
        return 'Fussy_Eater_Page'
    }
  }

  const handleButtonClick = (
    fussyDogsNumber: number,
    shippingCountryCode: CountryCode
  ) => {
    const sanitisedLanguageCode = lngStringToLngType(i18next.language)
    if (
      fussyDogsNumber >= 1 &&
      !nonSupportedRegions.includes(shippingCountryCode as Code)
    ) {
      const fussyEaterPDFPath = localisedFussyEaterPDFPath(
        sanitisedLanguageCode
      )
      // eslint-disable-next-line i18next/no-literal-string
      const url = `https://assets.butternutbox.com/image/upload/Web/documents/${fussyEaterPDFPath}.pdf`
      window.open(url, '_blank')
    } else {
      const transitioningPDFPath = localisedTransitioningPDFPath(
        sanitisedLanguageCode
      )
      // eslint-disable-next-line i18next/no-literal-string
      const url = `https://assets.butternutbox.com/image/upload/Web/documents/${transitioningPDFPath}.pdf`
      window.open(url, '_blank')
    }
  }

  if (isMobile) {
    return (
      <ImageCard
        button={{
          onClick: () =>
            handleButtonClick(fussyDogsNumber, shippingCountryCode),
          text: {
            text: `${copyContext}.pill`,
            namespace
          }
        }}
        alt={`${copyContext}.title`}
        image={{ width: 200, height: 200 }}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => handleButtonClick(fussyDogsNumber, shippingCountryCode)}
        padding={0}
        position="top"
        slug="feeding-tips"
        heading={{
          text: `${copyContext}.title`,
          namespace,
          variant: 'textRegular16',
          colour: 'brandBlue400'
        }}
        background={false}
        identifier="feeding_tips_card"
      >
        <Text
          text={`${copyContext}.subtitle`}
          margin={false}
          namespace={namespace}
        />
      </ImageCard>
    )
  }
  return (
    <div className={STYLES.desktopCard}>
      <div className={STYLES.imgWrapper}>
        <Image
          slug="feeding-tips"
          alt={`${copyContext}.title`}
          image={{
            width: 360,
            height: 200,
            aspectRatio: '4:3'
          }}
          className={`
            ${STYLES.image}
          `}
        />
      </div>
      <Card shadow padding={32}>
        <div className={STYLES.cardWrapper}>
          <Text
            text={`${copyContext}.subtitle`}
            namespace={namespace}
            margin={false}
            variant="display24"
          />
          <Text
            text={`${copyContext}.pill`}
            namespace={namespace}
            margin={false}
            colour="brandBlue400"
          />
          <Button
            identifier="feeding_tips.button"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() =>
              handleButtonClick(fussyDogsNumber, shippingCountryCode)
            }
            typography={{
              namespace,
              text: `${copyContext}.cta`
            }}
          />
        </div>
      </Card>
    </div>
  )
}

export default FeedingTipsCard
