// @noflow
import isNull from 'lodash/isNull'
import React from 'react'

import DiscountBadge from '@/components/elements/atoms/DiscountBadge/DiscountBadge'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import type {
  AllowedColours,
  Props as TextProps
} from '@/components/elements/atoms/Text/Text'

import STYLES from './AlertCard.module.sass'

type Variant =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'pointerInfo'
  | 'discount'
  | 'savings'

type TextAlign = 'left' | 'center' | 'right'

type PointerPosition = 'left' | 'center' | 'right'

type Props = {
  message: {
    align?: TextAlign
  } & TextProps
  variant?: Variant
  pointerPosition?: PointerPosition
  discountIcon?: string
}

const AlertCard = ({
  message,
  variant = 'info',
  pointerPosition,
  discountIcon = '%'
}: Props): JSX.Element => {
  const variantVariables = (): {
    colour: AllowedColours
    icon: JSX.Element | null
  } => {
    switch (variant) {
      case 'success': {
        return {
          colour: 'supportGreen500',
          icon: (
            <Icon
              asset="checkmark"
              size={22}
              accentColour="successGreen300"
              width={22}
            />
          )
        }
      }
      case 'error': {
        return {
          colour: 'dangerRed500',
          icon: (
            <Icon
              asset="errorCircle"
              size={22}
              accentColour="dangerRed400"
              width={22}
            />
          )
        }
      }
      case 'warning': {
        return {
          colour: 'supportOrange500',
          icon: (
            <Icon
              asset="alert"
              size={22}
              width={22}
              accentColour="warningOrange300"
              backgroundColour="warningOrange100"
            />
          )
        }
      }
      case 'info': {
        return {
          colour: 'supportBlue500',
          icon: (
            <Icon
              asset="alert"
              size={22}
              width={22}
              accentColour="supportBlue300"
              backgroundColour="supportBlue100"
            />
          )
        }
      }
      case 'pointerInfo': {
        return {
          colour: 'supportBlue500',
          icon: null
        }
      }
      case 'discount': {
        return {
          colour: 'brandBlue500',
          icon: null
        }
      }
      case 'savings': {
        return {
          colour: 'supportGreen500',
          icon: null
        }
      }
    }
  }

  const DefaultIcon = () => variantVariables().icon

  return (
    <div
      className={`${STYLES.container} ${STYLES[variant]} ${
        pointerPosition ? STYLES[pointerPosition] : ''
      }`}
    >
      {!isNull(Icon) && <DefaultIcon />}
      {(variant === 'discount' || variant === 'savings') && discountIcon && (
        <DiscountBadge
          discount={discountIcon}
          symbolOnly
          size="small"
          variant={variant}
        />
      )}
      <Text
        text={message.text}
        variables={message.variables}
        namespace={message.namespace}
        element={message.element}
        variant={message.variant || 'textRegular16'}
        colour={variantVariables().colour}
        align={message.align || 'center'}
        margin={message.margin}
        translate={message.translate}
      />
    </div>
  )
}

export { Props }
export default AlertCard
