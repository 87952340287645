import { gql } from '@apollo/client'

const FUNNEl_GIFT_CAMPAIGN_QUERY = gql`
  query FunnelGiftCampaignQuery($slug: String!) {
    funnelGiftCampaign(slug: $slug) {
      productVariant {
        id
        name
        recurringDeliveryType {
          netPrice
        }
        productCollection {
          id
          name
          merchandisingImage {
            src
          }
          slug
        }
        grossPrice
      }
    }
  }
`

export default FUNNEl_GIFT_CAMPAIGN_QUERY
