import React from 'react'

import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'

import STYLES from './OptionSelector.module.sass'

const OptionSelectorSkeleton = (): React.JSX.Element => (
  <div className={STYLES.container} data-testid="option-selector-skeleton">
    <SkeletonButton width={56} height={56} margin="0" />
    <div className={STYLES.selectedOption}>
      <SkeletonButton width={100} height={36} />
    </div>
    <SkeletonButton width={56} height={56} margin="0" />
  </div>
)

export { OptionSelectorSkeleton }
