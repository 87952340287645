import { useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import i18next from 'i18next'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { userDataVar } from '@/services/apollo'

import { possessive, pronounContext } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import { useDogProfileUpload } from '@/hooks/useDogProfilePictureUpload'
import useWindowSize from '@/hooks/useWindowSize'

import Camera from 'assets/images/icons/camera--drawn.svg'
import PolaroidFallback from 'assets/images/illustrations/dogs/round-dog-avatar.svg'
import PolaroidDecoration from 'assets/images/sprinkles/sparkles-yellow-300.svg'

import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text'

import STYLES from './DogProfile.module.sass'

import { thankYouQueryV3_user_dogs as Dog } from '../../__generated__/thankYouQueryV3'

type DogProfileCard = {
  dog: Dog
  handleUpload: (path: string, dog: Dog) => void
}

type DogProfileProps = {
  dogs: Dog[]
  handleUpload: (path: string, dog: Dog) => void
}

const DogProfileCard = ({ dog, handleUpload }: DogProfileCard) => {
  const { t } = useTranslation('account')
  const user = useReactiveVar(userDataVar)
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.sm

  const dogProfileUpload = {
    handleSuccessMessage: (file: string) => handleUpload(file, dog),
    userId: user?.id || '',
    dog
  }

  const {
    fileInputRef,
    handleElementClick,
    handleFileSelect,
    pictureUploading,
    profilePicture
  } = useDogProfileUpload(dogProfileUpload)

  return (
    <Card
      padding={24}
      dataTestId={`dog-profile`}
      variant={
        profilePicture || dog.dogProfile?.avatarUrl
          ? 'successGreen100'
          : 'brandYellow100'
      }
      onClick={handleElementClick}
      skeleton={{
        isLoading: pictureUploading,
        height: isMobile ? '15.8rem' : '18.8rem'
      }}
      identifier="dog_profile_card"
      screenIdentifier="thank_you_page"
    >
      <Grid container gap={{ xs: 1 }} wrap="nowrap" alignItems="center">
        <Grid item xs={7}>
          <div className={STYLES.iconWrapper}>
            {(profilePicture || dog.dogProfile?.avatarUrl) && (
              <Icon
                asset={'checkmark'}
                size={22}
                accentColour={'successGreen300'}
              />
            )}
            <Text
              dataTestId="dog-profile-title"
              namespace="account"
              variant="display24"
              text={
                profilePicture || dog.dogProfile?.avatarUrl
                  ? 'thank_you_page_v3.dog_profile.great_photo'
                  : 'thank_you_page_v3.dog_profile.upload_photo'
              }
              variables={{ dogName: possessive(dog.name) }}
              margin={false}
              colour={
                profilePicture || dog.dogProfile?.avatarUrl
                  ? 'supportGreen500'
                  : 'brandBlue500'
              }
            />
          </div>
          <Text
            namespace="account"
            margin={false}
            text={
              profilePicture || dog.dogProfile?.avatarUrl
                ? `thank_you_page_v3.dog_profile.looks_pawsome`
                : `thank_you_page_v3.dog_profile.say_cheese`
            }
            colour={
              profilePicture || dog.dogProfile?.avatarUrl
                ? 'supportGreen500'
                : 'brandBlue500'
            }
            variables={{
              context: pronounContext([dog.gender], i18next.language)
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <div className={STYLES.polaroid}>
            <img
              src={
                profilePicture || dog.dogProfile?.avatarUrl || PolaroidFallback
              }
              alt={dog.dogProfile?.description || dog.name}
              className={STYLES.polaroidPicture}
              width={128}
              height={128}
            />
            <img
              src={PolaroidDecoration}
              className={STYLES.polaroidPictureDecoration}
              alt={t('thank_you_page_v3.dog_profile.sparkles_alt')}
            />
            <div className={STYLES.camera}>
              <img
                src={Camera}
                alt={t('thank_you_page_v3.dog_profile.camera_alt')}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelect}
        className={STYLES.input}
        data-testid="dog-profile-upload-input"
      />
    </Card>
  )
}

const DogProfile = ({ dogs, handleUpload }: DogProfileProps): JSX.Element => (
  <Fragment>
    {dogs.map((dog) => (
      <DogProfileCard key={dog.id} dog={dog} handleUpload={handleUpload} />
    ))}
  </Fragment>
)

export default DogProfile
