import { gql } from '@apollo/client'

import { PRICE_FRAGMENT } from '@/components/pages/ExtrasPage/screens/ProductCollection/components/molecules/ProductPrice/fragments'

import { SHOP_ITEM_FRAGMENT } from '../../BoxItem/ShopItem/fragments/shopItemFragment'

const BOX_PHYSICAL_ORDER_PRODUCTS = gql`
  ${PRICE_FRAGMENT}
  fragment BoxPhysicalOrderProducts on Box {
    physicalOrderProducts {
      id
      requiresPayment
      quantity
      frequency
      price
      discountedPrice
      nonPaymentReason
      productVariant {
        id
        name
        discountedPrice
        grossPrice
        oneOffDeliveryType {
          netPrice
        }
        recurringDeliveryType {
          adjustedGrossPrice
          netPrice
        }
        subscribeAndSaveable
        productCollection {
          id
          slug
          name
          thumbnail {
            src
          }
          productVariants {
            id
            name
            ...PriceFragment
          }
        }
      }
    }
  }
`
const EDIT_EXTRAS_USER_FRAGMENT = gql`
  fragment EditExtrasUserFragment on User {
    id
    shippingCountryCode
    shippingCountry {
      id
      showUpcomingOrderSeeMoreExtras
      showExtras
    }
    preferredLanguage
  }
`
const EDIT_EXTRAS_BOX_FRAGMENT = gql`
  ${SHOP_ITEM_FRAGMENT}
  fragment EditExtrasBoxFragment on Box {
    id
    isoDeliveryDate
    physicalOrderProducts {
      id
      frequency
      quantity
      price
      discountedPrice
      requiresPayment
      nonPaymentReason
      productVariant {
        id
        grossPrice
        subscribeAndSaveable
        productCollection {
          id
          thumbnail {
            src
          }
          productVariants {
            id
            name
          }
        }
        ...ShopItemFragment
      }
    }
  }
`

export {
  BOX_PHYSICAL_ORDER_PRODUCTS,
  EDIT_EXTRAS_USER_FRAGMENT,
  EDIT_EXTRAS_BOX_FRAGMENT
}
