import { gql } from '@apollo/client'

const EDIT_DELIVERY_FREQUENCY_MUTATION = gql`
  mutation EditDeliveryFrequencyMutation(
    $userId: ID!
    $planId: ID!
    $nextNBoxesNum: Int!
  ) {
    subscriptionPlanUpdate(userId: $userId, planId: $planId) {
      id
      subscription {
        id
        plan {
          id
          numberOfPouches
          durationInWeeks
          pricePerDay
          pouchSize
        }
        nextNBoxes(num: $nextNBoxesNum) {
          id
          numberOfPouches
          durationInDays
          isoDeliveryDate
          editable
          plan {
            id
            pouchSize
          }
        }
      }
    }
  }
`

export { EDIT_DELIVERY_FREQUENCY_MUTATION }
