// @noflow
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import React, { useCallback, useMemo } from 'react'
import Swiper from 'swiper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import segmentTrack from '@/components/analytics/Analytics'
import { cloudinaryPath } from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './Gallery.module.sass'

import type { ProductCollectionQuery_productCollection as ProductCollection } from '../../../queries/__generated__/ProductCollectionQuery'

type Props = {
  name: ProductCollection['name']
  productSticker: ProductCollection['productSticker']
  images: ProductCollection['images']
  productCollectionSlug: ProductCollection['slug']
}

enum Action {
  onSwipe = 'Swipe',
  onClickThumbnail = 'Thumbnail Clicked',
  arrowClicked = 'Arrow Clicked'
}

const Gallery = ({
  name,
  productSticker,
  images,
  productCollectionSlug
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()

  const triggerCarouselInteractionAnalytics = useCallback(
    (action: Action): void => {
      const eventName = 'Interacted with Photo Carousel'
      const properties = {
        action: action,
        productCollectionSlug: productCollectionSlug
      }

      segmentTrack(eventName, properties)
    },
    [productCollectionSlug]
  )

  const triggerCarouselImageAnalytics = useCallback(
    (index: number): void => {
      const eventName = 'Photo Carousel Image Shown'
      const properties = {
        imageNumber: index + 1,
        productCollectionSlug: productCollectionSlug
      }

      segmentTrack(eventName, properties)
    },
    [productCollectionSlug]
  )

  const events = useMemo(
    () => ({
      onSwipe: () => triggerCarouselInteractionAnalytics(Action.onSwipe),
      onArrowClick: () =>
        triggerCarouselInteractionAnalytics(Action.arrowClicked),
      afterChange: triggerCarouselImageAnalytics,
      onClickThumbnail: () =>
        triggerCarouselInteractionAnalytics(Action.onClickThumbnail)
    }),
    [triggerCarouselInteractionAnalytics, triggerCarouselImageAnalytics]
  )

  const onSlideChange = useCallback(
    (event: Swiper) => events.afterChange(event.activeIndex),
    [events]
  )

  return (
    <div className={STYLES.container}>
      <div className={STYLES.carousel}>
        {productSticker && (
          <img
            alt={startCase(toLower(productSticker.slug))}
            src={cloudinaryPath({
              path: productSticker.image.src,
              transformations: { format: 'svg' }
            })}
            className={STYLES.productSticker}
          />
        )}
        <SwiperSlider
          slidesPerView={1}
          arrows={windowWidth >= BREAKPOINTS.md}
          arrowsInside
          thumbs
          autoHeight
          variant="brandBlue100"
          onArrowClick={events.onArrowClick}
          onSliderFirstMove={events.onSwipe}
          onSlideChange={onSlideChange}
          onThumbnailClick={events.onClickThumbnail}
        >
          {images.map(({ src }) => (
            <CloudinaryImage
              alt={name}
              className={STYLES.image}
              key={src}
              image={{
                path: src,
                width: 980,
                height: 980,
                crop: 'fill',
                quality: 'auto:best',
                dpr: window.devicePixelRatio
              }}
            />
          ))}
        </SwiperSlider>
      </div>
    </div>
  )
}

export default Gallery
