// @noflow
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { ApolloError, useMutation } from '@apollo/client'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as Sentry from '@/utils/sentry'

import { BenefitModalTrigger } from './components/BenefitModalTrigger'
import { FrequencySelector } from './components/FrequencySelector'
import { PlanOverview } from './components/PlanOverview'
import { Button } from '@/components/elements/atoms/Button'
import { FixedBase } from '@/components/elements/atoms/FixedBase'

import STYLES from './EditDeliveryFrequency.module.sass'

import { EDIT_DELIVERY_FREQUENCY_MUTATION } from './mutations/editDeliveryFrequencyMutation'

import type {
  EditDeliveryFrequencyMutation,
  EditDeliveryFrequencyMutationVariables
} from './mutations/__generated__/EditDeliveryFrequencyMutation'
import type { EditDeliveryFrequencyQuery } from './queries/__generated__/EditDeliveryFrequencyQuery'

import {
  createFrequencyOptionsFromPlans,
  getBenefitType,
  getPlanChanges
} from './utils'

type Props = Partial<EditDeliveryFrequencyQuery> & {
  loading: boolean
}

const EditDeliveryFrequencyContent = ({
  loading,
  ...props
}: Props): React.JSX.Element => {
  const { setErrorNotification, setSuccessNotification } = useNotifications()
  const navigate = useNavigate()
  const { boxId } = useParams()

  const container = useRef<HTMLDivElement>(null)

  const [chosenPlanId, setChosenPlanId] = useState<string | null>(null)

  /**
   * Manages the current and chosen plan states
   * - Returns null values when loading or no subscription plan
   * - Updates when loading state changes, user data updates, or chosen plan ID changes
   * - Chosen plan defaults to current plan if not found in available plans
   */
  const { currentPlan, chosenPlan } = useMemo(() => {
    if (loading || !props.user?.subscription?.plan) {
      return { currentPlan: null, chosenPlan: null }
    }

    const availablePlans = props.user.availablePlans
    const current = props.user.subscription.plan

    /**
     * We intentionally find plans by comparing their properties (pouches, duration, etc.)
     * instead of direct ID matching because the current subscription plan might not be
     * included in availablePlans. This can happen when a plan was previously available
     * but is no longer offered. By comparing plan properties instead of IDs, we ensure
     * we can still identify and handle the current plan correctly, even if its ID
     * doesn't match any available plan IDs.
     */
    const currentAvailable =
      availablePlans.find(
        (plan) =>
          plan.numberOfPouches === current.numberOfPouches &&
          plan.durationInWeeks === current.durationInWeeks &&
          plan.pouchSize === current.pouchSize
      ) ?? null

    const chosen =
      availablePlans.find((plan) => plan.id === chosenPlanId) ??
      currentAvailable

    return { currentPlan: currentAvailable, chosenPlan: chosen }
  }, [
    loading,
    props.user?.availablePlans,
    props.user?.subscription?.plan,
    chosenPlanId
  ])

  /**
   * Creates frequency options from available plans
   * - Returns empty array if no available plans
   * - Maps plan data to frequency option format with localized labels
   * - Recalculates when user data changes
   */
  const frequencyOptions = useMemo(
    () =>
      createFrequencyOptionsFromPlans(
        props.user?.availablePlans ?? null,
        currentPlan
      ),
    [props.user?.availablePlans, currentPlan]
  )

  /**
   * Determines the benefit type based on plan comparison
   * - Returns null if either plan is not available
   * - Compares pouches and price to determine benefit type
   * - Updates when either current or chosen plan changes
   */
  const benefitType = useMemo(
    () => getBenefitType(currentPlan, chosenPlan),
    [currentPlan, chosenPlan]
  )

  /**
   * Tracks changes between current and chosen plans
   * - Detects changes in number of pouches for UI animations
   * - Determines if any plan properties have changed to enable/disable update button
   * - Updates only when plans change
   */
  const { hasNumberOfPouchesChanged, hasPlanChanged } = useMemo(
    () => getPlanChanges(currentPlan, chosenPlan),
    [currentPlan, chosenPlan]
  )

  const captureSentryError = (error: ApolloError | unknown) => {
    Sentry.captureException('Error updating delivery frequency', {
      extra: {
        message: error
      },
      tags: {
        product: Sentry.Product.Account
      }
    })
  }

  /**
   * Handles delivery frequency updates
   * - Updates subscription plan with chosen frequency
   * - Shows success toast and navigates back on completion
   * - Displays error toast and logs to Sentry on failure
   * - Disabled when no changes or mutation in progress
   */
  const [editDeliveryFrequencyMutation, { loading: mutationLoading }] =
    useMutation<
      EditDeliveryFrequencyMutation,
      EditDeliveryFrequencyMutationVariables
    >(EDIT_DELIVERY_FREQUENCY_MUTATION, {
      variables: {
        userId: props.user?.id ?? '',
        planId: chosenPlanId ?? '',
        nextNBoxesNum: 3
      },
      onCompleted: () => {
        navigate(`${ACCOUNT_ROUTES.changeDate}/${boxId}`)

        setSuccessNotification({
          text: 'edit_delivery_frequency.alerts.success',
          namespace: 'account'
        })
      },
      onError: (error) => {
        captureSentryError(error)

        setErrorNotification({
          text: 'edit_delivery_frequency.alerts.error',
          namespace: 'account'
        })
      }
    })

  const handleEditDeliveryFrequency = useCallback(async (): Promise<void> => {
    if (!chosenPlanId || !hasPlanChanged) return

    try {
      await editDeliveryFrequencyMutation()
    } catch (error) {
      captureSentryError(error)
    }
  }, [chosenPlanId, hasPlanChanged, editDeliveryFrequencyMutation])

  return (
    <div ref={container} className={STYLES.container}>
      <FrequencySelector
        currentPlan={currentPlan}
        chosenPlan={chosenPlan}
        frequencyOptions={frequencyOptions}
        setChosenPlanId={setChosenPlanId}
        loading={loading}
        mutationLoading={mutationLoading}
      />
      <PlanOverview
        currentPlan={currentPlan}
        chosenPlan={chosenPlan}
        benefitType={benefitType}
        hasNumberOfPouchesChanged={hasNumberOfPouchesChanged}
        hasPlanChanged={hasPlanChanged}
        loading={loading}
      />
      <BenefitModalTrigger benefitType={benefitType} />
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.footerContent}>
          <Button
            identifier="dog_medical_info.update"
            onClick={handleEditDeliveryFrequency}
            typography={{
              text: 'edit_delivery_frequency.cta.update',
              namespace: 'account',
              variables: { context: mutationLoading ? 'loading' : '' }
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={!hasPlanChanged || mutationLoading}
          />
        </div>
      </FixedBase>
    </div>
  )
}

export { EditDeliveryFrequencyContent }
