import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useMutation } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'

import cookies from '@/utils/cookies'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import { PAUSE_FLOW_STARTED_EVENT_CREATE } from './mutations/pauseFlowStartedEventCreate'

import { PersonalDetailsQuery_user_subscription } from '../../queries/__generated__/PersonalDetailsQuery'
import { Code } from '@/types'

import { DelayBox } from './Delay'
import { DiscountModal } from './Discount'
import { PauseError } from './Error'

type Props = {
  subscription?: PersonalDetailsQuery_user_subscription
  shippingCountryCode?: Code
  showPausePrompt: boolean
  setshowPausePrompt: (val: boolean) => void
}

const PauseModal = ({
  setshowPausePrompt,
  subscription,
  shippingCountryCode = Code.GB
}: Props): JSX.Element | null => {
  const { userLanguage } = useLanguage()
  const [brightbackUrl, setBrightbackUrl] = useState<null | string>(null)

  const [trackPauseFlowStarted] = useMutation(PAUSE_FLOW_STARTED_EVENT_CREATE)

  useEffect(() => {
    if (subscription?.pauseUrl) {
      setBrightbackUrl(subscription?.pauseUrl)
    }
  }, [subscription?.pauseUrl])

  const handleHelpNavigation = useCallback(() => {
    window.location.href = '/contact'
  }, [])

  const handlePauseSubscription = useCallback(async () => {
    window.localStorage.removeItem(cookies.restartBoxSelectedDate)

    if (brightbackUrl) {
      try {
        await trackPauseFlowStarted({
          variables: {
            properties: false
          }
        })
      } catch (error) {
        Sentry.captureException(
          `Error with PAUSE_FLOW_STARTED_EVENT_CREATE mutation`,
          {
            extra: { error },
            tags: {
              product: Sentry.Product.Account,
              team: Sentry.Team.Retention
            }
          }
        )
      } finally {
        window.location.href = brightbackUrl
      }
      return
    }

    handleHelpNavigation()
  }, [brightbackUrl, handleHelpNavigation, trackPauseFlowStarted])

  const handleModalClose = useCallback(
    () => setshowPausePrompt(false),
    [setshowPausePrompt]
  )

  if (!subscription) {
    return <PauseError handleHelpNavigation={handleHelpNavigation} />
  }

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    userLanguage
  )

  const { nextEditableBox } = subscription
  const { order, isoDeliveryDate, shippingCountry } = nextEditableBox || {}
  const { discountTotal, discountTotalPercentage } = order || {}

  const discount = discountTotal || 0
  const discountPercentage = discountTotalPercentage || 0

  const props = {
    handleHelpNavigation,
    currency,
    locale,
    discount,
    discountPercentage,
    handleModalClose,
    handlePauseSubscription
  }

  if (discount > 0) {
    return (
      <DiscountModal
        {...props}
        isoDeliveryDate={isoDeliveryDate}
        shippingCountryCode={shippingCountry?.code || Code.GB}
      />
    )
  }

  return <DelayBox {...props} nextBox={nextEditableBox} />
}
export { PauseModal }
