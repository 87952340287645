// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import segmentTrack from '@/components/analytics/Analytics'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import MerchandisingCard from '@/components/elements/atoms/MerchandisingCard/MerchandisingCard'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'

import STYLES from './MerchandisingRAFCard.module.sass'

import { MerchRafCardQuery } from './__generated__/MerchRafCardQuery'

import { MERCH_RAF_CARD_QUERY } from './queries'

const analyticsOnClick = (title: string, link: string) => {
  segmentTrack('Merchandising Card Clicked', {
    title,
    link
  })
}

const MerchandisingRAFCard = (): JSX.Element | null => {
  const { t } = useTranslation()

  const { loading, data, error } =
    useQuery<MerchRafCardQuery>(MERCH_RAF_CARD_QUERY)

  if (loading && !error) {
    return (
      <div className={STYLES.cardWrapper}>
        <CardSkeleton height="20rem" width="100%" />
        <SkeletonParagraph
          align={'left'}
          count={1}
          shortLine={false}
          margin={'1.6rem 0'}
        />
      </div>
    )
  }
  // eslint-disable-next-line i18next/no-literal-string
  if (error)
    throw new Error(
      `Error when MERCH_RAF_CARD_QUERY executes: ${error.message}`
    )

  if (isUndefined(data) || isNull(data)) return null

  const { user } = data
  const { shippingCountryCode, preferredLanguage, subscription } = user
  const { referralLink } = subscription
  const { referrerDiscount } = referralLink || {}

  if (isUndefined(referrerDiscount)) return null

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const hasPercentageDiscount =
    referrerDiscount &&
    referrerDiscount.applicableDiscountParts[0].discountBasis === 'percentage'

  const hasAltruisticDiscount =
    referrerDiscount && referrerDiscount.applicableDiscountParts[0].value <= 0

  const discountText = () =>
    hasAltruisticDiscount
      ? 'merchandising_card.raf.body_altruistic'
      : 'merchandising_card.raf.body'

  const discountValue = () =>
    hasPercentageDiscount
      ? percentageValue(referrerDiscount.applicableDiscountParts[0].value)
      : formatCurrencyWithoutDecimal(
          referrerDiscount?.applicableDiscountParts[0].value as number,
          {
            locale,
            currency
          }
        )

  const discountBadge = () =>
    hasAltruisticDiscount
      ? undefined
      : {
          text: 'merchandising_card.raf.discountCopy',
          variables: { discount: discountValue() },
          namespace: 'atoms'
        }

  const discountTitle = () => ({
    text: discountText(),
    variables: { discount: discountValue() },
    namespace: 'atoms'
  })

  const merchandisingCardPath = () => ACCOUNT_ROUTES.referAFriend

  return (
    <div className={STYLES.cardWrapper}>
      <MerchandisingCard
        key="merch-card-raf-key"
        alt={t('atoms:merchandising_card.raf.alt')}
        image="/Web/photos/dogs/three-dogs-wearing-bandanas"
        discount={discountBadge()}
        title={discountTitle()}
        to={merchandisingCardPath()}
        analyticsOnClick={analyticsOnClick}
        identifier="merchandising_card.raf"
        fullWidth
      />
    </div>
  )
}

export default MerchandisingRAFCard
