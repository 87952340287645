// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import i18next from 'i18next'
import { isNull } from 'lodash'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { dogsDataVar } from '@/services/apollo'

import { possessive, toLocalisedSentence } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import RadioGroup, {
  RadioOption
} from '@/components/elements/molecules/RadioGroup/RadioGroup'

import STYLES from './ArchiveDogProfileAction.module.sass'

import { ARCHIVE_DOG_PROFILE_MUTATION } from './mutations/archiveDogProfileMutation'

import { ArchiveDogProfileActionDogFragment as Dog } from './fragments/__generated__/ArchiveDogProfileActionDogFragment'
import { ArchiveDogProfileActionQueryFragment as Query } from './fragments/__generated__/ArchiveDogProfileActionQueryFragment'
import type {
  ArchiveDogProfileMutation,
  ArchiveDogProfileMutationVariables
} from './mutations/__generated__/ArchiveDogProfileMutation'

type Props = {
  dog: Dog
  dogs: Array<Dog> | null
  archiveReasons: Query['archiveReasons']
  isModalOpen: boolean
  toggleModal: () => void
}

const ArchiveDogProfileModal = ({
  dog,
  dogs,
  archiveReasons,
  isModalOpen,
  toggleModal
}: Props): JSX.Element | null => {
  const dogsData = useReactiveVar(dogsDataVar)
  const { windowWidth } = useWindowSize()

  const [dogRemoved, setDogRemoved] = useState(false)
  const [selectedArchiveReason, setSelectedArchiveReason] = useState('')

  /**
   * We set the current dogs to the dogs list passed as props to avoid
   * the issue of the dogs list not being updated in time when the user
   * archive a dog profile.
   */
  const [currentDogs, setCurrentDogs] = useState(dogs)
  const [dogsAmountBeforeArchive] = useState(currentDogs?.length ?? 0)

  const archivingVariant = dogsAmountBeforeArchive === 1 ? 'single' : 'multiple'

  const genderContext =
    (dogs && dogs.length > 0 && (dogs?.length > 1 ? '' : dogs[0].gender)) ?? ''
  const possessiveDogName = possessive(dog.name, i18next.language)
  const filteredDogs = dogs?.filter(({ id }) => id !== dog?.id) ?? []
  const joinedDogsNames =
    filteredDogs.length > 0
      ? toLocalisedSentence({
          arr: filteredDogs.map(({ name }) => name),
          lng: i18next.language
        })
      : ''

  const transformedArchiveReasons = archiveReasons.map(
    (archiveReason: string): RadioOption => ({
      id: archiveReason,
      text: {
        text: 'dog_profile.archive_dog_profile.removing_step.archive_reasons.reason',
        namespace: 'account',
        variables: { context: archiveReason }
      },
      value: archiveReason,
      defaultChecked: archiveReason === selectedArchiveReason
    })
  )

  const handleOnArchiveReasonSelect = useCallback(
    (index: number): void => {
      archiveReasons && setSelectedArchiveReason(archiveReasons[index])
    },
    [setSelectedArchiveReason, archiveReasons]
  )

  const showErrorMessage = useCallback((): void => {
    toast.error(
      <NotificationContent
        copy={{
          namespace: 'account',
          text: 'dog_profile.archive_dog_profile.error'
        }}
      />,
      {
        toastId: 'dog_profile.archive_dog_profile.notifications.error'
      }
    )
  }, [])

  /**
   * Archive dog profile mutation.
   */
  const [archiveDogProfileMutation, { loading: isArchiveDogProfileLoading }] =
    useMutation<ArchiveDogProfileMutation, ArchiveDogProfileMutationVariables>(
      ARCHIVE_DOG_PROFILE_MUTATION,
      {
        update(cache, { data }) {
          if (data?.archiveDogs?.user) {
            const {
              archiveDogs: {
                user: { dogs: restDogs }
              }
            } = data

            const restDogsIds = restDogs?.map(({ id }) => id)
            const updatedDogs =
              dogs?.filter(({ id }) => restDogsIds?.includes(id)) ?? []

            setCurrentDogs(updatedDogs)

            dogsDataVar(
              isNull(dogsData)
                ? null
                : {
                    ...dogsData,
                    dogs:
                      dogsData?.dogs?.filter(({ id }) =>
                        restDogsIds?.includes(id)
                      ) ?? []
                  }
            )

            cache.modify({
              fields: {
                user(userRef) {
                  cache.modify({
                    id: userRef.__ref,
                    fields: {
                      dogs: () => updatedDogs
                    }
                  })
                }
              }
            })
          }
        },
        onCompleted() {
          setDogRemoved(true)
        }
      }
    )

  /**
   * Handler to archive dog profile.
   */
  const archiveDogProfile = useCallback(async (): Promise<void> => {
    try {
      if (selectedArchiveReason && dog) {
        await archiveDogProfileMutation({
          variables: {
            dogIds: [dog.id],
            archiveReason: selectedArchiveReason
          }
        })
      }
    } catch (error) {
      showErrorMessage()

      Sentry.captureException(
        `Encountered error archiving dog's profile: ${error}`
      )
    }
  }, [archiveDogProfileMutation, selectedArchiveReason, dog, showErrorMessage])

  /**
   * If there is no dogs left after archiving the current dog profile,
   * we want to show `NoDogs` screen.
   * If there is at least one dog left, we navigate to the first dog in the list.
   */
  const handleOnExit = useCallback(() => {
    if (currentDogs?.length === 0) {
      window.location.href = ACCOUNT_ROUTES.profile
      return
    }

    if (currentDogs && dogRemoved) {
      const [firstCurrentDog] = currentDogs

      const route = firstCurrentDog
        ? `${ACCOUNT_ROUTES.profile}/${firstCurrentDog.id}`
        : ACCOUNT_ROUTES.profile

      window.location.href = route
    }
  }, [currentDogs, dogRemoved])

  return (
    <Modal
      isModalOpen={isModalOpen}
      setOpenModal={toggleModal}
      onCloseButtonClick={handleOnExit}
      width={600}
      textAlign="center"
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      {dogRemoved ? (
        <>
          <Text
            element="h2"
            namespace="account"
            text="dog_profile.archive_dog_profile.exiting_step.title"
            variant="display28"
          />
          <AlertCard
            message={{
              text: `dog_profile.archive_dog_profile.exiting_step.${archivingVariant}.alert`,
              variables: {
                possessiveDogName
              },
              namespace: 'account'
            }}
            variant="success"
          />
          <SpeechBubble
            footPosition="bottomLeft"
            text={{
              namespace: 'account',
              text: `dog_profile.archive_dog_profile.exiting_step.${archivingVariant}.speech_bubble_text`,
              variables: {
                context: selectedArchiveReason ?? ''
              }
            }}
          />
          <ExpertCard
            namespace={'expert'}
            expert={{
              name: 'Jessie and Luna',
              description: `jessie-and-luna.profession`,
              imgSrc: 'Web/photos/people/jessie-and-luna-square.png',
              imgAlt: `jessie-and-luna.profession`
            }}
            imageProps={{
              width: 80,
              height: 80,
              aspectRatio: '1:1',
              crop: 'fill',
              gravity: 'auto',
              dpr: 2,
              path: 'Web/photos/people/jessie-and-luna-square.png'
            }}
          />
          <div className={STYLES.modalFooter}>
            <Button
              identifier="archive_dog_profile.exiting_step.confirm"
              typography={{
                text: 'dog_profile.archive_dog_profile.exiting_step.button',
                namespace: 'account'
              }}
              onClick={handleOnExit}
            />
          </div>
        </>
      ) : (
        <>
          <Text
            element="h2"
            namespace="account"
            text="dog_profile.archive_dog_profile.removing_step.title"
            variant="display28"
          />
          <Text
            namespace="account"
            text={`dog_profile.archive_dog_profile.removing_step.${archivingVariant}.sub_title`}
            variables={{
              possessiveDogName,
              joinedDogsNames,
              count: filteredDogs.length,
              context: genderContext
            }}
            align="left"
          />
          <Text
            namespace="account"
            text={`dog_profile.archive_dog_profile.removing_step.${archivingVariant}.description`}
            align="left"
          />
          <Text
            namespace="account"
            text="dog_profile.archive_dog_profile.removing_step.archive_reasons.title"
            variables={{ possessiveDogName }}
            align="left"
          />
          <form>
            <div className={STYLES.radioGroup}>
              <RadioGroup
                onChange={handleOnArchiveReasonSelect}
                variant="fullWidth"
                radioOptions={transformedArchiveReasons}
                identifier="archive_dog_profile.removing_step.archive_reasons"
              />
            </div>
            <div className={STYLES.modalFooter}>
              <Button
                identifier="archive_dog_profile.removing_step.archive"
                typography={{
                  text: `dog_profile.archive_dog_profile.removing_step.${archivingVariant}.button`,
                  namespace: 'account'
                }}
                disabled={
                  isArchiveDogProfileLoading || selectedArchiveReason === ''
                }
                onClick={archiveDogProfile}
              />
            </div>
          </form>
        </>
      )}
    </Modal>
  )
}

export { ArchiveDogProfileModal }
