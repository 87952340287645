// @noflow

/* eslint-disable jsx-a11y/label-has-for */
import camelCase from 'lodash/camelCase'
import isUndefined from 'lodash/isUndefined'
import React, { ReactNode, useCallback, useMemo } from 'react'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import HybridSelect, {
  Option
} from '@/components/elements/atoms/HybridSelect/HybridSelect'
import Label from '@/components/elements/atoms/Label/Label'
import QuantitySelector from '@/components/elements/atoms/QuantitySelector/QuantitySelector'
import Text from '@/components/elements/atoms/Text/Text'
import IconButton from '@/components/elements/molecules/IconButton/IconButton'
import LabelList from '@/components/elements/molecules/LabelList/LabelList'

import STYLES from './../boxitem.module.sass'

import { ShopItemFragment } from './fragments/__generated__/ShopItemFragment'
import { NonPaymentReason } from '@/types'

import { editShopItemAnalytics } from './analytics'

type Props = {
  price: string
  productName: string
  quantity: number
  children?: ReactNode
  cloudinaryPath?: string
  discountedPrice: string | null
  editable?: boolean
  free?: boolean
  recurring?: boolean
  sizeDescription: string | null
  subscribeSave?: boolean
  grossPrice?: string
}

type EditedProductDetails = {
  productCollectionSlug: string
  size: string
  productVariantId: string
  isRecurring?: boolean
}

type EditableShopItemProps = Props & {
  id: string
  productVariant: ShopItemFragment
  size: string
  sizes: Option[]
  maxQuantity?: number
  processing?: boolean
  requiresPayment?: boolean
  nonPaymentReason: NonPaymentReason | null
  onSizeChange?: (id: string, size: string) => void
  onQuantityChange?: (
    id: string,
    quantity: number,
    editedProductDetails: EditedProductDetails
  ) => void
}

const ItemLabels = ({
  subscribeSave,
  recurring
}: {
  subscribeSave: Props['subscribeSave']
  recurring: Props['recurring']
}) => {
  if (subscribeSave || recurring) {
    return (
      <Label
        variant="subscribeAndSave"
        text={{
          text: 'box_breakdown.subscribe_and_save',
          namespace: 'shared'
        }}
      />
    )
  }

  return (
    <Label
      variant="oneOff"
      text={{
        text: 'box_breakdown.one_off',
        namespace: 'shared'
      }}
    />
  )
}

const ShopItem = ({
  cloudinaryPath,
  productName,
  price,
  sizeDescription,
  quantity,
  subscribeSave,
  recurring,
  discountedPrice,
  free,
  editable,
  children,
  grossPrice
}: Props): JSX.Element => {
  const shouldDisplayDiscountedPrice =
    (discountedPrice && discountedPrice !== price) ||
    (grossPrice && grossPrice !== price)
  const discountedPriceToShow = discountedPrice || price

  return (
    <div className={STYLES.boxItemRow}>
      <div className={STYLES.details}>
        <div className={STYLES.boxItemImage}>
          {cloudinaryPath && (
            <CloudinaryImage
              alt=""
              image={{
                path: cloudinaryPath,
                crop: 'fill',
                height: 60,
                width: 60,
                dpr: window.devicePixelRatio
              }}
            />
          )}
        </div>
        <div className={STYLES.main}>
          <div className={STYLES.nameAndPrice}>
            <div className={STYLES.name}>
              <Text
                text={productName}
                margin={false}
                variant="textRegular16"
                translate={false}
                align="left"
              />
              {!editable && (
                <div className={STYLES.boxItemDescription}>
                  {sizeDescription && (
                    <Text
                      text={sizeDescription}
                      margin={false}
                      variant="textRegular14"
                      colour="brandBlue400"
                      element="span"
                      translate={false}
                    />
                  )}
                  <Text
                    text={quantity.toString()}
                    margin={false}
                    namespace="shared"
                    variant="textRegular14"
                    colour="brandBlue400"
                    translate={false}
                  />
                </div>
              )}
              <LabelList>
                <ItemLabels
                  subscribeSave={subscribeSave}
                  recurring={recurring}
                />
              </LabelList>
            </div>
            <div className={STYLES.price}>
              {free ? (
                <Text
                  text="shop.free"
                  margin={false}
                  variant="textRegular14"
                  colour="brandBlue400"
                  namespace="order"
                />
              ) : shouldDisplayDiscountedPrice ? (
                <React.Fragment>
                  <Text
                    text={discountedPriceToShow}
                    margin={false}
                    colour={'brandRed500'}
                    translate={false}
                    bold
                  />
                  <Text
                    text={`<accent type="strikeTrough">${grossPrice}</accent>`}
                    margin={false}
                    colour="brandBlue500"
                    variant="textRegular14"
                    translate={false}
                  />
                </React.Fragment>
              ) : (
                <Text
                  text={price}
                  margin={false}
                  colour="brandBlue500"
                  translate={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {children && <div className={STYLES.children}>{children}</div>}
    </div>
  )
}

const EditableShopItem = ({
  id,
  size,
  sizes,
  maxQuantity,
  onSizeChange,
  onQuantityChange,
  processing,
  productVariant,
  ...rest
}: EditableShopItemProps): JSX.Element => {
  const {
    quantity,
    recurring,
    productName,
    requiresPayment,
    nonPaymentReason
  } = rest

  const editedProductDetails = useMemo(
    () => ({
      productCollectionSlug: productVariant.productCollection.slug,
      productVariantId: productVariant.id,
      size: productVariant.name,
      isRecurring: recurring
    }),
    [
      productVariant.productCollection.slug,
      productVariant.id,
      productVariant.name,
      recurring
    ]
  )

  const onSizeChangeCallback = useCallback(
    (to: string) => {
      if (!isUndefined(onSizeChange)) {
        const newSlug = sizes.filter((size) => size.value === to)[0]
        onSizeChange(id, to)

        editShopItemAnalytics({
          product: {
            recurring,
            quantity,
            productVariant,
            nonPaymentReason,
            requiresPayment
          },
          finalQuantity: quantity,
          finalSizeSlug: newSlug.text,
          binSelected: false
        })
      }
    },
    [
      onSizeChange,
      sizes,
      id,
      recurring,
      quantity,
      productVariant,
      nonPaymentReason,
      requiresPayment
    ]
  )

  const onQuantityChangeCallback = useCallback(
    (to: number) => {
      if (!isUndefined(onQuantityChange)) {
        onQuantityChange(id, Number(to), editedProductDetails)

        editShopItemAnalytics({
          product: {
            recurring,
            quantity,
            productVariant,
            nonPaymentReason,
            requiresPayment
          },
          finalQuantity: Number(to),
          finalSizeSlug: rest.sizeDescription,
          binSelected: false
        })
      }
    },
    [
      onQuantityChange,
      id,
      editedProductDetails,
      recurring,
      quantity,
      productVariant,
      nonPaymentReason,
      requiresPayment,
      rest.sizeDescription
    ]
  )

  const onDeleteCallback = useCallback(() => {
    editShopItemAnalytics({
      product: {
        recurring,
        quantity,
        productVariant,
        nonPaymentReason,
        requiresPayment
      },
      finalQuantity: 0,
      finalSizeSlug: rest.sizeDescription,
      binSelected: true
    })

    if (requiresPayment && !isUndefined(onQuantityChange)) {
      onQuantityChange(id, 0, editedProductDetails)
    }
  }, [
    recurring,
    quantity,
    productVariant,
    nonPaymentReason,
    requiresPayment,
    rest.sizeDescription,
    onQuantityChange,
    id,
    editedProductDetails
  ])

  return (
    <ShopItem {...rest} editable>
      <div className={STYLES.fields}>
        <div className={STYLES.update}>
          <QuantitySelector
            quantity={quantity}
            minValue={0}
            onChange={onQuantityChangeCallback}
            processing={processing}
            maxValue={maxQuantity}
            size={30}
            controlled
            debounced
            disabled={rest.free || processing}
            identifier="box_item.shop_item"
          />
          {sizes.length > 1 ? (
            <HybridSelect
              id={`${camelCase(productName)}-size`}
              value={size}
              options={sizes}
              noSelectionLabel={{
                text: ''
              }}
              onChange={onSizeChangeCallback}
              variant="inline"
              size={16}
              disabled={rest.free}
            />
          ) : (
            <Text
              text={sizes[0].text}
              variant={`textRegular16`}
              colour="brandBlue500"
              element="span"
              translate={false}
            />
          )}
        </div>
        <IconButton
          size={32}
          icon="bin"
          onClick={onDeleteCallback}
          disabled={!requiresPayment || processing}
        />
      </div>
    </ShopItem>
  )
}

export { Props, EditableShopItem, EditableShopItemProps, EditedProductDetails }
export default ShopItem
