import { gql } from '@apollo/client'

const DOG_PROFILE_WEIGHT_AND_APPETITE_FRAGMENT = gql`
  fragment DogProfileWeightAndAppetiteFragment on Dog {
    id
    weightInGrams
    bodyShape
    exercise
    eaterType
    snackingHabits
  }
`

export { DOG_PROFILE_WEIGHT_AND_APPETITE_FRAGMENT }
