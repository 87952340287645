// @noflow
import type { TextProps } from '@/components/elements/atoms/Text'

import type { EditDeliveryFrequencyQuery_user_subscription_plan as Plan } from '../queries/__generated__/EditDeliveryFrequencyQuery'

type FrequencyOption = {
  id: string
  typography: TextProps
}

const getBenefitType = (
  currentPlan: Plan | null,
  chosenPlan: Plan | null
): string | null => {
  if (!currentPlan || !chosenPlan) return null

  switch (true) {
    case currentPlan.numberOfPouches > chosenPlan.numberOfPouches: {
      return 'space'
    }
    case currentPlan.pricePerDay > chosenPlan.pricePerDay: {
      return 'price'
    }
    default: {
      return null
    }
  }
}

const getPlanChanges = (
  currentPlan: Plan | null,
  chosenPlan: Plan | null
): {
  hasNumberOfPouchesChanged: boolean
  hasPriceChanged: boolean
  hasPlanChanged: boolean
} => {
  if (!currentPlan || !chosenPlan) {
    return {
      hasNumberOfPouchesChanged: false,
      hasPriceChanged: false,
      hasPlanChanged: false
    }
  }

  const hasNumberOfPouchesChanged =
    currentPlan.numberOfPouches !== chosenPlan.numberOfPouches
  const hasPriceChanged = currentPlan.pricePerDay !== chosenPlan.pricePerDay
  const hasPlanChanged =
    hasNumberOfPouchesChanged ||
    hasPriceChanged ||
    currentPlan.id !== chosenPlan.id

  return { hasNumberOfPouchesChanged, hasPriceChanged, hasPlanChanged }
}

const createFrequencyOptionsFromPlans = (
  plans: Array<Plan> | null,
  currentPlan: Plan | null
): Array<FrequencyOption> => {
  if (!plans) return []

  const plansInCurrentPricingCohort = plans.filter(
    ({ pouchSize }) => pouchSize === currentPlan?.pouchSize
  )

  return plansInCurrentPricingCohort.map(({ id, durationInWeeks }) => ({
    id,
    typography: {
      text: 'edit_delivery_frequency.frequency_selector.label',
      namespace: 'account',
      variables: {
        durationInWeeks,
        count: durationInWeeks
      }
    }
  }))
}

export type { FrequencyOption }

export { getBenefitType, getPlanChanges, createFrequencyOptionsFromPlans }
