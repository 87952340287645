// @noflow
import type { Language } from '@/packs/localisation'

import type { DeliveryDateModalWithCourierQuery_deliveryAreaOptions as DeliveryArea } from './components/DeliveryDateSection/queries/__generated__/DeliveryDateModalWithCourierQuery'

import { FunnelGiftCampaignQuery_funnelGiftCampaign as FunnelGiftCampaign } from '../SimplifiedPlansPage/queries/__generated__/FunnelGiftCampaignQuery'
import type {
  CheckoutPage_guest_discountCode as DiscountCode,
  CheckoutPage_guest_pendingSubscription_productVariants as ExtraProducts,
  CheckoutPage_guest_pendingSubscription as PendingSubscription,
  CheckoutPage_guest_pendingSubscription as PendingSubscriptionOrderPricing
} from './queries/__generated__/CheckoutPage'
import type { Affiliate, Code as CountryCode } from '@/types'

type SelectedTab = 'intro' | 'after'

type InputInteractionState =
  | 'InteractingInteracted'
  | 'InteractingNotInteracted'
  | 'NotInteractingInteracted'
  | 'NotInteractingNotInteracted'

type Dog = {
  name: string
}

type User = {
  preferredLanguage: Language
  dogs: Array<Dog>
  numberOfFreeTrialBoxes: number
  requiresPaymentDetailsOnCheckout: boolean
  affiliateType: Affiliate
  deliveryFee: number
  discount: DiscountCode | null
  funnelGiftCampaign: FunnelGiftCampaign | null
  shippingCountryCode: CountryCode
  firstDeliverableDate: {
    date: string
    endOfLeadTime: string
  }
  shippingCountryCodeId: number
  shouldSupportSca: boolean
}

type Flavours = Array<{
  flavour: {
    id: string
    name: string
    slug: string
    shortDescription: string
    primaryImage: {
      src: string
    }
    recipeSurcharges: Array<{
      planSize: number
      amount: number
    }>
  }
  id: string
  servings: number
  sevenDayServings: number
  trialBoxServings: number
}>

type Plan = {
  planId: number
  extraProducts: ExtraProducts[]
  trialLengthInDays: number
  durationInDays: number
  pouchesPerDay: number
  flavours: Flavours
}

type ActivePaymentView = 'credit-card' | 'payment-options' | PaymentMethod

type PaymentMethod =
  | 'creditCard'
  | 'iDeal'
  | 'payPal'
  | 'applePay'
  | 'googlePay'
  | 'bancontact'
  | 'goCardless'
  | 'none'

type PaymentProvider = 'stripe' | 'paypal'

type PaymentIdentifier = 'id' | 'token'

type PaymentType =
  | 'billing_agreement'
  | 'credit_card'
  | 'sepa_direct_debit'
  | 'direct_debit'

type SubmissionState =
  | { type: 'not-requested' }
  | { type: 'loading' }
  | { type: 'error'; error: string }

type PaypalResponse = {
  redirect_url: string
  token: string
}

type FormSection = {
  errorMessage: string | null
  value: string
  inputInteractionState: InputInteractionState
}

type AccountDetailsFormKey = 'name' | 'email' | 'password' | 'visible-password'

type AddressDetailsFormKey =
  | 'searchTerm'
  | 'addressLine1'
  | 'addressLine2'
  | 'city'
  | 'postcode'
  | 'eircode'

type DeliveryDetailsFormKey =
  | 'selectedDeliveryDate'
  | 'mobileNumber'
  | 'deliveryNotes'

type FormSectionKey =
  | AddressDetailsFormKey
  | AccountDetailsFormKey
  | DeliveryDetailsFormKey

type Section = {
  visible: boolean
  valid: boolean
  form: {
    [key: string]: FormSection
  }
}

type DeliveryDetailsForm = FormSection & {
  endOfLeadTime: string
  selectedDeliveryArea: DeliveryArea | null
}

type DeliveryDetailsSection = Section & {
  form: {
    selectedDeliveryDate: DeliveryDetailsForm
    [key: string]: FormSection
  }
}

type PaymentDetailsSection = Omit<Section, 'form'> & {
  form: {
    [key: string]: {
      complete: boolean
      type: PaymentMethod
      preselected: boolean
    }
  }
}

type SectionName = 'account' | 'delivery-address' | 'delivery-date' | 'payment'

type PayPalOption = 'with_paypal' | 'without_paypal'

type CheckoutState = {
  guestId: string | null
  user: User
  plan: Plan
  sections: {
    accountDetails: Section
    addressDetails: Section
    deliveryDetails: DeliveryDetailsSection
    paymentDetails: PaymentDetailsSection
  }
}

const convertActivePaymentViewToPaymentMethod = (
  view: ActivePaymentView
): PaymentMethod => {
  switch (view) {
    case 'credit-card':
      return 'creditCard'
    case 'payment-options':
      return 'none'
    default:
      return view
  }
}

type AuthenticationType = 'credit_card' | 'bancontact' | 'iDeal'

export {
  CheckoutState,
  FormSection,
  AccountDetailsFormKey,
  AddressDetailsFormKey,
  DeliveryDetailsFormKey,
  Dog,
  ExtraProducts,
  SelectedTab,
  SectionName,
  ActivePaymentView,
  SubmissionState,
  PaypalResponse,
  PayPalOption,
  PaymentProvider,
  PaymentIdentifier,
  PaymentType,
  PaymentMethod,
  InputInteractionState,
  FormSectionKey,
  PendingSubscription,
  PendingSubscriptionOrderPricing,
  convertActivePaymentViewToPaymentMethod,
  AuthenticationType
}
