import { gql } from '@apollo/client'

const DOG_PROFILE_PERSONALITY_DETAILS_FRAGMENT = gql`
  fragment DogProfilePersonalityDetailsFragment on Dog {
    id
    ageInMonths
    breed {
      id
      key
      name
    }
    name
    neutered
    gender
    possessivePronoun
    planPortionInGrams
    roundedPlanPortionInCalories(multipleOf: $caloriesMultipleOf)
  }
`

export { DOG_PROFILE_PERSONALITY_DETAILS_FRAGMENT }
