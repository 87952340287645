// @noflow
import classnames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import React, { useContext } from 'react'

import isURL from '@/utils/externalURL'

import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import DiscountBadge from '@/components/elements/atoms/DiscountBadge/DiscountBadge'
import Label from '@/components/elements/atoms/Label/Label'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import { NavigateContext } from '@/components/pages/App'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './MerchandisingCard.module.sass'

type TitleProps = TextProps & {
  text: string
}

type Props = {
  id?: string
  title: TitleProps
  to: string
  image?: string
  alt?: string | null
  discount?: TextProps
  identifier: string
  analyticsOnClick?: (title: string, link: string) => void
  fullWidth?: boolean
}

const MerchandisingCard = ({
  id,
  title,
  image,
  alt,
  to,
  discount,
  identifier,
  analyticsOnClick,
  fullWidth = false
}: Props): JSX.Element => {
  const externalLink = isURL(to) && !to.includes(window.location.hostname)
  const navigate = useContext(NavigateContext)

  const onClickCallback = React.useCallback((): void => {
    if (!isUndefined(analyticsOnClick)) analyticsOnClick(title.text, to)
    if (!externalLink) navigate(to, to)
    else window.open(to, '_blank')
  }, [analyticsOnClick, title.text, to, externalLink, navigate])

  const cardClassName = classnames(STYLES.card, {
    [STYLES.fullWidth]: fullWidth
  })

  return (
    <div id={id} className={cardClassName}>
      <Card
        padding={0}
        onClick={onClickCallback}
        background={false}
        fill
        variant={generateVariant('transparent', 'transparent', 'transparent')}
        identifier={identifier}
      >
        {image && alt && (
          <div className={STYLES.image} data-testid="merch-card-image">
            <CloudinaryImage // TODO: replace with the Image atom
              alt={alt}
              image={{
                path: image,
                width: 320,
                height: 200,
                crop: 'fill',
                dpr: 2
              }}
            />
          </div>
        )}
        <div className={STYLES.copy}>
          {discount && <DiscountBadge discount={'%'} symbolOnly />}
          <Text
            namespace={title.namespace}
            text={title.text}
            variables={title.variables}
            margin={false}
            variant="textRegular16"
            translate={!isUndefined(title.namespace)}
          />
        </div>
        {discount && (
          <div className={STYLES.discount} data-testid="merch-card-discount">
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <Label text={discount} variant="discount" size="small" />
          </div>
        )}
      </Card>
    </div>
  )
}

export { Props }
export default MerchandisingCard
