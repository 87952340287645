// @noflow
import React from 'react'

import type { Locale } from '@/utils/countryCodeHelper'

import useWindowSize from '@/hooks/useWindowSize'

import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text'
import CopyInputButton from '@/components/elements/molecules/CopyInputButton/CopyInputButton'
import ShareModalButton from '@/components/elements/molecules/ShareModalButton/ShareModalButton'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './GiveAndGetCard.module.sass'

import type { Currency } from '@/shared_types/rails_models/shipping_countries'

import useDiscountCopy from '../../../hooks/useDiscountCopy'

type Props = {
  namespace: string
  locale: Locale
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  copyLinkMessage: string
  currency: Currency
}

const GiveAndGetCard = ({
  namespace,
  referralLink,
  locale,
  copyLinkMessage,
  currency
}: Props): JSX.Element => {
  const { discountCode } = useDiscountCopy(referralLink, locale, currency)
  const copyContext = 'refer_a_friend.give_and_get_tab.give_and_get_card'

  const { windowWidth } = useWindowSize()
  const isDesktop = windowWidth >= BREAKPOINTS.lg

  return (
    <div className={STYLES.container}>
      <Card padding={32}>
        <Text
          namespace={namespace}
          text={`${copyContext}.title`}
          variant={`${isDesktop ? 'display36' : 'display24'}`}
          margin={false}
          shouldScale={false}
          element="h2"
        />
        <div className={STYLES.description}>
          <Text
            namespace={namespace}
            text={`${copyContext}.description`}
            margin={false}
            colour="brandBlue400"
          />
        </div>
        <div className={STYLES.shareMethods}>
          <ShareModalButton
            namespace={namespace}
            buttonText={`${copyContext}.cta`}
            partialLocation="Dashboard"
          />
          <CopyInputButton
            copyLinkMessage={copyLinkMessage}
            namespace={namespace}
            referralCode={discountCode}
          />
        </div>
      </Card>
    </div>
  )
}

export default GiveAndGetCard
