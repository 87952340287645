import { gql } from '@apollo/client'

const GUEST_QUERY = gql`
  query SimplifiedGuestQuery {
    currentUser {
      __typename
      ... on Guest {
        id
        email
        numberOfAffiliateFreeBoxes
        preferredLanguage
        assumedShippingCountry {
          id
          code
          showExtras
          trustpilotBusinessUnitId
        }
        dogs {
          id
          name
          gender
          previousFoodTypes {
            foodItem
          }
        }
        discountCode {
          id
          discountCodeParts {
            n
            discountType
            discountBasis
            value
          }
        }
      }
    }
  }
`

export default GUEST_QUERY
