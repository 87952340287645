// @noflow
import { Language } from '@/packs/localisation'
import { format } from 'date-fns'
import React from 'react'

import { Locale, localeToDateLocale } from '@/utils/countryCodeHelper'
import { Currency, formatCurrencyWithDecimal } from '@/utils/currency'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './OngoingBoxTab.module.sass'

import { Code } from '@/types'

type Props = {
  namespace: string
  cadence: number
  pricePerDay: number
  deliveryPrice: number
  secondBoxDiscountCopy?: string
  locale: Locale
  currency: Currency
  showPricingIncreaseAlert: boolean
  effectiveFrom: Date | null
  shippingCountryCode: Code
  preferredLanguage: Language
}

const OngoingBoxTab = ({
  namespace,
  cadence,
  pricePerDay,
  deliveryPrice,
  secondBoxDiscountCopy,
  locale,
  currency,
  showPricingIncreaseAlert,
  effectiveFrom,
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element => {
  const copyContext = 'order_summary.ongoing_box_tab'

  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)

  const dateFormatPattern = ((countryCode: Code): string => {
    switch (countryCode) {
      case 'NL':
      case 'BE':
        return 'd MMMM yyyy'
      default:
        return 'do MMMM yyyy'
    }
  })(shippingCountryCode)

  return (
    <>
      <div className={STYLES.cardContent}>
        <div className={STYLES.summaryWrapper}>
          <Text
            namespace={namespace}
            text={`${copyContext}.title`}
            variables={{
              cadence,
              pricePerDay: formatCurrencyWithDecimal(pricePerDay, {
                locale,
                currency
              }),
              deliveryPrice: formatCurrencyWithDecimal(deliveryPrice, {
                locale,
                currency
              })
            }}
            align="left"
          />
        </div>
        {showPricingIncreaseAlert && effectiveFrom && (
          <div className={STYLES.priceRiseNotice}>
            <AlertCard
              variant="info"
              message={{
                text: 'order_summary.price_increase_info',
                namespace,
                align: 'left',
                variables: {
                  date: format(effectiveFrom, dateFormatPattern, {
                    locale: dateLocale
                  })
                }
              }}
            />
          </div>
        )}
        <div className={STYLES.divider} />
        <div className={STYLES.pauseMessageWrapper}>
          <Text
            variant="textRegular16"
            colour="brandBlue400"
            namespace={namespace}
            text={`${copyContext}.skip_pause_message`}
            align="left"
          />
        </div>
      </div>
      {secondBoxDiscountCopy && (
        <div className={STYLES.footerContent}>
          <div className={STYLES.footerTitle}>
            <Icon asset="checkmark" size={15} accentColour="successGreen300" />
            <Text
              variant="textRegular14"
              namespace={namespace}
              text={`${copyContext}.n2_box_discount`}
              variables={{ secondBoxDiscountCopy }}
              align="left"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default OngoingBoxTab
