// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { HowToFeedModalTrigger } from './components/HowToFeedModalTrigger'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Text from '@/components/elements/atoms/Text/Text'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'
import { DogAvatar } from '@/components/elements/molecules/DogAvatar'

import STYLES from './SubscriptionOverview.module.sass'

import type { SubscriptionOverviewFragment } from './fragments/__generated__/SubscriptionOverviewFragment'
import { SubscriptionStatus } from '@/types'

type Props = {
  loading?: boolean
  dogs?: SubscriptionOverviewFragment['dogs']
  subscription?: SubscriptionOverviewFragment['subscription']
}

const SubscriptionOverview = ({
  dogs,
  subscription,
  loading = false
}: Props): JSX.Element | null => {
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  if (loading) {
    return (
      <section
        className={STYLES.container}
        data-testid="subscription-overview-loading"
      >
        <CardSkeleton height="19rem" />
      </section>
    )
  }

  if (
    subscription?.status !== SubscriptionStatus.active ||
    !dogs ||
    dogs.length === 0
  ) {
    return null
  }

  const openPlanManagement = () => {
    navigate(ACCOUNT_ROUTES.planManagement)
  }

  const commonTextProps = {
    namespace: 'molecules',
    margin: false
  }

  return (
    <section className={STYLES.container} data-testid="subscription-overview">
      <ActionCard
        skeleton={{ isLoading: loading, height: '19rem' }}
        button={{
          text: {
            namespace: 'molecules',
            text: 'subscription_overview.cta',
            translate: true
          },
          onClick: openPlanManagement
        }}
        padding={isMobile ? 16 : 24}
      >
        <div className={STYLES.contentWrapper}>
          {dogs.map((dog) => (
            <div
              key={dog.id}
              className={STYLES.content}
              data-testid="subscription-overview-content"
            >
              <DogAvatar
                variant="dynamic"
                avatarUrl={dog.dogProfile?.avatarUrl}
                ageInMonths={dog.ageInMonths}
                image={{
                  width: 79,
                  height: 79
                }}
              />
              <div className={STYLES.textWrapper}>
                <Text
                  text="subscription_overview.title"
                  variables={{ dogName: upperFirst(dog.name) }}
                  {...commonTextProps}
                />
                <div>
                  <Text
                    text="subscription_overview.amount"
                    variant="display24"
                    variables={{ gramsPerDay: dog.planPortionInGrams }}
                    {...commonTextProps}
                  />
                  <Text
                    text="subscription_overview.description"
                    {...commonTextProps}
                  />
                </div>
              </div>
            </div>
          ))}
          <HowToFeedModalTrigger
            data={{
              firstDogName: upperFirst(dogs[0].name),
              dogsAmount: dogs.length,
              pouchSize: subscription.plan.servingInformation.servingSize,
              pouchesPerDay:
                subscription.plan.servingInformation.pouchesPerServing
            }}
          />
        </div>
      </ActionCard>
    </section>
  )
}

export { SubscriptionOverview }
