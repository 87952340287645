// @noflow
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Locale } from '@/utils/countryCodeHelper'
import { Currency, formatCurrencyWithDecimal } from '@/utils/currency'
import { Product, captureException } from '@/utils/sentry'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import { TopNavBannerDiscountCodeId } from '@/components/elements/molecules/TopNavBanner/TopNavBanner'
import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'

import STYLES from './RedeemDiscount.module.sass'

import { CURRENT_DISCOUNT_CODE } from './queries/currentDiscountCode'
import { ADD_DISCOUNT_TO_GUEST } from './queries/redeemDiscount'

import type {
  CheckoutAddDiscountToGuest as AddDiscountToGuest,
  CheckoutAddDiscountToGuest_addDiscountToGuest_guest_discountCode as AddDiscountToGuestDiscountCode,
  CheckoutAddDiscountToGuestVariables as AddDiscountToGuestVariables
} from './queries/__generated__/CheckoutAddDiscountToGuest'
import type {
  CheckoutCurrentDiscountCode as CurrentDiscountCode,
  CheckoutCurrentDiscountCodeVariables as CurrentDiscountCodeVariables,
  CheckoutCurrentDiscountCode_discountCode as CurrentDiscountCode_discountCode
} from './queries/__generated__/CheckoutCurrentDiscountCode'

type DiscountCode = CurrentDiscountCode_discountCode['code']

type ResponseState = {
  success: boolean
  responseMessage: string
}

type Props = {
  onSuccess?: () => void
  namespace: string
  currency: Currency
  locale: Locale
}

// These error messages are returned by the add_discount_to_guest
// GraphQL mutation and all currently raised errors can be found here:
//
// ButternutBox/app/graphql/mutations/add_discount_to_guest.rb
const handledErrorMessages = [
  'discount_not_found',
  'discount_code_expired',
  'reactivation_campaign_code_attempted',
  'referral_link_type_invalid'
]

const RedeemDiscount = ({
  onSuccess,
  namespace,
  currency,
  locale
}: Props): JSX.Element => {
  const discountCodeCookiesKey = 'discount_code_id'
  const discountCodeId = Cookies.get(discountCodeCookiesKey)

  const copyContext = 'order_summary.redeem_discount'
  const { t } = useTranslation(namespace)

  const [discountCode, setDiscountCode] = useState<DiscountCode | null>(null)
  const [responseState, setResponseState] = useState<ResponseState | null>(null)

  const { user } = useReactiveVar(checkoutPageState)

  const { funnelGiftCampaign } = user

  const handleSetResponseState = ({
    success,
    discountCode
  }: {
    success: boolean
    discountCode?:
      | CurrentDiscountCode_discountCode
      | AddDiscountToGuestDiscountCode
      | null
  }) => {
    if (success && discountCode?.discountCodeParts) {
      const { discountCodeParts } = discountCode || {}

      // We only care about the first discount code part
      const { discountBasis, value } = discountCodeParts[0]

      const formattedValue =
        discountBasis === 'percentage'
          ? value
          : formatCurrencyWithDecimal(value, {
              locale,
              currency
            })

      setResponseState({
        success,
        responseMessage: t(`${copyContext}.optimised_success_message`, {
          value: formattedValue,
          unit: discountBasis === 'percentage' ? '%' : ''
        })
      })
    } else {
      setResponseState({
        success,
        responseMessage: t(`${copyContext}.error_message`)
      })
    }
  }

  // NOTE: Sets the discount code in session storage, to be sent and updated on the BE,
  // and ensure we charge the user what they're seeing on the Checkout page
  const updateSessionDiscountCode = (discountCode?: string) => {
    if (discountCode) {
      window.sessionStorage.setItem('discount_code', discountCode)
    } else {
      window.sessionStorage.removeItem('discount_code')
    }
  }

  // Fetch the current discount code
  const { loading, error } = useQuery<
    CurrentDiscountCode,
    CurrentDiscountCodeVariables
  >(CURRENT_DISCOUNT_CODE, {
    skip: !discountCodeId,
    variables: {
      discountCodeId: discountCodeId || ''
    },
    onCompleted: ({ discountCode }) => {
      setDiscountCode(discountCode.code)
      updateSessionDiscountCode(discountCode.code)
      handleSetResponseState({ success: true, discountCode })
    }
  })

  // Redeem a Discount
  const [addDiscountToGuest, { loading: redeemDiscountLoading }] = useMutation<
    AddDiscountToGuest,
    AddDiscountToGuestVariables
  >(ADD_DISCOUNT_TO_GUEST, {
    variables: {
      discountCode: discountCode || '0'
    },
    refetchQueries: ['CheckoutPendingSubscriptionOrderPricingQuery'],
    onError: (error) => {
      // If Apollo returns an error we haven't accounted for, send it to Sentry
      if (!handledErrorMessages.includes(error.message)) {
        captureException(`Error received in REDEEM_DISCOUNT mutation`, {
          extra: {
            error: error.message
          },
          tags: {
            product: Product.Checkout
          }
        })
      }

      handleSetResponseState({ success: false })

      updateSessionDiscountCode() // Cleares whatever value is on session
    },
    onCompleted: (data) => {
      if (onSuccess) onSuccess()

      // Set the discount code id value for the
      // Top Nav Banner so it can re-query for the new
      // discount information
      TopNavBannerDiscountCodeId(
        data.addDiscountToGuest?.guest.discountCode?.id
      )

      updateSessionDiscountCode(
        data.addDiscountToGuest?.guest.discountCode?.code
      )

      handleSetResponseState({
        success: true,
        discountCode: data.addDiscountToGuest?.guest.discountCode
      })
    }
  })

  const discountCodeChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setResponseState(null)
      if (e.currentTarget.value.length > 0) {
        setDiscountCode(e.currentTarget.value)
      } else {
        setDiscountCode(null)
      }
    },
    []
  )

  const addDiscountToGuestCallback = useCallback(() => {
    if (!discountCode) {
      captureException(
        `Discount code is null and the discount apply button is enabled`,
        {
          tags: {
            product: Product.Checkout
          }
        }
      )
      return
    }

    // Remove all white space from the discount code
    const trimmedDiscountCode = discountCode.trim()

    addDiscountToGuest({
      variables: {
        discountCode: trimmedDiscountCode
      }
    })
  }, [discountCode, addDiscountToGuest])

  if (error) {
    captureException(`Error received in CURRENT_DISCOUNT_CODE query`, {
      extra: {
        error
      },
      tags: {
        product: Product.Checkout
      }
    })
  }

  return (
    <>
      <div className={STYLES.inputWrapper}>
        <input
          defaultValue={discountCode || ''}
          onChange={discountCodeChangeCallback}
          placeholder={t(`${copyContext}.placeholder`)}
          disabled={loading}
        />
        <Button
          typography={{
            namespace,
            text: redeemDiscountLoading
              ? `${copyContext}.cta_loading`
              : `${copyContext}.cta`
          }}
          variant="secondary"
          disableAnalytics
          disabled={redeemDiscountLoading || discountCode === null}
          onClick={addDiscountToGuestCallback}
        />
      </div>
      {responseState && (
        <div className={STYLES.responseWrapper}>
          {responseState.success ? (
            // eslint-disable-next-line jsx-a11y/label-has-for
            <Label
              colour="successGreen100"
              text={{
                variant: 'textRegular14',
                colour: 'supportGreen400',
                text: responseState.responseMessage,
                margin: false,
                translate: false
              }}
              icon={
                <Icon
                  asset="checkmark"
                  size={15}
                  accentColour="successGreen400"
                />
              }
              rowDirection
            />
          ) : (
            <>
              <Icon asset="errorCircle" size={15} accentColour="dangerRed300" />
              <Text
                text={responseState.responseMessage}
                variant="textRegular14"
                colour="supportRed300"
                margin={false}
                translate={false}
              />
            </>
          )}
        </div>
      )}
      {funnelGiftCampaign && (
        <div className={`${STYLES.responseWrapper}`}>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <Label
            colour="successGreen100"
            text={{
              variant: 'textRegular14',
              colour: 'supportGreen400',
              text: `${copyContext}.free_gift_added`,
              variables: {
                productVariantName:
                  funnelGiftCampaign.productVariant.productCollection.name
              },
              namespace,
              margin: false
            }}
            icon={
              <Icon
                asset="checkmark"
                size={15}
                accentColour="successGreen400"
              />
            }
            rowDirection
          />
        </div>
      )}
    </>
  )
}

export default RedeemDiscount
export type { Props }
