import { gql } from '@apollo/client'

const EDIT_DELIVERY_FREQUENCY_FRAGMENT = gql`
  fragment EditDeliveryFrequencyFragment on User {
    id
    availablePlans(scopeType: $scopeType) {
      id
      numberOfPouches
      durationInWeeks
      pricePerDay
      pouchSize
    }
    subscription {
      id
      plan {
        id
        numberOfPouches
        durationInWeeks
        pricePerDay
        pouchSize
      }
    }
  }
`

export { EDIT_DELIVERY_FREQUENCY_FRAGMENT }
