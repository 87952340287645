/* eslint-disable i18next/no-literal-string */
// @noflow

/*  eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import upperFirst from 'lodash/upperFirst'
import React, {
  ElementType,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useCallback
} from 'react'

import { AnalyticsProps, trackEvent } from '@/services/segment'

import { BrandColours } from '@/constants/BrandColours'
import { SupportColours } from '@/constants/SupportColours'

// Components
import Interactive, {
  Props as InteractiveProps
} from '@/components/elements/atoms/Interactive/Interactive'

// Styles
import STYLES from './Card.module.sass'

import { CardSkeleton } from './CardSkeleton'
import type { Props as CardSkeletonProps } from './CardSkeleton'

type Event =
  | MouseEvent<HTMLElement | MouseEvent>
  | KeyboardEvent<HTMLElement | KeyboardEvent>

type Skeleton = {
  isLoading: boolean
} & CardSkeletonProps

type Squared =
  | boolean
  | {
      topLeft?: boolean
      topRight?: boolean
      bottomLeft?: boolean
      bottomRight?: boolean
    }

type CardVariantProp =
  | keyof Pick<
      BrandColours,
      | 'brandYellow100'
      | 'brandYellow200'
      | 'brandYellow300'
      | 'brandBlue100'
      | 'brandPink100'
      | 'brandPink200'
      | 'brandBlue500'
    >
  | keyof Pick<SupportColours, 'successGreen100'>
  | keyof Pick<
      typeof STYLES,
      'tapewhite' | 'tapeblue400' | 'tapepink' | 'tapeyellow'
    >
  | CardVariant

type CardProps = React.HTMLProps<HTMLElement> & {
  padding?: 0 | 8 | 16 | 24 | 32
  border?: 'solid' | 'dashed' | 'none'
  variant?: CardVariantProp | null
  background?: boolean | ReactNode
  shadow?: boolean
  fill?: boolean
  mask?: boolean
  selected?: InteractiveProps['selected']
  element?: InteractiveProps['element']
  children: ReactNode
  className?: string
  skeleton?: Skeleton
  squared?: Squared
  themed?: boolean
  fadedOut?: boolean
  dataTestId?: string
}

type ClickableCardProps = CardProps & {
  onClick?: (event?: Event) => void
} & AnalyticsProps

type NonClickableCardProps = CardProps & {
  onClick?: never
  identifier?: never
  screenIdentifier?: never
  disableAnalytics?: never
}

type Props = ClickableCardProps | NonClickableCardProps

type CardVariant = {
  border:
    | keyof Pick<
        BrandColours,
        | 'brandYellow600'
        | 'brandYellow500'
        | 'brandYellow400'
        | 'brandYellow300'
        | 'brandBlue300'
        | 'brandBlue400'
        | 'brandBlue500'
      >
    | 'transparent'
    | keyof Pick<SupportColours, 'successGreen100'>
  background:
    | keyof Pick<
        BrandColours,
        | 'brandYellow100'
        | 'brandYellow200'
        | 'brandYellow300'
        | 'brandBlue100'
        | 'brandPink200'
        | 'brandBlue200'
        | 'brandBlue500'
      >
    | 'transparent'
    | keyof Pick<SupportColours, 'successGreen100'>
  selected:
    | keyof Pick<
        BrandColours,
        | 'brandYellow200'
        | 'brandYellow300'
        | 'brandYellow400'
        | 'brandPink200'
        | 'brandBlue200'
      >
    | 'transparent'
}

const generateVariant = (
  background: CardVariant['background'] = 'transparent',
  border: CardVariant['border'] = 'transparent',
  selected: CardVariant['selected'] = 'transparent'
): CardVariant => ({
  background,
  border,
  selected
})

const generateVariantStyles = (
  variant: Props['variant'] | CardVariant | undefined
): string => {
  if (isObject(variant)) {
    const background = `background${upperFirst(
      variant.background
    )}` as keyof typeof STYLES
    const border = `border${upperFirst(variant.border)}` as keyof typeof STYLES
    const selected = `selected${upperFirst(
      variant.selected
    )}` as keyof typeof STYLES
    return `
    ${STYLES[background]}
    ${STYLES[border]}
    ${STYLES[selected]}
  `
  }
  if (isString(variant)) return STYLES[variant]
  return ''
}

const generateSquaredClassname = (squared: Squared) => {
  if (squared === true) {
    return STYLES.squaredFull
  } else {
    return (Object.keys(squared) as Array<keyof Squared>)
      .map((key) => `${STYLES.squared} ${STYLES[key]}`)
      .join(' ')
  }
}

/**
 * The Card component.
 * NOTE: this is the new single page app card component which will replace our older card
 * @param onClick
 * @param padding
 * @param background - should the card have a background
 * @param border
 * @param variant - controls the color theme of the card (background / border / selected)
 * @param shadow
 * @param mask - set overflow to hidden so content does not display outside of card
 * @param fill - fill the parent container
 * @param selected - toggle cards selected state
 * @param element - the outer element for the card to use
 * @param children
 * @param className - additional class names
 * @param fadedOut - overlay with opacity to navigate focus elsewhere
 * @constructor
 */
const Card = ({
  onClick,
  padding = 16,
  border = 'none',
  background = true,
  variant,
  mask,
  shadow,
  selected,
  fill = true,
  element = 'div',
  className,
  children,
  skeleton,
  squared = false,
  themed = false,
  fadedOut = false,
  identifier,
  screenIdentifier,
  disableAnalytics,
  dataTestId,
  ...htmlAttributes
}: Props): JSX.Element => {
  const hasClickEvent = !isUndefined(onClick)

  const handleTrackEvent = useCallback((): void => {
    if (disableAnalytics || !identifier) return
    trackEvent('Component Clicked', {
      component_identifier: identifier,
      ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
    })
  }, [disableAnalytics, identifier, screenIdentifier])

  const onCardClick = useCallback(
    (event) => {
      if (isUndefined(onClick)) return

      handleTrackEvent()

      onClick(event)
    },
    [handleTrackEvent, onClick]
  )

  const Element = hasClickEvent ? Interactive : (element as ElementType)

  if (skeleton?.isLoading) {
    return <CardSkeleton {...skeleton} />
  }

  const classes = classNames(className, {
    [STYLES.card]: true,
    [generateVariantStyles(variant)]: variant,
    [STYLES.transparent]: !background,
    [STYLES[`padding${padding}`]]: padding,
    [STYLES.shadow]: shadow,
    [STYLES.mask]: mask,
    [STYLES[border]]: border,
    [STYLES.selected]: selected,
    [STYLES.fill]: fill,
    [STYLES.interactable]: !themed && onClick,
    [generateSquaredClassname(squared)]: squared,
    [STYLES.fadedOut]: fadedOut
  })

  return (
    <Element
      {...htmlAttributes}
      onClick={hasClickEvent ? onCardClick : undefined}
      selected={selected}
      className={classes}
      data-testid={dataTestId}
    >
      {React.isValidElement(background) && (
        <div className={STYLES.background}>{background}</div>
      )}
      {children}
    </Element>
  )
}

export {
  Props,
  CardProps,
  ClickableCardProps,
  NonClickableCardProps,
  CardVariantProp,
  generateVariant,
  Squared
}
export default Card
