// @noflow
import React from 'react'

import { FileUpload } from '@/hooks/useFileUpload/useFileUpload'

import Camera from 'assets/images/icons/camera--drawn.svg'

import Interactive from '@/components/elements/atoms/Interactive/Interactive'

import STYLES from './CameraAction.module.sass'

import { CameraActionSkeleton } from './CameraActionSkeleton'

type BgColour = 'brandBlue600' | 'brandBlue400'

type Props = {
  bgColour?: BgColour
  loading?: boolean
  size?: 'small' | 'medium'
  fileUploadRef: FileUpload['fileInputRef'] | undefined
  onFileUploadChange: FileUpload['handleFileSelect']
  onFileUploadClick: FileUpload['handleElementClick']
  dataTestId?: string
}

const CameraAction = ({
  bgColour = 'brandBlue600',
  loading = false,
  size = 'medium',
  fileUploadRef,
  onFileUploadChange,
  onFileUploadClick,
  dataTestId
}: Props): JSX.Element => {
  if (loading) {
    return <CameraActionSkeleton />
  }

  return (
    <Interactive
      element="button"
      onClick={onFileUploadClick}
      className={`${STYLES.wrapper} ${STYLES[bgColour]} ${STYLES[size]}`}
    >
      <input
        type="file"
        ref={fileUploadRef}
        onChange={onFileUploadChange}
        className={STYLES.fileInput}
        data-testid={dataTestId}
      />
      <img src={Camera} alt="Camera" />
    </Interactive>
  )
}

export { CameraAction }
