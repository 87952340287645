/* eslint-disable jsx-a11y/label-has-for */
// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Utils
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

// Components
import RedeemDiscount from './components/RedeemDiscount/RedeemDiscount'
import TabbedSummary from './components/TabbedSummary/TabbedSummary'
import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'

// Styles
import STYLES from './OrderSummary.module.sass'

import { orderSummaryOpened } from '../../Analytics/CheckoutAnalytics'
import { checkoutPricingState } from '../../hooks/useCheckoutPricing'
import useDiscountCopy from '../../hooks/useDiscountCopy'

type Props = {
  namespace: string
  shouldDisplayPrice: boolean
}

const OrderSummary = ({
  namespace,
  shouldDisplayPrice
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false)
  const copyContext = 'order_summary'
  const { t } = useTranslation(namespace)

  const { firstOrderPricing, priceIncrease } =
    useReactiveVar(checkoutPricingState)
  const { user } = useReactiveVar(checkoutPageState)

  const { shippingCountryCode, preferredLanguage } = user

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )
  const { discountCopyParts, fetchDiscountCopy } = useDiscountCopy(
    locale,
    currency
  )

  const toggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  const redeemDiscountCallback = useCallback(() => {
    const delay = 3000

    modalData({
      isOpen: true,
      // eslint-disable-next-line i18next/no-literal-string
      text: `${copyContext}.redeem_discount.success_modal_message`,
      namespace,
      delay
    })
    fetchDiscountCopy()
  }, [namespace, fetchDiscountCopy])

  useEffect(() => {
    if (open) {
      orderSummaryOpened()
    }
  }, [open])

  const { netTotalPrice, totalSavedAmount } = firstOrderPricing

  const savingsApplied = totalSavedAmount > 0

  const price = shouldDisplayPrice ? netTotalPrice : 0

  return (
    <>
      <Card shadow padding={0} mask>
        <div className={STYLES.title}>
          <div className={STYLES.imageWrapper}>
            <Image
              className={STYLES.image}
              slug="spaniel-watching-butternut-box-with-meals-and-meal-bowl-on-counter"
              alt={t(`${copyContext}.img_alt`)}
              image={{
                width: 64,
                height: 64,
                resizeMode: 'resize_to_fill',
                quality: 'auto:best',
                retina: true
              }}
            />
          </div>
          <div className={STYLES.titleText}>
            <Text
              text={`${copyContext}.title`}
              variant="display20"
              element="h2"
              namespace={namespace}
              margin={false}
              shouldScale
              align="left"
            />
            <div className={STYLES.totalPrice}>
              <Text
                text={formatCurrencyWithDecimal(price, {
                  locale,
                  currency
                })}
                namespace={namespace}
                margin={false}
                align="left"
                translate={false}
                colour={savingsApplied ? 'brandRed500' : 'brandBlue500'}
                variant="textRegular18"
                bold={savingsApplied}
              />
              {savingsApplied && (
                <Text
                  text={`<accent type='strikeThrough'>${formatCurrencyWithDecimal(
                    netTotalPrice + totalSavedAmount,
                    {
                      locale,
                      currency
                    }
                  )}</accent>`}
                  namespace={namespace}
                  margin={false}
                  align="left"
                  translate={false}
                  variant="textRegular12"
                />
              )}
            </div>
          </div>
          <button
            type="button"
            aria-label={t(`${copyContext}.toggle_alt`)}
            className={STYLES.toggle}
            onClick={toggle}
          >
            <div className={`${STYLES.arrow} ${open ? STYLES.arrowOpen : ''}`}>
              <Icon asset="chevron" width="100%" size={12} />
            </div>
          </button>
        </div>

        <div className={STYLES.redeemDiscountWrapper}>
          <RedeemDiscount
            onSuccess={redeemDiscountCallback}
            namespace={namespace}
            currency={currency}
            locale={locale}
          />
        </div>
        <div id="expandable">
          <Expand
            origin="top"
            show={open}
            // Set to a large enough number to contain an order summary with 7 recipes and 5 extras
            maxHeight={2000}
            config={{
              precision: 0.0001,
              friction: 25
            }}
          >
            <TabbedSummary
              namespace={namespace}
              currency={currency}
              locale={locale}
              secondBoxDiscountCopy={
                discountCopyParts.find((part) => part.n === 2)?.copy
              }
              showPricingIncreaseAlert={priceIncrease.showPriceIncreaseAlert}
              effectiveFrom={priceIncrease.effectiveFrom}
              shouldDisplayPrice={shouldDisplayPrice}
            />
          </Expand>
        </div>
      </Card>
      <ConfirmationModal />
    </>
  )
}

export default OrderSummary
