import { gql } from '@apollo/client'

const SELECTABLE_DOG_AVATAR_FRAGMENT = gql`
  fragment SelectableDogAvatarFragment on Dog {
    id
    name
    breed {
      id
      name
      key
    }
    ageInMonths
    dogProfile {
      id
      avatarUrl
    }
  }
`

export { SELECTABLE_DOG_AVATAR_FRAGMENT }
