/* eslint-disable i18next/no-literal-string */
// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useState } from 'react'

import { Locale } from '@/utils/countryCodeHelper'
import { Currency } from '@/utils/currency'

import Interactive from '@/components/elements/atoms/Interactive/Interactive'
import Text from '@/components/elements/atoms/Text/Text'
import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'

import STYLES from './TabbedSummary.module.sass'

import { checkoutPricingState } from '../../../../hooks/useCheckoutPricing'
import OngoingBoxTab from '../OngoingBoxTab/OngoingBoxTab'
import OrderSummaryTab from '../OrderSummaryTab/OrderSummaryTab'

type Props = {
  namespace: string
  secondBoxDiscountCopy?: string
  locale: Locale
  currency: Currency
  showPricingIncreaseAlert: boolean
  effectiveFrom: Date | null
  shouldDisplayPrice: boolean
}

enum Tabs {
  OrderSummaryTab = 'order_summary_tab',
  OngoingBoxTab = 'ongoing_box_tab'
}

type Tab = {
  label: string
  value: Tabs
}

const TabbedSummary = ({
  namespace,
  secondBoxDiscountCopy,
  locale,
  currency,
  showPricingIncreaseAlert,
  effectiveFrom,
  shouldDisplayPrice
}: Props): JSX.Element => {
  const { plan } = useReactiveVar(checkoutPageState)
  const { standardOrderPricing } = useReactiveVar(checkoutPricingState)
  const { user } = useReactiveVar(checkoutPageState)

  const { shippingCountryCode, preferredLanguage } = user

  const SummaryTabs: Array<Tab> = [
    { label: `${Tabs.OrderSummaryTab}.title`, value: Tabs.OrderSummaryTab },
    { label: `${Tabs.OngoingBoxTab}.title`, value: Tabs.OngoingBoxTab }
  ]
  const [selectedTab, setSelectedTab] = useState(Tabs.OrderSummaryTab)
  const copyContext = 'order_summary'

  const handleClick = useCallback((value: Tabs) => {
    setSelectedTab(value)
  }, [])

  return (
    <div>
      <div className={STYLES.tabsContainer}>
        <div className={STYLES.tabsWrapper}>
          {SummaryTabs.map((tab) => (
            <Interactive
              key={tab.label}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleClick(tab.value)}
              element="button"
              className={`${STYLES.tab} ${
                selectedTab === tab.value && STYLES.selected
              }`}
              selected={selectedTab === tab.value}
            >
              <Text
                namespace={namespace}
                text={`${copyContext}.${tab.value}.tab_title`}
                variant="textRegular20"
                colour={
                  selectedTab === tab.value ? 'brandBlue500' : 'brandBlue400'
                }
                margin={false}
              />
            </Interactive>
          ))}
        </div>
      </div>
      {selectedTab === Tabs.OrderSummaryTab && (
        <OrderSummaryTab
          namespace={namespace}
          shippingCountryCode={shippingCountryCode}
          preferredLanguage={preferredLanguage}
          shouldDisplayPrice={shouldDisplayPrice}
        />
      )}
      {selectedTab === Tabs.OngoingBoxTab && (
        <OngoingBoxTab
          namespace={namespace}
          cadence={plan.durationInDays}
          currency={currency}
          locale={locale}
          secondBoxDiscountCopy={secondBoxDiscountCopy}
          showPricingIncreaseAlert={showPricingIncreaseAlert}
          shippingCountryCode={shippingCountryCode}
          preferredLanguage={preferredLanguage}
          effectiveFrom={effectiveFrom}
          deliveryPrice={standardOrderPricing.netDeliverySurchargePrice}
          pricePerDay={standardOrderPricing.netTotalPricePerDay}
        />
      )}
    </div>
  )
}

export default TabbedSummary
