// @noflow
import { gql } from '@apollo/client'

const DASHBOARD_QUERY = gql`
  query DashboardQuery {
    user {
      id
      shippingCountry {
        id
        code
        currency
      }
      preferredLanguage
      subscription {
        id
        previewUncreatedFutureBox {
          discountValue
        }
        nextEditableBox {
          id
          price
          discountValue
        }
      }
    }
  }
`

export { DASHBOARD_QUERY }
