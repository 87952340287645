// @noflow
import { useLanguage } from '@/context/injectedValues/injectedValues'
import { makeVar, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import { RESUME_SUBSCRIPTION } from '@/constants/RibbonEvents'

import useInAccountRaf from '@/hooks/useInAccountRaf/useInAccountRaf'
import { useMTFITB } from '@/hooks/useMFITB'
import useRAFDiscountReminder from '@/hooks/useRAFDiscountReminder'
import useReactivationNotification from '@/hooks/useReactivationNotification'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import RafCampaignModal from '@/components/elements/molecules/RafCampaignModal/RafCampaignModal'
import RafDiscountReminderModal from '@/components/elements/molecules/RafDiscountReminderModal/RafDiscountReminderModal'
import UpcomingBoxes from '@/components/pages/Dashboard/components/upcomingBoxes/UpcomingBoxes'

import { DASHBOARD_QUERY } from './queries/dashboardQuery'

enum DASHBOARD_PAGE_SECTIONS {
  upcomingBoxes = 'upcoming-boxes'
}

const upcomingBoxesLoaded = makeVar(false)

const namespace = 'account'

const DashboardPage = (): JSX.Element => {
  const [showRafModal, setShowRafModal] = useState(true)
  const { userLanguage } = useLanguage()

  const { data } = useQuery(DASHBOARD_QUERY)

  useReactivationNotification()
  useMTFITB()

  const { eligibleForRafCampaign, loading: rafDataLoading } = useInAccountRaf(
    'Valentines-Campaign-BNLX-2025'
  )

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const reactivation = urlParams.get('show_reactivation_confirmation')

    const reactivated = reactivation === 'true'

    if (reactivated) {
      window.ribbon && window.ribbon('trigger', RESUME_SUBSCRIPTION)
      history.replaceState(null, '', window.location.pathname)
    }
  })

  const { formattedDiscount, shouldSeeRafDiscountModal } =
    useRAFDiscountReminder(data)

  return (
    <>
      <SectionWrapper
        id={DASHBOARD_PAGE_SECTIONS.upcomingBoxes}
        headerTypography={{
          text: 'home.upcoming_boxes.title',
          namespace
        }}
      >
        <UpcomingBoxes />
      </SectionWrapper>
      {!rafDataLoading && eligibleForRafCampaign ? (
        <RafCampaignModal
          isModalOpen={showRafModal}
          setOpenModal={setShowRafModal}
          preferredLanguage={userLanguage}
        />
      ) : null}

      {shouldSeeRafDiscountModal && (
        <RafDiscountReminderModal discount={formattedDiscount} />
      )}
    </>
  )
}

export { DASHBOARD_PAGE_SECTIONS, upcomingBoxesLoaded }
export default DashboardPage
