import {
  CsrfTokenContext,
  TokenContext
} from '@/context/injectedValues/injectedValues'
import thankYouConfetti from '@/lottie/thank-you-page-v3.json'
import initI18n, { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import Handlebars from 'handlebars'
import Lottie from 'lottie-react'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { toLocalisedSentence } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import { THANK_YOU_PAGE } from '@/constants/RibbonEvents'

import useWindowSize from '@/hooks/useWindowSize'

import AppOverlay from './components/AppOverlay/AppOverlay'
import { SubscriptionConfirmation } from './components/SupscriptionConfirmation'
import withApollo from '@/components/apollo/withApollo'
import { Button } from '@/components/elements/atoms/Button'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
import { BREAKPOINTS } from '@/components/pages/App/App'

import STYLES from './ThankYouPageV3.module.sass'

import { thankYouQueryV3_user_dogs as Dog } from './__generated__/thankYouQueryV3'

import { Discounts, DogProfile } from './components'
import { THANK_YOU_QUERY_V3 } from './queries'

type Props = {
  variant: Language
  csrfToken: string
  token: string
}

const ThankYouPageV3 = ({
  variant,
  csrfToken,
  token
}: Props): JSX.Element | null => {
  initI18n(variant)
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.sm
  const [showAppOverlay, setShowAppOverlay] = useState(false)
  const [showSubscriptionConfirmation, setShowSubscriptionConfirmation] =
    useState(true)
  const [userCsrftoken, setUserCsrftoken] = useState<string | undefined>(
    undefined
  )
  const [userToken, setUserToken] = useState<string | undefined>(undefined)

  const { loading, data, error, refetch } = useQuery(THANK_YOU_QUERY_V3, {
    variables: {
      headerSlug: 'header',
      whatsappSingleDogSlug: 'whatsapp_single_dog',
      whatsappMultipleDogsSlug: 'whatsapp_multiple_dogs'
    }
  })

  const handleDogProfileUploaded = useCallback(() => {
    refetch()
  }, [refetch])

  const csrfTokenProviderValues = useMemo(
    () => ({ csrfToken: userCsrftoken, setCsrfToken: setUserCsrftoken }),
    [userCsrftoken]
  )

  const tokenProviderValues = useMemo(
    () => ({ token: userToken, setToken: setUserToken }),
    [userToken]
  )

  useEffect(() => setUserCsrftoken(csrfToken), [csrfToken])
  useEffect(() => setUserToken(token), [token])

  useEffect(() => {
    const localStorageKeys = [
      'wizardState',
      'checkout_state',
      'simplifiedPendingSubscription',
      'simplified_selected_plan_details'
    ]

    const sessionStorageKeys = ['gclid']

    localStorageKeys.forEach((key) => {
      window.localStorage.removeItem(key)
    })

    sessionStorageKeys.forEach((key) => {
      window.sessionStorage.removeItem(key)
    })

    if (window.ribbon) window.ribbon('trigger', THANK_YOU_PAGE)
  }, [])

  const handleCloseSubscriptionConfirm = useCallback(() => {
    setShowSubscriptionConfirmation(false)
  }, [])

  const handleGoToAccount = useCallback(() => {
    if (data && data.systemShouldShowAppContent === 'true') {
      setShowAppOverlay(true)
    } else {
      window.location.href = '/account'
      setTimeout(() => setShowAppOverlay(false), 750)
    }
  }, [data])

  if (error) {
    Sentry.captureException('Error in ThankYouPageV3', {
      extra: error,
      tags: {
        product: Sentry.Product.Checkout,
        team: Sentry.Team.Retention
      }
    })
  }

  if (loading || !data || error) return null

  const { whatsAppSingleDog, whatsAppMultipleDogs, user } = data || {}
  const { preferredLanguage, shippingCountryCode, subscription } = user || {}
  const { referralLink = {} } = subscription || {}

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode || 'GB',
    preferredLanguage || 'en'
  )

  const dogNames = user?.dogs?.map((dog: Dog) => dog.name) || []

  const joinedDogNames = toLocalisedSentence({
    arr: dogNames,
    lng: preferredLanguage,
    oxfordComma: true
  })

  const copyLinkMessageNative = Handlebars.compile(
    dogNames.length === 1 ? whatsAppSingleDog.text : whatsAppMultipleDogs.text,
    { noEscape: true }
  )({
    dogNames: joinedDogNames,
    // eslint-disable-next-line i18next/no-literal-string
    referralLink: `${referralLink.url}?share_source=nativeweb`
  })

  const names = user.dogs.map((dog: Dog) => dog.name)

  if (showSubscriptionConfirmation) {
    return (
      <SubscriptionConfirmation
        handleSuccessClose={handleCloseSubscriptionConfirm}
      />
    )
  }

  if (showAppOverlay) {
    return (
      <AppOverlay
        shippingCode={shippingCountryCode}
        setShowAppOverlay={setShowAppOverlay}
      />
    )
  }

  return (
    <CsrfTokenContext.Provider value={csrfTokenProviderValues}>
      <TokenContext.Provider value={tokenProviderValues}>
        <Fragment>
          <div className={STYLES.confetti}>
            <Lottie animationData={thankYouConfetti} loop={false} />
          </div>

          <SectionWrapper
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            bgColour="brandYellow200"
          >
            <SectionWrapper
              bgColour="brandYellow200"
              className={STYLES.header}
              margin={{ top: 0, bottom: isMobile ? 8 : 24 }}
            >
              <Text
                margin={false}
                dataTestId="thank-you-title"
                namespace="account"
                text="thank_you_page_v3.welcome_title"
                variant="display28"
                align="center"
              />
            </SectionWrapper>

            <div className={STYLES.page}>
              <div className={STYLES.subtitle}>
                <Text
                  text="thank_you_page_v3.welcome_subtitle.line1"
                  namespace="account"
                  margin={false}
                  align="center"
                  dataTestId="thank-you-subtitle1"
                  variables={{
                    dogNames: toLocalisedSentence({
                      arr: names,
                      lng: preferredLanguage
                    })
                  }}
                />
                <Text
                  text="thank_you_page_v3.welcome_subtitle.line2"
                  namespace="account"
                  margin={false}
                  align="center"
                  dataTestId="thank-you-subtitle2"
                  variables={{
                    dogNames: toLocalisedSentence({
                      arr: names,
                      lng: preferredLanguage
                    })
                  }}
                />
              </div>
              <DogProfile
                dogs={user?.dogs}
                handleUpload={handleDogProfileUploaded}
              />
              <Discounts
                referralLink={referralLink}
                locale={locale}
                currency={currency}
                copyLinkMessageNative={copyLinkMessageNative}
              />
              <div className={STYLES.buttonWrapper}>
                <Button
                  dataTestId="account-button"
                  onClick={handleGoToAccount}
                  identifier="thank_you.sticky.go_to_account"
                  variant="secondary"
                  typography={{
                    text: 'thank_you_page_v3.continue_cta',
                    namespace: 'account'
                  }}
                />
              </div>
            </div>
          </SectionWrapper>
        </Fragment>
      </TokenContext.Provider>
    </CsrfTokenContext.Provider>
  )
}

export default withApollo(ThankYouPageV3)
