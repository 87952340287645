// @noflow
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// Component
import segmentTrack from '@/components/analytics/Analytics'
import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import { NavigateContext } from '@/components/pages/App'

import STYLES from './AddMore.module.sass'

type Props = {
  link: string
  hasExtras?: boolean
}

const AddMore = ({ link, hasExtras = false }: Props): JSX.Element | null => {
  const navigate = useContext(NavigateContext)
  const { t } = useTranslation('shared')

  const spaceForMoreClicked = React.useCallback((): void => {
    segmentTrack('Order Details Page - Space for more items area clicked', {
      link
    })
    navigate(link, link)
  }, [link, navigate])

  if (hasExtras) return null

  return (
    <Card
      border="dashed"
      variant={generateVariant('transparent', 'brandBlue500')}
      onClick={spaceForMoreClicked}
      identifier="box_breakdown.see_extras"
      id="edit-extras-tour-target"
    >
      <div className={STYLES.content}>
        <div>
          <Image
            slug="extras-recipe-retriever"
            image={{ width: 117, height: 117 }}
            alt={t('box_breakdown.boost_upsell_image_alt')}
          />
        </div>

        <div>
          <Text
            variant="display16"
            colour="brandBlue500"
            namespace="shared"
            text="box_breakdown.see_extras"
          />
          <Text
            variant="textRegular16"
            colour="brandBlue400"
            namespace="shared"
            text="box_breakdown.space_for_more"
          />
        </div>
      </div>
    </Card>
  )
}

export { Props }
export default AddMore
