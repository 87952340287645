// @noflow

/* eslint-disable i18next/no-literal-string */
import { ACCOUNT_ROUTES } from '@/routes'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Image from '@/components/elements/atoms/Image/Image'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'

import STYLES from './WeightAndAppetite.module.sass'

import { DogProfileWeightAndAppetiteFragment as Dog } from '../../fragments/__generated__/DogProfileWeightAndAppetiteFragment'

import { DetailsRowSection } from '../DetailsRowSection'

type Props = {
  dog?: Dog
  loading?: boolean
}

const WeightAndAppetite = ({ dog, loading = false }: Props): JSX.Element => {
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation('account')

  const isMobile = windowWidth < BREAKPOINTS.md
  const copyContext = 'dog_profile.weight_and_appetite'

  const dogWeightInKg = (dog?.weightInGrams ?? 0) / 1000

  const weightSections = [
    {
      id: 'weight',
      title: {
        text: `${copyContext}.weight.title`
      },
      content: {
        text: `${copyContext}.weight.content`,
        variables: { weight: dogWeightInKg }
      }
    },
    {
      id: 'body_condition',
      title: {
        text: `${copyContext}.body_condition.title`
      },
      content: {
        text: `${copyContext}.body_condition.content`,
        variables: { context: dog?.bodyShape ?? '' }
      }
    },
    {
      id: 'activity_level',
      title: {
        text: `${copyContext}.activity_level.title`
      },
      content: {
        text: `${copyContext}.activity_level.content`,
        variables: { context: dog?.exercise ?? '' }
      }
    }
  ]

  const appetiteSections = useMemo(() => {
    if (!dog?.eaterType && !dog?.snackingHabits) {
      return []
    }

    const contentParts = []

    if (dog.eaterType) {
      contentParts.push(
        t(`${copyContext}.eating_habits_and_treats.content_habits`, {
          context: dog.eaterType
        })
      )
    }

    if (
      dog.snackingHabits &&
      dog.snackingHabits !== 'unknown_snacking_habits'
    ) {
      contentParts.push(
        t(`${copyContext}.eating_habits_and_treats.content_treats`, {
          context: dog.snackingHabits
        })
      )
    }

    if (contentParts.length === 0) {
      return []
    }

    return [
      {
        id: 'eating_habits_and_treats',
        title: {
          text: `${copyContext}.eating_habits_and_treats.title`
        },
        content: {
          text: contentParts.join(' | '),
          translate: false
        }
      }
    ]
  }, [dog?.eaterType, dog?.snackingHabits, t])

  const navigateToWeightAndAppetite = useCallback(() => {
    navigate(
      generatePath(ACCOUNT_ROUTES.dogWeightAndAppetite, {
        dogId: dog?.id ?? ''
      })
    )
  }, [dog?.id, navigate])

  return (
    <ActionCard
      shadow
      padding={isMobile ? 24 : 32}
      heading={{
        text: `${copyContext}.title`,
        namespace: 'account'
      }}
      button={{
        text: {
          text: 'dog_profile.edit_cta',
          namespace: 'account'
        },
        onClick: navigateToWeightAndAppetite
      }}
      skeleton={{
        isLoading: loading,
        height: 300
      }}
      fill={false}
      className={STYLES.wrapper}
    >
      <div className={STYLES.contentWrapper}>
        <div className={STYLES.content}>
          {weightSections.map(({ id, ...weightSection }) => (
            <DetailsRowSection
              key={id}
              margin={{ top: 0, bottom: 0 }}
              {...weightSection}
            />
          ))}
        </div>
        <div className={STYLES.imageWrapper}>
          <Image
            alt={t(`${copyContext}.image_alt`)}
            slug="dog-profile-weight-and-appetite"
            image={{
              width: isMobile ? 110 : 125,
              height: isMobile ? 110 : 125
            }}
          />
        </div>
      </div>
      {appetiteSections.map(({ id, ...appetiteSection }) => (
        <DetailsRowSection
          key={id}
          margin={{ top: 16, bottom: 0 }}
          {...appetiteSection}
        />
      ))}
    </ActionCard>
  )
}

export { WeightAndAppetite }
