// @noflow
import { Language } from '@/packs/localisation'
import { useQuery, useReactiveVar } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Cookies from 'js-cookie'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import times from 'lodash/times'
import React, { useCallback, useEffect } from 'react'

import * as DiscountCodes from '@/utils/butternutbox/discountCodes'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

// Breakpoints
import BREAKPOINTS from '@/constants/Breakpoints'

// Hooks
import useWindowSize from '@/hooks/useWindowSize'

// Components
import Text from '@/components/elements/atoms/Text'
import ProductUpsellableCard from '@/components/elements/molecules/ProductUpsellableCard/ProductUpsellableCard'
import ProductUpsellableCardSkeleton from '@/components/elements/molecules/ProductUpsellableCard/ProductUpsellableCardSkeleton'
import AdditionalProductInformationModal, {
  modalData as additionalProductModalData
} from '@/components/elements/organisms/AdditionalProductModal/AdditionalProductModal'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'
import {
  SelectedProductVariant,
  deliveryCadenceState,
  plansPageAnalyticState,
  plansPageState,
  plansPageWizardState
} from '@/components/pages/SimplifiedPlansPage/SimplifiedPlansPage'

// Styles
import STYLES from './extras.module.sass'

// Queries
import EXTRAS_SCREEN_QUERY from '../../queries/ExtrasScreenQuery'
import FUNNEl_GIFT_CAMPAIGN_QUERY from '../../queries/FunnelGiftCampaignQuery'
import GUEST_QUERY from '../../queries/GuestQuery'

import { ExtrasScreenQuery } from '../../queries/__generated__/ExtrasScreenQuery'
import type { ExtrasScreenQuery_guest_planRecommendation_recommendedExtraProductVariants as ExtraProductVariant } from '../../queries/__generated__/ExtrasScreenQuery'
import { FunnelGiftCampaignQuery } from '../../queries/__generated__/FunnelGiftCampaignQuery'
import { SimplifiedGuestQuery } from '../../queries/__generated__/SimplifiedGuestQuery'
import type {
  SimplifiedGuestQuery_currentUser_Guest_discountCode as DiscountCode,
  SimplifiedGuestQuery_currentUser_Guest_discountCode_discountCodeParts as DiscountCodePart
} from '../../queries/__generated__/SimplifiedGuestQuery'
import { Code as CountryCode } from '@/types/index'

import type { PlanState } from '../../SimplifiedPlansPage'
import {
  trackAdditionalProductMoreInfoClicked,
  trackExtrasProductAdded,
  trackExtrasProductRemoved,
  trackExtrasSeen
} from '../../helpers/analytics'
import {
  currentRouteToCurrentStep,
  currentRouteToPercentFilled,
  currentRouteToTotalStep
} from '../../helpers/plansNavigation'
import useCreatePendingSubscription from '../../hooks/useCreatePendingSubscription'
import { FlowType } from '../../types/analyticsProperties'
import { Routes as PlansRoutes, Routes } from '../../types/routes'

type Props = {
  namespace: string
  shouldSeePetParentOnPlans: boolean
  shouldSeeSkipPlanPage: boolean
}

type ExtraCardProps = {
  preferredLanguage: Language
  shippingCountryCode: CountryCode
  discountCode: DiscountCode | null
  extraProduct: ExtraProductVariant
  productVariants: Array<SelectedProductVariant>
  setSelectedExtras: ({
    productVariant,
    productName,
    productCollectionSlug
  }: {
    productVariant: SelectedProductVariant
    productName: ExtraProductVariant['name']
    productCollectionSlug: ExtraProductVariant['productCollection']['slug']
  }) => void
  removeSelectedExtras: ({
    productVariant,
    productName,
    productCollectionSlug
  }: {
    productVariant: SelectedProductVariant
    productName: ExtraProductVariant['name']
    productCollectionSlug: ExtraProductVariant['productCollection']['slug']
  }) => void
  readMoreOnClick?: () => void
  isFreeGift?: boolean
}

const FUNNEl_GIFT_CAMPAIGN_COOKIE_KEY = 'funnel_gift_campaign'

const ExtraCard = ({
  preferredLanguage,
  shippingCountryCode,
  discountCode,
  extraProduct,
  productVariants,
  setSelectedExtras,
  removeSelectedExtras,
  readMoreOnClick,
  isFreeGift = false
}: ExtraCardProps): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md
  const { recurringDeliveryType, productCollection, name, id } = extraProduct

  const productUpsellableOnClick = useCallback(
    (
      id: string,
      discountedPrice: number,
      adjustedPrice: number,
      selected?: SelectedProductVariant,
      firstBoxDiscountPart?: DiscountCodePart
    ) => {
      const extraPrice = firstBoxDiscountPart ? discountedPrice : adjustedPrice

      if (selected) {
        removeSelectedExtras({
          productVariant: { id, discountedPrice: extraPrice },
          productName: productCollection.name,
          productCollectionSlug: productCollection.slug
        })
      } else {
        setSelectedExtras({
          productVariant: { id, discountedPrice: extraPrice },
          productName: productCollection.name,
          productCollectionSlug: productCollection.slug
        })
      }
    },
    [productCollection, removeSelectedExtras, setSelectedExtras]
  )

  if (isUndefined(extraProduct.recurringDeliveryType)) {
    Sentry.captureException(
      `Extra Product ID recurringDeliveryType is not defined`,
      {
        extra: {
          extraProductId: extraProduct.id
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
    return null
  }

  const adjustedPrice = recurringDeliveryType
    ? recurringDeliveryType.netPrice
    : extraProduct.grossPrice

  const firstBoxDiscountPart = discountCode?.discountCodeParts.find((part) =>
    DiscountCodes.partAppliesToBoxNumber(part, 1)
  )
  const discountedPrice = firstBoxDiscountPart
    ? DiscountCodes.discountedPriceWithPart(firstBoxDiscountPart, adjustedPrice)
    : 0
  const selected = productVariants?.find((productVariant) => {
    return productVariant.id === id
  })

  return (
    <ProductUpsellableCard
      dataTestId="extra-product-card"
      variant="addAndRemove"
      name={name}
      productName={productCollection.name}
      preferredLanguage={preferredLanguage}
      shippingCountryCode={shippingCountryCode}
      src={productCollection?.merchandisingImage?.src || ''}
      orientation={isMobile ? 'horizontal' : 'vertical'}
      grossPrice={adjustedPrice}
      discountedPrice={
        (isFreeGift ? 0 : undefined) ??
        (firstBoxDiscountPart ? discountedPrice : undefined)
      }
      imgWidth={isMobile ? 140 : 280}
      imgHeight={isMobile ? 160 : 240}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() =>
        productUpsellableOnClick(
          id,
          discountedPrice,
          adjustedPrice,
          selected,
          firstBoxDiscountPart
        )
      }
      ctaVariant={selected ? 'primary' : 'secondary'}
      isSelected={!!selected}
      disableButton={false}
      readMoreOnClick={readMoreOnClick}
      isFreeGift={isFreeGift}
    />
  )
}

const Extras = ({
  namespace,
  shouldSeePetParentOnPlans,
  shouldSeeSkipPlanPage
}: Props): JSX.Element | null => {
  const copyContext = 'extras'

  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const wizardState = useReactiveVar(plansPageWizardState)
  const deliveryCadence = useReactiveVar(deliveryCadenceState)

  const plansState: PlanState = useReactiveVar(plansPageState)
  const { productVariants } = plansState

  const plansAnalyticState = useReactiveVar(plansPageAnalyticState)

  const funnelGiftCampaignSlug = Cookies.get(FUNNEl_GIFT_CAMPAIGN_COOKIE_KEY)

  const { data, loading, error } = useQuery<SimplifiedGuestQuery>(GUEST_QUERY)

  const {
    data: extrasScreenData,
    loading: extrasScreenLoading,
    error: extrasScreenError
  } = useQuery<ExtrasScreenQuery>(EXTRAS_SCREEN_QUERY)

  const {
    data: funnelGiftCampaignData,
    loading: funnelGiftCampaignLoading,
    error: funnelGiftCampaignError
  } = useQuery<FunnelGiftCampaignQuery>(FUNNEl_GIFT_CAMPAIGN_QUERY, {
    skip: !funnelGiftCampaignSlug,
    variables: {
      slug: funnelGiftCampaignSlug
    }
  })

  const { createPendingSubscription, createPendingSubscriptionLoading } =
    useCreatePendingSubscription()

  useEffect(() => {
    const recommendedExtras =
      extrasScreenData?.guest?.planRecommendation
        ?.recommendedExtraProductVariants
    recommendedExtras &&
      trackExtrasSeen({
        quantity: recommendedExtras.length,
        productGroup: recommendedExtras.map(
          ({ productCollection }) => productCollection.name
        ),
        productVariantIds: recommendedExtras.map(({ id }) => id)
      })
  }, [
    extrasScreenData?.guest?.planRecommendation?.recommendedExtraProductVariants
  ])

  if (error) {
    Sentry.captureException(
      `Error in Plans Flow - Extras step: SimplifiedGuestQuery`,
      {
        extra: {
          error
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  }

  if (extrasScreenError) {
    Sentry.captureException(
      `Error in Plans Flow - Extras step: ExtrasScreenQuery`,
      {
        extra: {
          extrasScreenError
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  }

  if (funnelGiftCampaignError) {
    Sentry.captureException(
      `Error in Plans Flow - Extras step: FunnelGiftCampaignQuery`,
      {
        extra: {
          funnelGiftCampaignError
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  }

  const currentStep = useCallback(() => {
    return currentRouteToCurrentStep({
      route: PlansRoutes.Extras,
      hasRecommendedExtras: true,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    })
  }, [shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  const totalSteps = useCallback(() => {
    return currentRouteToTotalStep(
      true,
      shouldSeePetParentOnPlans,
      shouldSeeSkipPlanPage
    )
  }, [shouldSeePetParentOnPlans, shouldSeeSkipPlanPage])

  const showProductModal = useCallback(
    (slug: string) => {
      additionalProductModalData({ isOpen: true, slug: slug })
      trackAdditionalProductMoreInfoClicked(
        Routes.Extras,
        plansState,
        wizardState,
        deliveryCadence,
        slug,
        FlowType.new,
        plansAnalyticState
      )
    },
    [deliveryCadence, plansAnalyticState, plansState, wizardState]
  )

  const handleOnClick = useCallback(() => {
    createPendingSubscription()
  }, [createPendingSubscription])

  const { guest } = extrasScreenData || {}
  const { planRecommendation } = guest || {}
  const { recommendedExtraProductVariants } = planRecommendation || {}

  const setSelectedExtras = useCallback(
    ({
      productVariant,
      productName,
      productCollectionSlug
    }: {
      productVariant: SelectedProductVariant
      productName: string
      productCollectionSlug: string
    }) => {
      plansPageState({
        ...plansState,
        productVariants: [...productVariants, productVariant]
      })

      if (plansAnalyticState.addedExtraProducts) {
        plansPageAnalyticState({
          ...plansAnalyticState,
          addedExtraProducts: {
            ...plansAnalyticState.addedExtraProducts,
            names: [
              ...plansAnalyticState.addedExtraProducts.names,
              productName
            ],
            slugs: [
              ...plansAnalyticState.addedExtraProducts.slugs,
              productCollectionSlug
            ]
          }
        })
        trackExtrasProductAdded({
          productType: [
            ...plansAnalyticState.addedExtraProducts.names,
            productName
          ],
          quantity: productVariants.length + 1,
          productCollectionSlugs: [
            ...plansAnalyticState.addedExtraProducts.slugs,
            productCollectionSlug
          ],
          productVariantIds: isNil(recommendedExtraProductVariants)
            ? null
            : recommendedExtraProductVariants.map(({ id }) => id)
        })
      } else {
        plansPageAnalyticState({
          ...plansAnalyticState,
          addedExtraProducts: {
            names: [productName],
            slugs: [productCollectionSlug]
          }
        })
        trackExtrasProductAdded({
          productType: [productName],
          quantity: productVariants.length + 1,
          productCollectionSlugs: [productCollectionSlug],
          productVariantIds: isNil(recommendedExtraProductVariants)
            ? null
            : recommendedExtraProductVariants.map(({ id }) => id)
        })
      }
    },
    [
      plansState,
      productVariants,
      recommendedExtraProductVariants,
      plansAnalyticState
    ]
  )

  const removeSelectedExtras = useCallback(
    ({
      productVariant,
      productName,
      productCollectionSlug
    }: {
      productVariant: SelectedProductVariant
      productName: string
      productCollectionSlug: string
    }) => {
      const filteredProductVariants = productVariants.filter(
        (selectedProductVariant) =>
          productVariant.id !== selectedProductVariant.id
      )
      plansPageState({
        ...plansState,
        productVariants: filteredProductVariants
      })

      const filteredProductNames =
        plansAnalyticState.addedExtraProducts.names.filter(
          (name: string) => productName !== name
        )

      const filteredProductSlugs =
        plansAnalyticState.addedExtraProducts.slugs.filter(
          (slug: string) => productCollectionSlug !== slug
        )

      plansPageAnalyticState({
        ...plansAnalyticState,
        addedExtraProducts: {
          names: filteredProductNames,
          slugs: filteredProductSlugs
        }
      })

      trackExtrasProductRemoved({
        productType: plansAnalyticState.addedExtraProducts.names,
        quantity: productVariants.length,
        productCollectionSlugs: isNil(recommendedExtraProductVariants)
          ? []
          : recommendedExtraProductVariants.map(
              ({ productCollection }) => productCollection.slug
            ),
        productVariantIds: productVariants.map(({ id }) => id)
      })
    },
    [
      plansState,
      productVariants,
      plansAnalyticState,
      recommendedExtraProductVariants
    ]
  )

  if (data?.currentUser?.__typename !== 'Guest') return null

  const { dogs, assumedShippingCountry, preferredLanguage, discountCode } =
    data?.currentUser

  const firstBoxDiscountPart = (() => {
    if (
      data?.currentUser.__typename === 'Guest' &&
      data?.currentUser.discountCode
    ) {
      const {
        discountCode: { discountCodeParts }
      } = data.currentUser

      return (
        discountCodeParts.find((part) =>
          DiscountCodes.partAppliesToBoxNumber(part, 1)
        ) ?? null
      )
    } else {
      return null
    }
  })()

  const dogNames = dogs.map(({ name }) => name)
  const { code: shippingCountryCode } = assumedShippingCountry

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )
  const firstBoxDiscount =
    firstBoxDiscountPart &&
    DiscountCodes.formattedPartValue(firstBoxDiscountPart, locale, currency)

  const discountValue = firstBoxDiscount

  const hasFreeGift =
    funnelGiftCampaignData && funnelGiftCampaignData?.funnelGiftCampaign

  return (
    <div className={STYLES.container}>
      <WizardNavigation
        variant="simplified"
        percentFilled={currentRouteToPercentFilled({
          route: PlansRoutes.Extras,
          hasRecommendedExtras: true,
          shouldSeePetParentOnPlans
        })}
        currentStep={currentStep()}
        totalSteps={totalSteps()}
        backUrl={PlansRoutes.Plan}
        customText={
          shouldSeeSkipPlanPage
            ? {
                text: 'recommended_box_navigation.extras',
                namespace,
                variant: 'textRegular14',
                margin: false,
                variables: {
                  currentStep: currentStep(),
                  totalSteps: totalSteps()
                }
              }
            : undefined
        }
      />
      <Container maxWidth="lg">
        <div
          className={`${STYLES.titleWrapper} ${
            hasFreeGift ? STYLES.freeGift : ''
          }`}
        >
          <Text
            dataTestId="hero-text-title"
            namespace={namespace}
            text={`${copyContext}.title`}
            variables={{
              count: dogs.length,
              dogName: dogNames
            }}
            variant="display24"
            element="h1"
            align="center"
            margin={false}
          />
          <Text
            namespace={namespace}
            text={`${copyContext}.subtitle${discountValue ? '_discount' : ''}`}
            variables={{
              count: dogs.length,
              dogName: dogNames,
              discountValue
            }}
            variant="textRegular16"
            colour="brandBlue400"
            element="p"
            align="center"
            margin={false}
          />
        </div>
        <Grid container justifyContent="center">
          <div className={STYLES.cardsContainer}>
            {funnelGiftCampaignLoading ? (
              <div className={STYLES.cardContainer}>
                <ProductUpsellableCardSkeleton
                  orientation={isMobile ? 'horizontal' : 'vertical'}
                />
              </div>
            ) : (
              funnelGiftCampaignData &&
              funnelGiftCampaignData?.funnelGiftCampaign && (
                <div className={STYLES.cardContainer} key="funnelGiftCampaign">
                  <ExtraCard
                    preferredLanguage={preferredLanguage}
                    shippingCountryCode={shippingCountryCode}
                    discountCode={discountCode}
                    extraProduct={
                      funnelGiftCampaignData.funnelGiftCampaign.productVariant
                    }
                    setSelectedExtras={setSelectedExtras}
                    removeSelectedExtras={removeSelectedExtras}
                    productVariants={productVariants}
                    // eslint-disable-next-line react/jsx-no-bind
                    readMoreOnClick={() =>
                      showProductModal(
                        funnelGiftCampaignData.funnelGiftCampaign
                          ?.productVariant.productCollection.slug || ''
                      )
                    }
                    isFreeGift
                  />
                </div>
              )
            )}
            {loading || extrasScreenLoading
              ? times(4, (n) => {
                  return (
                    <div className={STYLES.cardContainer} key={n}>
                      <ProductUpsellableCardSkeleton
                        orientation={isMobile ? 'horizontal' : 'vertical'}
                        key={n}
                      />
                    </div>
                  )
                })
              : recommendedExtraProductVariants &&
                recommendedExtraProductVariants.map((extraProduct) => {
                  return (
                    <div className={STYLES.cardContainer} key={extraProduct.id}>
                      <ExtraCard
                        preferredLanguage={preferredLanguage}
                        shippingCountryCode={shippingCountryCode}
                        discountCode={discountCode}
                        extraProduct={extraProduct}
                        setSelectedExtras={setSelectedExtras}
                        removeSelectedExtras={removeSelectedExtras}
                        productVariants={productVariants}
                        // eslint-disable-next-line react/jsx-no-bind
                        readMoreOnClick={() =>
                          showProductModal(extraProduct.productCollection.slug)
                        }
                      />
                    </div>
                  )
                })}
          </div>
        </Grid>
      </Container>
      <StickyNavigation
        variant="oneButton"
        disabled={createPendingSubscriptionLoading}
        buttonOne={{
          dataTestId: 'next-button',
          variant: 'primary',
          text: 'plans_steps_navigation.next',
          iconColour: 'brandWhite',
          namespace,
          onClick: handleOnClick,
          fullWidth: true
        }}
      />
      <AdditionalProductInformationModal />
    </div>
  )
}

export default Extras
