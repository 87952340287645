import { gql } from '@apollo/client'

const DOG_PROFILE_MEDICAL_FRAGMENT = gql`
  fragment DogProfileMedicalFragment on Dog {
    id
    allergens {
      id
      name
    }
    healthIssues {
      id
      name
    }
    recommendedCareDetails {
      careType
    }
    name
    possessivePronoun
  }
`

export { DOG_PROFILE_MEDICAL_FRAGMENT }
