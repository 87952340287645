import { gql } from '@apollo/client'

const ARCHIVE_DOG_PROFILE_ACTION_DOG_FRAGMENT = gql`
  fragment ArchiveDogProfileActionDogFragment on Dog {
    id
    name
    gender
  }
`

const ARCHIVE_DOG_PROFILE_ACTION_QUERY_FRAGMENT = gql`
  fragment ArchiveDogProfileActionQueryFragment on Query {
    archiveReasons
  }
`

export {
  ARCHIVE_DOG_PROFILE_ACTION_DOG_FRAGMENT,
  ARCHIVE_DOG_PROFILE_ACTION_QUERY_FRAGMENT
}
