import { gql } from '@apollo/client'

import { SELECTABLE_DOG_AVATAR_FRAGMENT } from '../../SelectableDogAvatar/fragments/selectableDogAvatarFragment'

const DOG_SELECTION_FRAGMENT = gql`
  ${SELECTABLE_DOG_AVATAR_FRAGMENT}
  fragment DogSelectionFragment on Dog {
    id
    ...SelectableDogAvatarFragment
  }
`

export { DOG_SELECTION_FRAGMENT }
