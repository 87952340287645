import { gql } from '@apollo/client'

const DELIVERY_FREQUENCY_OVERVIEW_FRAGMENT = gql`
  fragment DeliveryFrequencyOverviewFragment on Plan {
    id
    numberOfPouches
    durationInWeeks
  }
`

export { DELIVERY_FREQUENCY_OVERVIEW_FRAGMENT }
