// @noflow

/* eslint-disable i18next/no-literal-string */
import { ACCOUNT_ROUTES } from '@/routes'
import i18next from 'i18next'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'
import ImageCard from '@/components/elements/organisms/ImageCard/ImageCard'

import STYLES from './Medical.module.sass'

import { DogProfileMedicalFragment as Dog } from '../../fragments/__generated__/DogProfileMedicalFragment'

import { DetailsRowSection } from '../DetailsRowSection'

type Props = {
  showHealthSection: boolean
  dog?: Dog
  calendlyUrl?: string
  loading?: boolean
}

const Medical = ({
  showHealthSection,
  dog,
  calendlyUrl,
  loading = false
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation('account')

  const isMobile = windowWidth < BREAKPOINTS.md
  const copyContext = 'dog_profile.medical'

  const allergies = dog?.allergens.map(({ name }) => name).join(', ')
  const issues = dog?.healthIssues.map(({ name }) => name).join(', ')

  const medicalSections = [
    {
      id: 'allergies',
      title: {
        text: `${copyContext}.allergies.title`
      },
      content: {
        text: `${copyContext}.allergies.content`,
        variables: {
          context:
            dog && dog.allergens.length > 0 ? 'hasAllergens' : 'noAllergens',
          allergies: allergies ?? ''
        }
      }
    },
    {
      id: 'health_issues',
      title: {
        text: `${copyContext}.health_issues.title`
      },
      content: {
        text: `${copyContext}.health_issues.content`,
        variables: {
          context:
            dog && dog.healthIssues.length > 0 ? 'hasIssues' : 'noIssues',
          issues: issues ?? ''
        }
      }
    }
  ]

  const careType = dog?.recommendedCareDetails.careType

  // TODO: Make sure this is the correct route
  const navigateToMedicalInfo = useCallback(() => {
    navigate(
      generatePath(ACCOUNT_ROUTES.dogMedicalInfo, { dogId: dog?.id ?? '' })
    )
  }, [dog?.id, navigate])

  const navigateToHealthPage = useCallback(() => {
    if (calendlyUrl) {
      window.location.href = calendlyUrl
    }
  }, [calendlyUrl])

  return (
    <ActionCard
      shadow
      padding={isMobile ? 24 : 32}
      heading={{
        text: `${copyContext}.title`,
        namespace: 'account'
      }}
      button={{
        text: {
          text: 'dog_profile.edit_cta',
          namespace: 'account'
        },
        onClick: navigateToMedicalInfo
      }}
      skeleton={{
        isLoading: loading,
        height: 300
      }}
      fill={false}
      className={STYLES.wrapper}
    >
      <div className={STYLES.contentWrapper}>
        <div className={STYLES.content}>
          {medicalSections.map(({ id, ...medicalSection }) => (
            <DetailsRowSection
              key={id}
              margin={{ top: 0, bottom: 0 }}
              {...medicalSection}
            />
          ))}
        </div>
        <div className={STYLES.imageWrapper}>
          <Image
            alt={t(`${copyContext}.image_alt`)}
            slug="dog-profile-medical"
            image={{
              width: isMobile ? 132 : 180,
              height: isMobile ? 70 : 94
            }}
          />
        </div>
      </div>
      {showHealthSection && (
        <>
          <Separator handdrawn margin={{ top: 32, bottom: 32 }} />
          <ImageCard
            padding={0}
            disablePaddedContent
            image={{
              width: isMobile ? 80 : 120,
              height: isMobile ? 80 : 152
            }}
            slug="dog-profile-care-entry-point"
            alt={t(`${copyContext}.health_section.image_alt`)}
            heading={{
              variant: 'display16',
              namespace: 'account',
              margin: false,
              text: `${copyContext}.health_section.title`
            }}
            position="left"
            fill={false}
          >
            <Text
              margin={false}
              namespace="account"
              text={`${copyContext}.health_section.${careType}`}
              colour="brandBlue400"
              variables={{
                possessiveName: upperFirst(
                  possessive(dog?.name ?? 'loading', i18next.language)
                ),
                possessivePronoun: dog?.possessivePronoun
              }}
            />
            {!isMobile && (
              <SectionWrapper margin={{ top: 24, bottom: 0 }}>
                <Button
                  fullWidth
                  identifier={`${copyContext}.health_section.book_appointment`}
                  typography={{
                    text: `${copyContext}.health_section.book_appointment`,
                    namespace: 'account'
                  }}
                  onClick={navigateToHealthPage}
                />
              </SectionWrapper>
            )}
          </ImageCard>
          {isMobile && (
            <SectionWrapper margin={{ top: 16, bottom: 0 }}>
              <Button
                fullWidth
                identifier={`${copyContext}.health_section.book_appointment`}
                typography={{
                  text: `${copyContext}.health_section.book_appointment`,
                  namespace: 'account'
                }}
                onClick={navigateToHealthPage}
              />
            </SectionWrapper>
          )}
        </>
      )}
    </ActionCard>
  )
}

export { Medical }
