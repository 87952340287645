/* eslint-disable i18next/no-literal-string */
// @noflow
import {
  PartialLocation,
  shareOnFacebookButtonWasClicked,
  shareOnWhatsAppButtonWasClicked
} from '@/helpers/ReferralLinkShareHelper'
import type { Language } from '@/packs/localisation'
import { makeVar, useReactiveVar } from '@apollo/client'
import Handlebars from 'handlebars'
import React from 'react'

import { toLocalisedSentence } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Envelope from 'assets/images/icons/envelope--blue-large.svg'

import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import EmailShare from '@/components/elements/organisms/EmailShare/EmailShare'

import STYLES from './ShareModal.module.sass'

import { ShareModalQuery } from '../../molecules/ShareModalButton/queries/__generated__/ShareModalQuery'
import { Code as CountryCode } from '@/types'

import CopyInputButton from '../../molecules/CopyInputButton/CopyInputButton'

type Props = {
  namespace: string
  preferredLanguage: Language
  shippingCountryCode: CountryCode
  referralLink: ShareModalQuery['user']['subscription']['referralLink']
  userId: ShareModalQuery['user']['id']
  dogNames: Array<string>
  whatsAppSingleDog: ShareModalQuery['whatsAppSingleDog']
  whatsAppMultipleDogs: ShareModalQuery['whatsAppMultipleDogs']
  partialLocation: PartialLocation
}

type ShareModalContentProps = {
  namespace: string
  preferredLanguage: Language
  userId: ShareModalQuery['user']['id']
  dogNames: Array<string>
  referralUrl: ShareModalQuery['user']['subscription']['referralLink']['url']
  referralCode: ShareModalQuery['user']['subscription']['referralLink']['referralDiscount']['code']
  isAltruisticReferral: boolean
  onSuccessHandler: () => void
  whatsAppSingleDog: ShareModalQuery['whatsAppSingleDog']
  whatsAppMultipleDogs: ShareModalQuery['whatsAppMultipleDogs']
  shippingCountryCode: CountryCode
  partialLocation: PartialLocation
}

type ShareSuccessContentProps = {
  namespace: string
  isAltruisticReferral: boolean
  referralDiscountValue: string
  referrerDiscountValue: string
  setShowShareSuccess: (arg0: boolean) => void
}

const copyContext = 'refer_a_friend.give_and_get_tab.share_modal'

const shareModalState = makeVar(false)

const ShareModalContent = ({
  namespace,
  preferredLanguage,
  userId,
  dogNames,
  referralUrl,
  referralCode,
  isAltruisticReferral,
  onSuccessHandler,
  whatsAppSingleDog,
  whatsAppMultipleDogs,
  shippingCountryCode,
  partialLocation
}: ShareModalContentProps): JSX.Element => {
  const joinedDogNames = toLocalisedSentence({
    arr: dogNames,
    lng: preferredLanguage
  })

  // noEscape is set to true here to allow the = symbol, which is a special character
  // The user shares copyLinkMessage with referrals, so they could write anything they want
  // But the copyLinkMessage is not rendered on the site
  // eslint-disable-next-line i18next/no-literal-string
  const whatsappMessage = Handlebars.compile(
    dogNames.length === 1 ? whatsAppSingleDog.text : whatsAppMultipleDogs.text,
    { noEscape: true }
  )({
    dogNames: joinedDogNames,
    referralLink: `${referralUrl}?share_source=whatsapp`
  })
  // eslint-disable-next-line i18next/no-literal-string
  const copyLinkMessage = Handlebars.compile(
    dogNames.length === 1 ? whatsAppSingleDog.text : whatsAppMultipleDogs.text,
    { noEscape: true }
  )({
    dogNames: joinedDogNames,
    referralLink: `${referralUrl}?share_source=copylink`
  })

  const handleWhatsAppButtonClick = React.useCallback((): void => {
    // eslint-disable-next-line i18next/no-literal-string
    const newWindow = window.open(
      `https://api.whatsapp.com/send?phone=&text=${whatsappMessage}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
    shareOnWhatsAppButtonWasClicked(partialLocation)
  }, [partialLocation, whatsappMessage])

  const handleFacebookButtonClick = React.useCallback((): void => {
    const url = new URL(referralUrl)
    url.searchParams.append('share_source', 'facebook')
    url.searchParams.append('language', preferredLanguage)
    url.searchParams.append('country_code', shippingCountryCode)

    const newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url.href)}`,
      '_blank',
      'noopener,noreferrer'
    )

    if (newWindow) newWindow.opener = null
    shareOnFacebookButtonWasClicked(partialLocation)
  }, [preferredLanguage, referralUrl, shippingCountryCode])

  return (
    <>
      <div className={STYLES.title}>
        <Text
          namespace={namespace}
          text={`${copyContext}.title`}
          variant="display20"
          align="center"
          margin={false}
        />
      </div>
      <div className={STYLES.shareMethods}>
        <Text
          namespace={namespace}
          text={`${copyContext}.copy_input_button_title`}
          variant="textRegular16"
          margin={false}
          bold
        />
        <CopyInputButton
          namespace={namespace}
          referralCode={referralCode}
          copyLinkMessage={copyLinkMessage}
          partialLocation={partialLocation}
        />
        <EmailShare
          userID={userId}
          partialLocation={partialLocation}
          onSuccess={onSuccessHandler}
        />
        <div className={STYLES.socialButtons}>
          <button
            type="button"
            className={STYLES.facebookButton}
            onClick={handleFacebookButtonClick}
          >
            <Icon asset="facebookLetter" size={49} width="49" />
          </button>
          <button
            type="button"
            className={STYLES.whatsappButton}
            onClick={handleWhatsAppButtonClick}
          >
            <Icon asset="whatsApp" size={49} width="49" />
          </button>
        </div>
      </div>
      <Text
        namespace={namespace}
        text={`${copyContext}.${
          isAltruisticReferral ? 'name_drop_altruistic' : 'name_drop'
        }`}
        variant="textRegular16"
      />
    </>
  )
}

const ShareSuccessContent = ({
  namespace,
  isAltruisticReferral,
  referralDiscountValue,
  referrerDiscountValue,
  setShowShareSuccess
}: ShareSuccessContentProps) => {
  const showShareModal = React.useCallback(() => {
    setShowShareSuccess(false)
  }, [setShowShareSuccess])

  return (
    <>
      <img src={Envelope} alt="" className={STYLES.envelope} />
      <Text
        namespace={namespace}
        text={`${copyContext}.success_title`}
        variant="display20"
        align="center"
      />
      <div className={STYLES.successMessage}>
        <Text
          namespace={namespace}
          text={`${copyContext}.${
            isAltruisticReferral
              ? 'success_message_altruistic'
              : 'success_message'
          }`}
          variant="textRegular16"
          variables={{
            referralDiscountValue,
            referrerDiscountValue
          }}
        />
      </div>
      <div className={STYLES.buttonWrapper}>
        <Button
          typography={{
            namespace,
            text: `${copyContext}.send_another_button`
          }}
          variant="primary"
          onClick={showShareModal}
          disableAnalytics
        />
      </div>
    </>
  )
}

const ShareModal = ({
  namespace,
  referralLink,
  preferredLanguage,
  shippingCountryCode,
  userId,
  dogNames,
  whatsAppSingleDog,
  whatsAppMultipleDogs,
  partialLocation
}: Props): JSX.Element => {
  const isShareModalOpen = useReactiveVar(shareModalState)
  const [showShareSuccess, setShowShareSuccess] = React.useState(false)

  const { windowWidth } = useWindowSize()

  const { referralDiscount, referrerDiscount, url } = referralLink
  const { discountCodeParts, code } = referralDiscount
  const { applicableDiscountParts } = referrerDiscount || {}
  const {
    value: applicableDiscountValue,
    discountBasis: referrerDiscountBasis
  } = (applicableDiscountParts || [])[0]
  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const discountPartValue = discountCodeParts[0].value
  const referralDiscountValue =
    discountCodeParts[0].discountBasis === 'total'
      ? formatCurrencyWithoutDecimal(discountPartValue, { locale, currency })
      : percentageValue(discountPartValue)
  const referrerDiscountValue =
    referrerDiscountBasis === 'total'
      ? formatCurrencyWithoutDecimal(applicableDiscountValue, {
          locale,
          currency
        })
      : percentageValue(applicableDiscountValue)
  const isAltruisticReferral = applicableDiscountValue <= 0

  const onSuccessHandler = React.useCallback(() => {
    setShowShareSuccess(true)
  }, [])

  const closeShareModal = React.useCallback(() => {
    setShowShareSuccess(false)
  }, [setShowShareSuccess])

  React.useEffect(() => {
    if (isShareModalOpen) {
      segmentTrack('RAF Share Modal opened')
    }
  }, [isShareModalOpen])

  return (
    <Modal
      isModalOpen={isShareModalOpen}
      setOpenModal={shareModalState}
      width={600}
      showCloseButton
      onCloseButtonClick={closeShareModal}
      bodyScroll
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      {showShareSuccess ? (
        <ShareSuccessContent
          namespace={namespace}
          isAltruisticReferral={isAltruisticReferral}
          referralDiscountValue={referralDiscountValue}
          referrerDiscountValue={referrerDiscountValue}
          setShowShareSuccess={setShowShareSuccess}
        />
      ) : (
        <ShareModalContent
          namespace={namespace}
          preferredLanguage={preferredLanguage}
          userId={userId}
          dogNames={dogNames}
          referralUrl={url}
          referralCode={code}
          isAltruisticReferral={isAltruisticReferral}
          onSuccessHandler={onSuccessHandler}
          whatsAppSingleDog={whatsAppSingleDog}
          whatsAppMultipleDogs={whatsAppMultipleDogs}
          shippingCountryCode={shippingCountryCode}
          partialLocation={partialLocation}
        />
      )}
    </Modal>
  )
}

export { shareModalState }
export default ShareModal
