// @noflow
import { Occasions, useOccasion } from '@/context/festiveTheme/festiveTheme'
import { useLocalisation } from '@/context/localisation'
import { format, isAfter, isToday } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card, {
  CardVariantProp,
  generateVariant
} from '@/components/elements/atoms/Card/Card'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './OrderDate.module.sass'

import { Badge } from '../Badge'
import { OrderLabel } from '../OrderLabel'

type Props = {
  id: string
  deliveryDate: Date
  selected: boolean
  isDiscounted?: boolean
  isNextBox?: boolean
  isTrialBox?: boolean
  isNonCoreOrder?: boolean
  isOneOffBox?: boolean
  isPastBox?: boolean
  isBoxDelivered?: boolean
  deliveryDateAdjustedBySystem?: boolean
  onClick: (id: string) => void
}

const OrderDate = ({
  id,
  deliveryDate,
  selected,
  onClick,
  isDiscounted,
  isNextBox,
  isTrialBox,
  isNonCoreOrder,
  isOneOffBox,
  isPastBox,
  isBoxDelivered,
  deliveryDateAdjustedBySystem
}: Props): JSX.Element => {
  const { dateLocale } = useLocalisation()
  const { xmas, occasion, optedIn } = useOccasion()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md
  const isPastOrder =
    isAfter(new Date(), deliveryDate) && !isToday(deliveryDate)

  const [initialised, setInitialised] = useState(false)

  const shouldDisplayDeliveryAdjustmentBadge =
    deliveryDateAdjustedBySystem && !isBoxDelivered && !isPastBox

  const { variant, textColour } = useMemo((): {
    variant: CardVariantProp
    textColour: TextProps['colour']
  } => {
    if (optedIn && occasion === Occasions.xmas) {
      return {
        variant: generateVariant(
          'brandBlue200',
          isPastOrder ? 'brandBlue300' : 'brandBlue500',
          'brandBlue200'
        ),
        textColour: 'brandBlue500'
      }
    }

    return {
      variant: generateVariant(
        'brandYellow100',
        'brandYellow500',
        'brandYellow200'
      ),
      textColour: isPastOrder ? 'brandYellow600' : 'brandBlue500'
    }
  }, [isPastOrder, optedIn, occasion])

  const onDateSelect = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialised(true)
    }, 100)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <Expand origin="center" show={initialised} fullWidth>
      <div className={STYLES.container}>
        <Card
          variant={variant}
          border={isPastOrder ? 'dashed' : 'solid'}
          element="time"
          dateTime={deliveryDate.toString()}
          background={xmas ? 'brandBlue100' : true}
          selected={selected}
          onClick={onDateSelect}
          padding={0}
          themed={xmas}
          identifier="order_carousel.date"
          className={STYLES.card}
        >
          <Badge
            variant={
              shouldDisplayDeliveryAdjustmentBadge
                ? 'info'
                : isDiscounted
                ? 'discount'
                : null
            }
          />
          <div className={STYLES.cardContent}>
            <div className={STYLES.textContent}>
              <Text
                text={format(new Date(deliveryDate), 'EEE', {
                  locale: dateLocale
                })}
                element="div"
                translate={false}
                colour={textColour}
              />
              <div className={STYLES.date}>
                <Text
                  text={format(deliveryDate, 'dd', {
                    locale: dateLocale
                  })}
                  element="div"
                  translate={false}
                  variant={isMobile ? 'display20' : 'display28'}
                  colour={textColour}
                  tight
                />
              </div>
              <Text
                text={format(deliveryDate, 'MMM', {
                  locale: dateLocale
                })}
                element="div"
                translate={false}
                colour={textColour}
              />
            </div>
            {isNextBox && (
              <OrderLabel
                isNextBox={isNextBox}
                isTrialBox={isTrialBox}
                isNonCoreOrder={isNonCoreOrder}
                isOneOffBox={isOneOffBox}
              />
            )}
          </div>
        </Card>
      </div>
    </Expand>
  )
}

export { OrderDate }
