// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useState } from 'react'

import { dogsDataVar } from '@/services/apollo'

import * as Sentry from '@/utils/sentry'

import useSubscriptionResume from '@/hooks/subscriptionResume/useSubscriptionResume'
import useWindowSize from '@/hooks/useWindowSize'

import DefaultAddress from '../AddressBookPage/components/DefaultAddress'
import { PauseModal } from './components/PauseModal'
import YourDetails from './components/YourDetails'
import YourPassword from './components/YourPassword'
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import { pageTitleState } from '@/components/pages/CustomerIssueManagementPage/CustomerIssueManagementPage'

import STYLES from './PersonalDetails.module.sass'

import { UPDATE_DEFAULT_ADDRESS } from '../AddressBookPage/mutations/updateAddress'
import { UPDATE_PERSONAL_DETAILS } from './mutations/updatePersonalDetails'
import { PERSONAL_DETAILS } from './queries/personalDetailsQuery'

import { UpdateDefaultAddress } from '../AddressBookPage/mutations/__generated__/UpdateDefaultAddress'
import { UpdatePersonalDetails } from './mutations/__generated__/UpdatePersonalDetails'
import {
  PersonalDetailsQuery_user_address as Address,
  PersonalDetailsQuery,
  PersonalDetailsQuery_user as User
} from './queries/__generated__/PersonalDetailsQuery'
import { SubscriptionStatus } from '@/types'

import { BREAKPOINTS } from '../App/App'

type Props = {
  refetch?: () => void
}

const PersonalDetails = ({ refetch }: Props): JSX.Element => {
  console.log('----- updated ------')
  const [user, setUser] = useState<User | null>(null)
  const [showPausePrompt, setshowPausePrompt] = useState(false)
  const [showSuccessfulUpdateMessage, setShowSuccessfulUpdateMessage] =
    useState(false)
  const { windowWidth } = useWindowSize()
  const { dogs } = useReactiveVar(dogsDataVar) || {}

  const {
    loading: personalDetailsLoading,
    data: personalDetailsData,
    error: personalDetailsError,
    refetch: personalDetailsRefetch
  } = useQuery<PersonalDetailsQuery>(PERSONAL_DETAILS)

  const [
    updatePersonalDetailsMutation,
    {
      loading: updatePersonalDetailsLoading,
      data: updatePersonalDetailsData,
      error: updatePersonalDetailsError
    }
  ] = useMutation<UpdatePersonalDetails>(UPDATE_PERSONAL_DETAILS)

  const [
    updateYourPasswordMutation,
    {
      loading: updateYourPasswordLoading,
      data: updateYourPasswordData,
      error: updateYourPasswordError
    }
  ] = useMutation<UpdatePersonalDetails>(UPDATE_PERSONAL_DETAILS)

  const [
    updateAddressMutation,
    {
      loading: updateAddressLoading,
      data: updateAddressData,
      error: updateAddressError
    }
  ] = useMutation<UpdateDefaultAddress>(UPDATE_DEFAULT_ADDRESS)

  const updateUser = useCallback((newUserDetails: Partial<User>) => {
    setUser((prev) => {
      if (prev) {
        return {
          ...prev,
          ...newUserDetails
        }
      } else {
        return null
      }
    })
  }, [])

  const updatePersonalDetails = useCallback(
    (data: Partial<User>) => {
      updatePersonalDetailsMutation({
        variables: {
          userId: user?.id,
          userDetails: data
        }
      }).then((response) => {
        if (!response.errors) {
          setShowSuccessfulUpdateMessage(true)
        }
        personalDetailsRefetch()
        if (!isUndefined(refetch)) refetch()
      })
    },
    [updatePersonalDetailsMutation, user?.id, personalDetailsRefetch, refetch]
  )

  const updateAddress = useCallback(
    (data: Partial<Address>) => {
      const currentAddress = personalDetailsData?.user.address
      updateAddressMutation({
        variables: {
          userId: user?.id,
          address: {
            postcode: data.postcode || currentAddress?.postcode,
            addressLineOne: data.address1 || currentAddress?.address1,
            recipientName: data.recipientName || currentAddress?.recipientName,
            addressLineTwo: isUndefined(data.address2)
              ? currentAddress?.address2
              : data.address2,
            city: data.city || currentAddress?.city,
            deliveryNotes: data.deliveryNotes || currentAddress?.deliveryNotes
          },
          num: 3
        }
      }).then((response) => {
        if (!response.errors) {
          setShowSuccessfulUpdateMessage(true)
        }
        personalDetailsRefetch()
        if (!isUndefined(refetch)) refetch()
      })
    },
    [
      personalDetailsData?.user.address,
      updateAddressMutation,
      user?.id,
      personalDetailsRefetch,
      refetch
    ]
  )

  const updateYourPassword = useCallback(
    (password: string) => {
      updateYourPasswordMutation({
        variables: {
          userId: user?.id,
          userDetails: {
            password
          }
        }
      }).then(() => {
        window.location.href = '/users/sign_in'
      })
    },
    [updateYourPasswordMutation, user?.id]
  )

  useEffect(() => {
    pageTitleState('my_details.personal_details.title')
  }, [])

  useEffect(() => {
    if (personalDetailsData) {
      setUser(personalDetailsData.user)
    }
  }, [personalDetailsData])

  useEffect(() => {
    if (updateYourPasswordData) {
      updateUser(updateYourPasswordData.response as Partial<User>)
    }
  }, [updateYourPasswordData, updateUser])

  useEffect(() => {
    if (updatePersonalDetailsData) {
      updateUser(updatePersonalDetailsData.response as Partial<User>)
    }
  }, [updatePersonalDetailsData, updateUser])

  useEffect(() => {
    if (updateAddressData?.response) {
      const { address } = updateAddressData.response
      updateUser({ address } as Partial<User>)
    }
  }, [updateAddressData, updateUser])

  const { subscription } = user || {}
  const nextBox = subscription?.nextEditableBox
  const { order, editable } = nextBox || {}
  const { discountTotal = 0 } = order || {}

  const { handleResumeSubscription } = useSubscriptionResume({
    subscriptionId: subscription?.id || ''
  })

  const handlePauseClick = useCallback(() => {
    if ((discountTotal && discountTotal > 0) || editable) {
      setshowPausePrompt(true)
      return
    }

    if (subscription?.pauseUrl) {
      window.location.href = subscription.pauseUrl
    }
  }, [discountTotal, editable, subscription?.pauseUrl])

  const handleRestart = useCallback(async () => {
    try {
      await handleResumeSubscription()
      window.location.href =
        ACCOUNT_ROUTES.base + '?show_reactivation_confirmation=true'
    } catch (error) {
      Sentry.captureException(
        `Simplified Account Test | Error restarting subscription`,
        {
          extra: { error },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        }
      )
    }
  }, [handleResumeSubscription])

  const showRestartButton = subscription?.status === SubscriptionStatus.paused

  if (personalDetailsError) {
    return (
      <AlertCard
        message={{
          text: personalDetailsError.message,
          translate: false
        }}
        variant="error"
      />
    )
  }

  return (
    <SectionWrapper
      headerTypography={{
        text: 'titles.account_details',
        namespace: 'account'
      }}
      margin={{ top: 0, bottom: 32 }}
    >
      <div className={STYLES.container}>
        <div className={STYLES.alertCardWrapper}>
          {showSuccessfulUpdateMessage && (
            <AlertCard
              message={{
                text: 'my_details.personal_details.details_updated',
                namespace: 'dashboard'
              }}
              variant="success"
            />
          )}
          {(updatePersonalDetailsError ||
            updateYourPasswordError ||
            updateAddressError) && (
            <AlertCard
              message={{
                text:
                  updatePersonalDetailsError?.message ||
                  updateYourPasswordError?.message ||
                  updateAddressError?.message ||
                  '',
                translate: false
              }}
              variant="error"
            />
          )}
        </div>
        <div className={STYLES.detailsCardWrapper}>
          <YourDetails
            user={user}
            loading={personalDetailsLoading || updatePersonalDetailsLoading}
            updateYourDetails={updatePersonalDetails}
          />
          <YourPassword
            loading={personalDetailsLoading || updateYourPasswordLoading}
            updateYourPassword={updateYourPassword}
          />
          <DefaultAddress
            address={user?.address as Address}
            loading={personalDetailsLoading || updateAddressLoading}
            updateDefaultAddress={updateAddress}
            deliveryNotesLimit={
              user?.currentDeliveryAreaPreference?.preferredCarrierService
                ?.deliveryNotesLimit
            }
          />

          <div className={STYLES.accountPauseTrigger}>
            {dogs && dogs.length > 0 && (
              <Button
                fullWidth
                variant="secondary"
                typography={{
                  namespace: 'account',
                  text: showRestartButton
                    ? 'subscription_settings.restart_subscription_cta'
                    : 'subscription_settings.pause_subscription_cta_alternative'
                }}
                identifier="simplified_account_button"
                onClick={showRestartButton ? handleRestart : handlePauseClick}
                dataTestId={
                  showRestartButton
                    ? 'resume-subscription-button'
                    : 'pause-subscription-button'
                }
              />
            )}

            {!showRestartButton && (
              <Modal
                isModalOpen={showPausePrompt}
                setOpenModal={setshowPausePrompt}
                width={600}
                bottomSticky={windowWidth < BREAKPOINTS.md}
              >
                <PauseModal
                  showPausePrompt={showPausePrompt}
                  setshowPausePrompt={setshowPausePrompt}
                  subscription={personalDetailsData?.user?.subscription}
                  shippingCountryCode={
                    personalDetailsData?.user?.shippingCountryCode
                  }
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default PersonalDetails
