// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DeliveryFrequencyOverview when component is rendered should match snapshot 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class="wrapper transparent borderRadius0"
        data-testid="section-wrapper"
        style="margin-top: 0px; margin-bottom: 0px;"
      >
        <div
          class="content"
        >
          <div
            class="card padding16 none fill"
            data-testid="delivery-frequency-overview-card-content"
          >
            <div
              class="
          container
          indent16
        "
            >
              <div
                class="content"
              >
                <div
                  class="heading"
                />
                <p
                  class="wrapper textRegular16 nomargin bold brandBlue500 scale"
                >
                  delivery_frequency_overview.duration_in_weeks
                </p>
                <p
                  class="wrapper textRegular16 nomargin brandBlue500 scale"
                >
                  delivery_frequency_overview.number_of_pouches
                </p>
              </div>
              <button
                class="button blue interactive"
                id=""
                style="transform: none;"
                tabindex="0"
              >
                <span
                  class="wrapper textRegular14 brandBlue500 scale"
                >
                  delivery_frequency_overview.cta
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="Toastify"
      />
    </div>
  </body>,
  "container": <div>
    <div
      class="wrapper transparent borderRadius0"
      data-testid="section-wrapper"
      style="margin-top: 0px; margin-bottom: 0px;"
    >
      <div
        class="content"
      >
        <div
          class="card padding16 none fill"
          data-testid="delivery-frequency-overview-card-content"
        >
          <div
            class="
          container
          indent16
        "
          >
            <div
              class="content"
            >
              <div
                class="heading"
              />
              <p
                class="wrapper textRegular16 nomargin bold brandBlue500 scale"
              >
                delivery_frequency_overview.duration_in_weeks
              </p>
              <p
                class="wrapper textRegular16 nomargin brandBlue500 scale"
              >
                delivery_frequency_overview.number_of_pouches
              </p>
            </div>
            <button
              class="button blue interactive"
              id=""
              style="transform: none;"
              tabindex="0"
            >
              <span
                class="wrapper textRegular14 brandBlue500 scale"
              >
                delivery_frequency_overview.cta
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="Toastify"
    />
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;
