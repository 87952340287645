import { gql } from '@apollo/client'

import { PRICE_FRAGMENT } from '@/components/pages/ExtrasPage/screens/ProductCollection/components/molecules/ProductPrice/fragments'

const BOX_DELIVERY_ADDRESS_UPDATE = gql`
  mutation BoxDeliveryAddressUpdate(
    $userId: ID!
    $boxId: ID!
    $address: AddressInput!
  ) {
    boxAddressUpdate(userId: $userId, boxId: $boxId, address: $address) {
      subscription {
        id
        box(boxId: $boxId) {
          address {
            recipientName
            address1
            address2
            city
            postcode
            deliveryNotes
            deliveryCarrier
          }
        }
      }
    }
  }
`

const BOX_DELIVERY_DATE_UPDATE = gql`
  mutation BoxDeliveryDateUpdate(
    $userId: ID!
    $boxId: ID!
    $selectedDeliveryDate: ISO8601Date!
    $adjustFutureBoxDates: Boolean!
    $triggerEvents: Boolean
  ) {
    boxDeliveryDateUpdate(
      userId: $userId
      boxId: $boxId
      date: $selectedDeliveryDate
      adjustFutureBoxDates: $adjustFutureBoxDates
      triggerEvents: $triggerEvents
    ) {
      subscription {
        id
        box(boxId: $boxId) {
          id
          isoDeliveryDate
          cutOffDate
        }
      }
    }
  }
`

const BOX_QUERY = gql`
  ${PRICE_FRAGMENT}
  query boxQuery($boxId: ID!) {
    user {
      id
      shippingCountryCode
      shippingCountry {
        id
        showUpcomingOrderSeeMoreExtras
        showExtras
        showInvoiceDownloadDirections
      }
      preferredLanguage
      dogs {
        gender
        name
      }
      subscription {
        id
        upsellableSampleProduct {
          id
          name
          grossPrice
          recurringDeliveryType {
            adjustedGrossPrice
          }
          productCollection {
            id
            slug
            name
            thumbnail {
              src
            }
            productVariants {
              id
              ...PriceFragment
            }
          }
        }
        plan {
          id
          pouchSize
        }
        nextNBoxes(num: 3) {
          id
          isNextEditableBox
        }
        box(boxId: $boxId) {
          id
          descriptor
          isFreeBox
          isReplacementBox
          invoices {
            id
            issueDate
            corrective
            grossTotal
          }
          meals {
            id
            quantity
          }
          invoice {
            id
          }
          shouldOfferSelfService
          totalGrams
          consignment {
            trackingUrl
            status
          }
          editable
          amendable
          id
          cutOffDate
          isoDeliveryDate
          address {
            recipientName
            address1
            address2
            city
            postcode
            deliveryNotes
            deliveryCarrier
          }
          order {
            id
            deliveryFee
            discountTotal
            discountTotalPercentage
            physicalProductsTotal
            planTotal
            planTotalPerDay
            subtotal
            totalPrice
            surchargeTotal
          }

          physicalOrderProducts {
            id
            requiresPayment
            nonPaymentReason
            quantity
            frequency
            price
            discountedPrice
            productVariant {
              id
              name
              grossPrice
              oneOffDeliveryType {
                netPrice
              }
              recurringDeliveryType {
                adjustedGrossPrice
                netPrice
              }
              subscribeAndSaveable
              productCollection {
                id
                slug
                name
                thumbnail {
                  src
                }
                productVariants {
                  id
                  name
                  ...PriceFragment
                }
              }
            }
          }
        }
      }
    }
  }
`

const MEALS_QUERY = gql`
  query mealsQuery($boxId: ID!) {
    user {
      id
      subscription {
        id
        box(boxId: $boxId) {
          id
          editable
          meals {
            quantity
            flavour {
              id
              thumbnail {
                src
              }
              themeColour
              name
              slug
              recipeSurcharge
              productLabels {
                id
                name
                themeColour
              }
            }
          }
        }
      }
    }
    shouldSeeRecipeSurchargeTooltips: featureFlag(
      flag: "should_see_recipe_surcharge_tooltips"
      fallback: "false"
    )
    shouldSeeRecipeSurchargePriceIncreasePl: featureFlag(
      flag: "should_see_recipe_surcharge_price_increase_pl"
      fallback: "false"
    )
  }
`

export {
  BOX_QUERY,
  MEALS_QUERY,
  BOX_DELIVERY_ADDRESS_UPDATE,
  BOX_DELIVERY_DATE_UPDATE
}
