// @noflow
import flatten from 'lodash/flatten'
import isNull from 'lodash/isNull'
import uniq from 'lodash/uniq'

import * as Sentry from '@/utils/sentry'

import { Analytics } from '@/components/analytics/Analytics'
import type { InitialState as WizardState } from '@/components/pages/SignupWizardPage/SignupWizardPage'
import {
  encodeAge,
  encodeEaterType
} from '@/components/pages/SignupWizardPage/helpers/submitFormData'
import type {
  Dog,
  WizardAge,
  WizardEaterType
} from '@/components/pages/SignupWizardPage/types/types'

import { Eater, TypeOfPlan } from '@/types'
import { ensureNever } from '@/typescript/utils'

import { PlanState } from '../SimplifiedPlansPage'
import type {
  Action,
  ExtrasAnalyticProperties,
  FlowType,
  PageLoadProperties,
  PlansAnalyticState,
  SelectionStatus,
  TooltipLocation
} from '../types/analyticsProperties'
import { Routes } from '../types/routes'

const getFlowInformation = (
  route: Routes,
  planState: PlanState,
  wizardState: WizardState,
  deliveryCadence: 'default' | null,
  flowType: FlowType,
  plansAnalyticState: PlansAnalyticState
): Omit<
  PageLoadProperties,
  | 'numberOfSelectedRecipes'
  | 'numberOfRecommendedRecipes'
  | 'numberOfNonRecommendedRecipes'
  | 'addedExtraProducts'
> => {
  const dogs = wizardState.dogs

  const numberOfDogs = dogs.length
  const numberOfPuppies = dogs.filter(
    (dog: Dog) => dog.ageStage === 'puppy'
  ).length
  const ageInMonths = dogs.map((dog: Dog) => encodeAge(dog.age as WizardAge))
  const hasAnAdult = !!dogs.find((dog: Dog) => dog.ageStage === 'adult')
  const hasAPuppy = !!dogs.find((dog: Dog) => dog.ageStage === 'puppy')
  const hasASenior = !!dogs.find((dog: Dog) => dog.ageStage === 'senior')
  const weightInGrams = dogs.map((dog: Dog) => dog.weight)
  const hasAnOverweightDog = !!dogs.find(
    (dog: Dog) => dog.bodyCondition === 'chubby'
  )
  const hasARightSizedDog = !!dogs.find(
    (dog: Dog) => dog.bodyCondition === 'justRight'
  )
  const hasAnUnderweightDog = !!dogs.find(
    (dog: Dog) => dog.bodyCondition === 'skinny'
  )
  const allergies = uniq(flatten(dogs.map((dog: Dog) => dog.allergies)))
  const hasADogWithAnAllergy =
    dogs.filter(
      (dog: Dog): boolean => !isNull(dog.allergies) && dog.allergies.length > 0
    ).length > 0
  const healthIssues = uniq(
    flatten(
      dogs.map((dog: Dog) =>
        dog.healthIssues && dog.healthIssues.length > 0
          ? dog.healthIssues.map((hi) => hi.name)
          : []
      )
    )
  )
  const hasADogWithHealthIssue =
    dogs.filter(
      (dog: Dog): boolean =>
        !isNull(dog.healthIssues) && dog.healthIssues.length > 0
    ).length > 0
  const foodCategories = uniq(
    flatten(dogs.map((dog: Dog): Array<string> => dog.foodCategoryIds))
  )
  const eaterType = uniq(
    flatten(
      dogs.map(
        (dog: Dog): Eater => encodeEaterType(dog.eaterType as WizardEaterType)
      )
    )
  )
  const hasAFussyEater =
    eaterType.filter(
      (eaterType: Eater): boolean =>
        eaterType === 'fussy_eater' || eaterType === 'picky_eater'
    ).length > 0
  const activityLevel = dogs.map((dog: Dog) => dog.activityLevel)
  const breed = dogs.map((dog: Dog) =>
    !isNull(dog.breed) ? dog.breed.key : null
  )

  const selectedDailyGrams = plansAnalyticState.selectedDailyGrams || null
  const idealGramsAmount = plansAnalyticState.idealGramsAmount || null

  return {
    numberOfDogs,
    numberOfPuppies,
    ageInMonths,
    hasAnAdult,
    hasAPuppy,
    hasASenior,
    weightInGrams,
    hasAnOverweightDog,
    hasARightSizedDog,
    hasAnUnderweightDog,
    allergies,
    hasADogWithAnAllergy,
    healthIssues,
    hasADogWithHealthIssue,
    foodCategories,
    eaterType,
    hasAFussyEater,
    activityLevel,
    breed,
    deliveryCadence,
    step: route,
    flowType,
    selectedDailyGrams,
    idealGramsAmount
  }
}

const sectionToAnalyticsEventName = (section: string): string => {
  switch (section) {
    case 'starterBox': {
      return 'New Plans Flow interacted with Recipes Carousel'
    }
    case 'secondaryRecipes': {
      return 'New Plans Flow interacted with Secondary Recipes Carousel'
    }
    default: {
      Sentry.captureException('Invalid sectionToAnalyticsEventName argument', {
        extra: {
          section
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      })
      return ''
    }
  }
}
const trackCarouselInteraction = ({
  action,
  section
}: {
  action: Action
  section: string
}): void => {
  Analytics.track(`${sectionToAnalyticsEventName(section)}`, {
    action
  })
}

const trackExtrasSeen = ({
  quantity,
  productGroup,
  productVariantIds
}: {
  quantity: number
  productGroup: string[] | null
  productVariantIds: string[]
}): void => {
  Analytics.track('Track Extras Seen Pre Checkout', {
    quantity,
    productGroup,
    productVariantIds
  })
}

const trackExtrasProductAdded = ({
  productType,
  quantity,
  productCollectionSlugs,
  productVariantIds
}: ExtrasAnalyticProperties): void => {
  Analytics.track('Plans Flow Extras Product Added', {
    productType,
    quantity,
    productCollectionSlugs,
    productVariantIds
  })
}

const trackExtrasProductRemoved = ({
  productType,
  quantity,
  productCollectionSlugs,
  productVariantIds
}: ExtrasAnalyticProperties): void => {
  Analytics.track('Precheckout Plans Flow Extras Removed', {
    productType,
    quantity,
    productCollectionSlugs,
    productVariantIds
  })
}

const trackPageLoad = (properties: PageLoadProperties): void => {
  Analytics.track('Plans Flow Step Loaded', {
    ...properties
  })
}

const triggerPageLoadAnalytics = ({
  route,
  plansState,
  wizardState,
  deliveryCadence,
  flowType,
  plansAnalyticState
}: {
  route: Routes
  plansState: PlanState
  wizardState: WizardState | null
  deliveryCadence: 'default' | null
  flowType: FlowType
  plansAnalyticState: PlansAnalyticState
}): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    plansState,
    wizardState,
    deliveryCadence,
    flowType,
    plansAnalyticState
  )
  const numberOfSelectedRecipes =
    plansState &&
    plansState.flavours &&
    plansState.flavours.map(({ id }) => id).length
  const numberOfRecommendedRecipes =
    plansAnalyticState.numberOfRecommendedRecipes
  const numberOfNonRecommendedRecipes =
    plansAnalyticState.numberOfNonRecommendedRecipes
  const addedExtraProducts =
    plansAnalyticState.addedExtraProducts &&
    plansAnalyticState.addedExtraProducts.names

  const pageLoadProperties: PageLoadProperties = {
    ...flowData,
    numberOfSelectedRecipes,
    numberOfRecommendedRecipes,
    numberOfNonRecommendedRecipes,
    addedExtraProducts
  }

  switch (route) {
    case Routes.Recipes: {
      trackPageLoad(pageLoadProperties)
      break
    }
    case Routes.Plan: {
      trackPageLoad(pageLoadProperties)
      break
    }
    case Routes.Extras: {
      trackPageLoad(pageLoadProperties)
      break
    }
    // Update the below to get the correct analytics
    case Routes.PetParent:
    case Routes.RecommendedBox:
    case Routes.Checkout: {
      break
    }
    default: {
      ensureNever(route)
    }
  }
}

const trackRecipeSelectionToggled = ({
  route,
  plansState,
  wizardState,
  deliveryCadence,
  selectionStatus,
  flavour,
  flowType,
  plansAnalyticState
}: {
  route: Routes
  plansState: PlanState
  wizardState: WizardState | null
  deliveryCadence: 'default' | null
  selectionStatus: SelectionStatus
  flavour: string
  flowType: FlowType
  plansAnalyticState: PlansAnalyticState
}): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    plansState,
    wizardState,
    deliveryCadence,
    flowType,
    plansAnalyticState
  )
  Analytics.track('Plans Flow Recipe Toggled', {
    ...flowData,
    selectionStatus,
    flavour
  })
}

const trackRecipeTooltipToggled = ({
  route,
  location
}: {
  route: Routes
  location: TooltipLocation
}): void => {
  Analytics.track('New Plans Flow interacted with recipe pricing tooltip', {
    path: route,
    location
  })
}

const trackSecondaryRecipesShown = (): void => {
  Analytics.track('New Plans Flow seen Secondary Recipes', {})
}

const trackSizeTooltipToggled = (): void => {
  Analytics.track('New Plans flow interacted with tooltip on Review step', {})
}

const trackUserSelectsAPlanType = ({
  route,
  plansState,
  wizardState,
  deliveryCadence,
  flowType,
  plansAnalyticState,
  planType
}: {
  route: Routes
  plansState: PlanState
  wizardState: WizardState | null
  deliveryCadence: 'default' | null
  flowType: FlowType
  plansAnalyticState: PlansAnalyticState
  planType: TypeOfPlan
}): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    plansState,
    wizardState,
    deliveryCadence,
    flowType,
    plansAnalyticState
  )

  Analytics.track('Plans Flow Plan Type Selected', {
    ...flowData,
    planType
  })
}

const trackPlanTypeSelectionSeen = (): void => {
  Analytics.track('Seen plan selection section on Plans Flow v2', {})
}

const trackStorageSectionSeen = (): void => {
  Analytics.track('Seen fridge/freezer storage section on Plans Flow v2', {})
}

const trackTrustpilotSectionSeen = (): void => {
  Analytics.track('Seen Trustpilot review on Plans Flow v2', {})
}

const trackAdditionalProductMoreInfoClicked = (
  route: Routes,
  planState: PlanState,
  wizardState: WizardState | null,
  deliveryCadence: 'default' | null,
  extra: string,
  flowType: FlowType,
  plansAnalyticState: PlansAnalyticState
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    deliveryCadence,
    flowType,
    plansAnalyticState
  )
  Analytics.track('Plans Flow Extra More Info Clicked', {
    ...flowData,
    extra
  })
}

const trackRecipeMoreInfoClicked = (
  route: Routes,
  planState: PlanState,
  wizardState: WizardState | null,
  deliveryCadence: 'default' | null,
  flavour: string,
  flowType: FlowType,
  plansPageAnalyticState: PlansAnalyticState
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    deliveryCadence,
    flowType,
    plansPageAnalyticState
  )
  Analytics.track('Plans Flow Recipe More Info Clicked', {
    ...flowData,
    flavour
  })
}

export {
  getFlowInformation,
  trackCarouselInteraction,
  trackExtrasSeen,
  trackExtrasProductAdded,
  trackExtrasProductRemoved,
  trackPageLoad,
  triggerPageLoadAnalytics,
  trackRecipeSelectionToggled,
  trackRecipeTooltipToggled,
  trackSecondaryRecipesShown,
  trackSizeTooltipToggled,
  trackUserSelectsAPlanType,
  trackPlanTypeSelectionSeen,
  trackStorageSectionSeen,
  trackTrustpilotSectionSeen,
  trackAdditionalProductMoreInfoClicked,
  trackRecipeMoreInfoClicked
}
