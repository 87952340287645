/* eslint-disable i18next/no-literal-string */
// @noflow
import { Language } from '@/packs/localisation'
import React, { useCallback, useState } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'

import type { ButtonProps } from '@/components/elements/atoms/Button'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import QuantitySelector from '@/components/elements/atoms/QuantitySelector/QuantitySelector'
import Text from '@/components/elements/atoms/Text/Text'
import ProductPrice, {
  ProductPriceProps
} from '@/components/pages/ExtrasPage/screens/ProductCollection/components/molecules/ProductPrice'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './ProductUpsellableCard.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { VariantDelivery } from '@/types'

import Image from '../../atoms/Image'
import Link from '../../atoms/Link/Link'

type Orientation = 'horizontal' | 'vertical'

type DefaultProps = Omit<ProductPriceProps, 'locale' | 'currency'> & {
  disableButton: boolean
  name: string
  preferredLanguage: Language
  productName: string
  shippingCountryCode: CountryCode
  src: string
  orientation: Orientation
  showExploreExtrasButton?: boolean
  shadow?: boolean
  deliveryType?: VariantDelivery
  ctaVariant?: ButtonProps['variant']
  imgWidth?: number
  imgHeight?: number
  readMoreOnClick?: () => void
  dataTestId?: string
  isFreeGift?: boolean
}

type OneClickProps = {
  variant: 'oneClick'
  onClick: () => void
}

type AddAndRemoveProps = {
  variant: 'addAndRemove'
  onClick: () => void
  isSelected: boolean
}
type QuantitySelectorProps = {
  variant: 'quantitySelector'
  onClick: (productVariantId: string, quantity: number) => void
  productVariantId: string
}

type Props = DefaultProps &
  (OneClickProps | AddAndRemoveProps | QuantitySelectorProps)

const namespace = 'dashboard'
const copyContext = 'extras.product_quantity_card'

const BaseProductUpsellableCard = (props: Props): JSX.Element => {
  const {
    shippingCountryCode,
    preferredLanguage,
    grossPrice,
    discountedPrice,
    deliveryType,
    shadow,
    orientation,
    productName,
    src,
    name,
    disableButton,
    variant,
    ctaVariant = 'primary',
    showExploreExtrasButton,
    imgWidth = 250,
    imgHeight = 250,
    readMoreOnClick = false,
    dataTestId,
    isFreeGift = false
  } = props

  const [productQuantity, setProductQuantity] = useState(0)
  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const handleOnClick = useCallback(
    (quantity) => {
      switch (variant) {
        case 'oneClick':
        case 'addAndRemove': {
          const { onClick } = props
          return onClick()
        }
        case 'quantitySelector': {
          const { productVariantId, onClick } = props
          return (
            setProductQuantity(productQuantity === 0 ? 1 : quantity),
            onClick(productVariantId, productQuantity === 0 ? 1 : quantity)
          )
        }
      }
    },
    [productQuantity, props, variant]
  )

  const handleCtaText = () => {
    switch (variant) {
      case 'oneClick':
      case 'quantitySelector': {
        return deliveryType === 'recurring'
          ? `${copyContext}.add_plan`
          : `${copyContext}.add_one_off`
      }
      case 'addAndRemove': {
        const { isSelected } = props
        return isSelected ? `${copyContext}.remove` : `${copyContext}.add_extra`
      }
    }
  }

  const extrasUrl = '/dashboard/extras'
  // eslint-disable-next-line no-return-assign
  const exploreExtras = useCallback(
    () => (window.location.href = extrasUrl),
    []
  )

  return (
    <Card shadow={shadow}>
      <div
        className={
          orientation === 'horizontal' ? STYLES.wrapper : STYLES.wrapperVertical
        }
      >
        <div
          className={`${STYLES.image} ${
            variant === 'addAndRemove' ? STYLES.addAndRemove : ''
          }`}
        >
          <CloudinaryImage
            alt={productName}
            image={{
              path: src,
              width: imgWidth,
              height: imgHeight,
              aspectRatio: '1:1',
              crop: 'fill',
              dpr: window.devicePixelRatio
            }}
          />
        </div>
        <div
          className={`${STYLES.content} ${
            variant === 'addAndRemove' ? STYLES.addAndRemove : ''
          }`}
        >
          <Text
            variant="display20"
            text={productName}
            margin={false}
            translate={false}
            shouldScale={false}
            align="left"
          />
          <div className={STYLES.subcontent}>
            <Text
              variant="textRegular18"
              text={name}
              margin={false}
              translate={false}
              shouldScale={false}
              align="left"
            />
            <div>
              <ProductPrice
                grossPrice={grossPrice}
                discountedPrice={discountedPrice}
                locale={locale}
                currency={currency}
              />
            </div>
            {readMoreOnClick && (
              <div className={STYLES.readMore}>
                <Link
                  text={`${copyContext}.read_more`}
                  onClick={readMoreOnClick}
                  namespace={namespace}
                  suffix={null}
                  identifier="extras.product_quantity_card.read_more"
                />
              </div>
            )}
            {!readMoreOnClick && productQuantity === 0 && !isFreeGift && (
              <Button
                typography={{
                  namespace,
                  text: handleCtaText(),
                  variables: { deliveryType }
                }}
                onClick={handleOnClick}
                variant={ctaVariant}
                disabled={disableButton}
                disableAnalytics
              />
            )}
            {showExploreExtrasButton && (
              <Button
                typography={{
                  namespace,
                  text: `${copyContext}.explore_extras`
                }}
                onClick={exploreExtras}
                variant="secondary"
                disableAnalytics
              />
            )}
            {productQuantity > 0 && (
              <QuantitySelector
                quantity={productQuantity}
                onChange={handleOnClick}
                minValue={0}
                size="fullWidth"
                identifier="product_upsellable_card"
              />
            )}
          </div>
        </div>
      </div>

      {readMoreOnClick && productQuantity === 0 && !isFreeGift && (
        <div className={STYLES.footerContent}>
          <Button
            dataTestId={`${dataTestId}-button`}
            typography={{
              namespace,
              text: handleCtaText(),
              variables: { deliveryType }
            }}
            onClick={handleOnClick}
            variant={ctaVariant}
            disabled={disableButton}
            disableAnalytics
            fullWidth
          />
        </div>
      )}

      {isFreeGift && (
        <div className={STYLES.footerContent}>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <div className={STYLES.freeGiftLabel}>
            <Text
              variant="textRegular18"
              text={`${copyContext}.free_gift`}
              namespace={namespace}
              shouldScale={false}
              colour="supportGreen500"
              align="center"
              margin={false}
              uppercase
            />
            <Icon asset="checkmark" size={15} accentColour="successGreen400" />
          </div>
        </div>
      )}
    </Card>
  )
}

const FreeProductUpsellableCard = (props: Props): JSX.Element => {
  return (
    <div className={STYLES.freeGiftWrapper}>
      <Image
        slug="yellow-side-sparks--left"
        className={STYLES.sparksLeft}
        image={{
          width: 10,
          height: 25
        }}
        alt=""
      />
      <Image
        slug="yellow-side-sparks--right"
        className={STYLES.sparksRight}
        image={{
          width: 10,
          height: 25
        }}
        alt=""
      />
      <Card
        variant="brandYellow300"
        className={STYLES.freeGiftCardWrapper}
        shadow
        padding={0}
        fill={false}
      >
        <Text
          text={`${copyContext}.your_free_gift`}
          namespace={namespace}
          margin={false}
          shouldScale={false}
          align="center"
          uppercase
        />
      </Card>
      <BaseProductUpsellableCard {...props} />
    </div>
  )
}

const ProductUpsellableCard = (props: Props): JSX.Element => {
  const { isFreeGift } = props
  return isFreeGift ? (
    <FreeProductUpsellableCard {...props} />
  ) : (
    <BaseProductUpsellableCard {...props} />
  )
}

export type { Props }
export default ProductUpsellableCard
