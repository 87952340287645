// @noflow
import { useQuery } from '@apollo/client'
import isNull from 'lodash/isNull'
import React, { useCallback, useState } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import { BoxPriceBreakdown } from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'
import {
  boxQuery,
  boxQuery_user_subscription_box
} from '@/components/pages/OrderPage/__generated__/boxQuery'
import InvoiceCard from '@/components/pages/OrderPage/components/InvoiceCard/InvoiceCard'
import { BOX_QUERY } from '@/components/pages/OrderPage/queries'

type WithQueryProps = {
  boxId: number
  csrfToken?: string
}

const PriceBreakdownWithQuery = ({
  boxId,
  csrfToken
}: WithQueryProps): JSX.Element | null => {
  const [invoicesModalIsOpen, setInvoicesModalIsOpen] = useState(false)

  const { windowWidth } = useWindowSize()

  const { data } = useQuery<boxQuery>(BOX_QUERY, {
    variables: {
      boxId: boxId
    }
  })

  const toggleInvoicesModalOpen = useCallback(() => {
    setInvoicesModalIsOpen(!invoicesModalIsOpen)
  }, [invoicesModalIsOpen])

  if (!data || isNull(data.user.subscription.box)) return null

  const {
    shippingCountryCode,
    preferredLanguage,
    subscription,
    shippingCountry: { showInvoiceDownloadDirections }
  } = data.user

  const { box } = subscription

  const { order, isoDeliveryDate, invoices, isFreeBox, isReplacementBox } =
    box as boxQuery_user_subscription_box

  const invoiceIssued = invoices.length > 0

  const { planTotal, planTotalPerDay } = (box as boxQuery_user_subscription_box)
    .order

  const {
    discountTotal,
    deliveryFee,
    surchargeTotal,
    physicalProductsTotal,
    totalPrice
  } = order

  return (
    <>
      <BoxPriceBreakdown
        isoDeliveryDate={isoDeliveryDate}
        csrfToken={csrfToken}
        invoiceId={box?.invoice.id}
        isFreeBox={isFreeBox}
        isReplacementBox={isReplacementBox}
        shippingCountryCode={shippingCountryCode}
        preferredLanguage={preferredLanguage}
        meals={[
          {
            total: planTotal || 0,
            perDay: planTotalPerDay || 0
          }
        ]}
        discount={discountTotal || 0}
        deliveryFee={deliveryFee || 0}
        surchargeTotal={surchargeTotal}
        total={totalPrice}
        extras={physicalProductsTotal || undefined}
        toggleInvoicesModalOpen={toggleInvoicesModalOpen}
        invoiceIssued={invoiceIssued}
        showInvoiceDownloadDirections={showInvoiceDownloadDirections}
      />
      {invoicesModalIsOpen && (
        <Modal
          isModalOpen={invoicesModalIsOpen}
          setOpenModal={toggleInvoicesModalOpen}
          width={600}
          textAlign="center"
          bottomSticky={windowWidth < BREAKPOINTS.md}
        >
          <>
            <Text namespace="order" text="your_invoices" variant="display28" />
            {invoices &&
              invoices.map((invoice) => {
                return (
                  <InvoiceCard
                    key={invoice.id}
                    invoice={invoice}
                    shippingCountryCode={shippingCountryCode}
                    preferredLanguage={preferredLanguage}
                  />
                )
              })}

            <Button
              typography={{
                namespace: 'order',
                text: 'done'
              }}
              onClick={toggleInvoicesModalOpen}
              fullWidth
              disableAnalytics
            />
          </>
        </Modal>
      )}
    </>
  )
}

export default PriceBreakdownWithQuery
