// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { useNotifications } from '@/context/notifications/notifications'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import React, { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDogProfilePictureUploadAPI } from '@/hooks/useDogProfilePictureUpload'
import { useFileUpload } from '@/hooks/useFileUpload'

// Assets
import BrokenPolaroidFrame from 'assets/images/backgrounds/broken-image.svg'
import PolaroidFrame from 'assets/images/backgrounds/dog-profile-polaroid-frame.svg'
import LoadingDog from 'assets/images/illustrations/dogs/loading-dog.svg'

import { CameraAction } from '@/components/elements/atoms/CameraAction'
import Interactive from '@/components/elements/atoms/Interactive/Interactive'

import STYLES from './DogProfilePicture.module.sass'

import { CREATE_DOG_PROFILE_MUTATION } from '../../mutations/createDogProfileMutation'

// import { UPDATE_DOG_PROFILE_PICTURE_MUTATION } from '../../mutations/updateDogProfilePictureMutation'
import type {
  CreateDogProfileMutation,
  CreateDogProfileMutationVariables
} from '../../mutations/__generated__/CreateDogProfileMutation'
import { DogProfilePictureFragment as Dog } from './fragments/__generated__/DogProfilePictureFragment'

// import type {
//   UpdateDogProfilePictureMutation,
//   UpdateDogProfilePictureMutationVariables
// } from '../../mutations/__generated__/UpdateDogProfilePictureMutation'

type Props = {
  userId?: string
  dog?: Dog
  loading?: boolean
  refetch?: () => void
  sethasFirstTimeProfilePic: (val: boolean) => void
}

const DogProfilePicture = ({
  userId,
  dog,
  loading = false,
  refetch,
  sethasFirstTimeProfilePic = (): void => undefined
}: Props): JSX.Element => {
  const { setErrorNotification, setSuccessNotification } = useNotifications()
  const { t } = useTranslation('account')
  const [pictureUploading, setpictureUploading] = useState(false)

  const { uploadPhoto } = useDogProfilePictureUploadAPI()

  const { xmas } = useOccasion()

  const showErrorMessage = useCallback(
    (errorContext?: string): void => {
      setErrorNotification(
        {
          text: 'dog_profile.profile_picture.notifications.error',
          namespace: 'account',
          variables: {
            ...(errorContext ? { context: errorContext } : {})
          }
        },
        true
      )
    },
    [setErrorNotification]
  )

  const showSuccessMessage = useCallback((): void => {
    setSuccessNotification(
      {
        text: 'dog_profile.profile_picture.notifications.success',
        namespace: 'account'
      },
      true
    )
  }, [setSuccessNotification])

  // TODO: Use update in progress when needed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateInProgress, setUpdateInProgress] = useState(false)

  const [createDogProfileMutation] = useMutation<
    CreateDogProfileMutation,
    CreateDogProfileMutationVariables
  >(CREATE_DOG_PROFILE_MUTATION, {
    onError() {
      showErrorMessage()
    }
  })

  // TODO: Use the mutation below once fixed
  // const [updateDogProfilePictureMutation] = useMutation<
  //   UpdateDogProfilePictureMutation,
  //   UpdateDogProfilePictureMutationVariables
  // >(UPDATE_DOG_PROFILE_PICTURE_MUTATION, {
  //   onCompleted() {
  //     showSuccessMessage()
  //   },
  //   onError() {
  //     showErrorMessage()
  //   }
  // })

  const uploadNewPicture = useCallback(
    async (file): Promise<boolean> => {
      if (file) {
        const profilePictureForm = new FormData()
        profilePictureForm.append('avatar', file)

        try {
          // TODO: use `updateDogProfilePictureMutation` mutation instead once fixed
          const response = await uploadPhoto({ file, dogid: dog?.id || '' })

          if (response.ok) {
            const res = await response.json()

            if (res.avatar_url) {
              showSuccessMessage()

              if (!dog?.dogProfile?.avatarUrl) {
                sethasFirstTimeProfilePic(true)
              }

              refetch && refetch()

              return true
            } else {
              showErrorMessage('corrupted')
              refetch && refetch()

              return false
            }
          }

          showErrorMessage()

          return false

          // const { data, errors } = await updateDogProfilePictureMutation({
          //   variables: {
          //     userId: userId ?? '',
          //     avatarsInput: [
          //       {
          //         dogId: dog?.id ?? '',
          //         avatar: profilePictureForm
          //       }
          //     ]
          //   }
          // })

          // if (data) {
          //   return true
          // }

          // if (errors) {
          //   return false
          // }
        } catch (error) {
          showErrorMessage()

          return false
        }
      }

      return true
    },
    [
      uploadPhoto,
      dog?.id,
      dog?.dogProfile?.avatarUrl,
      showErrorMessage,
      showSuccessMessage,
      refetch,
      sethasFirstTimeProfilePic
    ]
  )

  // If the dog profile is not available, create a new dog profile
  const createProfileIfNotAvailable =
    useCallback(async (): Promise<boolean> => {
      if (dog?.dogProfile) {
        return true
      } else {
        try {
          if (dog) {
            const { id: dogId } = dog
            const { data } = await createDogProfileMutation({
              variables: {
                userId: userId ?? '',
                dogIdsInput: [dogId]
              }
            })

            if (data) {
              return true
            }
          }
        } catch (e) {
          showErrorMessage()
        }
      }

      return false
    }, [dog, userId, createDogProfileMutation, showErrorMessage])

  // Update the dog profile picture
  const updateDogProfilePicture = useCallback(
    async (file) => {
      setpictureUploading(true)
      try {
        setUpdateInProgress(true)
        const isDogProfileAvaliable = await createProfileIfNotAvailable()

        if (isDogProfileAvaliable) {
          await uploadNewPicture(file)

          setUpdateInProgress(false)
        }
      } catch (error) {
        showErrorMessage()
      } finally {
        setpictureUploading(false)
      }
    },
    [createProfileIfNotAvailable, showErrorMessage, uploadNewPicture]
  )

  const { fileInputRef, handleElementClick, handleFileSelect } = useFileUpload({
    onFileSelect: updateDogProfilePicture
  })

  const handlePolaroidClick = useCallback(() => {
    if (pictureUploading) return

    handleElementClick()
  }, [handleElementClick, pictureUploading])

  const wrapperClass = classNames(STYLES.wrapper, {
    [STYLES.polaroidWrapper]: !dog?.dogProfile?.avatarUrl,
    [STYLES.xmas]: xmas
  })

  return (
    <div className={wrapperClass}>
      {pictureUploading && (
        <div className={STYLES.loadingWrapper}>
          <img
            alt={t('dog_profile.profile_picture.image_loading')}
            src={LoadingDog}
          />
        </div>
      )}

      {dog?.dogProfile?.avatarUrl ? (
        <Fragment>
          {!pictureUploading && (
            <div className={STYLES.camera}>
              <CameraAction
                fileUploadRef={fileInputRef}
                onFileUploadClick={handlePolaroidClick}
                onFileUploadChange={handleFileSelect}
                loading={loading}
              />
            </div>
          )}
          <div className={STYLES.picture}>
            <img
              src={dog?.dogProfile?.avatarUrl}
              alt={t('dog_profile.profile_picture.image_alt_text', {
                dogName: dog?.name
              })}
              width={355}
              height={355}
            />
          </div>
        </Fragment>
      ) : (
        <div className={STYLES.polaroid}>
          <Interactive element="button" onClick={handlePolaroidClick}>
            <img
              src={
                dog?.dogProfile?.avatarImageAttached
                  ? BrokenPolaroidFrame
                  : PolaroidFrame
              }
              alt={t('dog_profile.profile_picture.image_alt_text', {
                dogName: dog?.name
              })}
              width={220}
              height={260}
              className={STYLES.polaroidFrame}
            />
          </Interactive>
          {!pictureUploading && (
            <div
              className={
                dog?.dogProfile?.avatarImageAttached
                  ? STYLES.brokenCamera
                  : STYLES.camera
              }
            >
              <CameraAction
                fileUploadRef={fileInputRef}
                onFileUploadClick={handlePolaroidClick}
                onFileUploadChange={handleFileSelect}
                loading={loading}
                bgColour="brandBlue400"
                size="small"
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export { DogProfilePicture }
