import { useReactiveVar } from '@apollo/client'
import times from 'lodash/times'
import React, { useCallback } from 'react'

// Components
import Text from '@/components/elements/atoms/Text'
import Faq from '@/components/elements/molecules/Faq/Faq'
// State
import { wizardPageState } from '@/components/pages/SignupWizardPage/SignupWizardPage'

// Styles
import STYLES from './FAQs.module.sass'

import { Code } from '@/types'

type Props = {
  namespace: string
  copyContext: string
}

const FAQs = ({ namespace, copyContext }: Props): JSX.Element => {
  const wizardState = useReactiveVar(wizardPageState)

  const { user } = wizardState
  const { countryCode } = user.location

  const faqRegion = useCallback(() => {
    switch (countryCode) {
      case Code.BE:
        return 'be'
      case Code.NL:
        return 'nl'
      case Code.PL:
        return 'pl'
      case Code.DE:
        return 'de'
      default:
        return 'uk_ie'
    }
  }, [countryCode])

  return (
    <div className={STYLES.faqSection}>
      <Text
        variant="textRegular20"
        bold
        text={`${copyContext}.faq.title`}
        namespace={namespace}
        margin={false}
        align="center"
      />
      {times(7).map((n) => (
        <Faq
          key={n}
          question={{
            text: `${copyContext}.faq.question_${n + 1}.question`,
            namespace
          }}
          answer={{
            text: `${copyContext}.faq.question_${n + 1}.answer`,
            namespace
          }}
        />
      ))}
      <Faq
        question={{
          text: `${copyContext}.faq.question_${8}.question`,
          namespace
        }}
        answer={{
          text: `${copyContext}.faq.question_${8}.answer_${faqRegion()}`,
          namespace,
          variables: {
            url: '/contact'
          }
        }}
      />
    </div>
  )
}

export default FAQs
