// @noflow
import initI18n from '@/packs/localisation'
import type { Language } from '@/packs/localisation'
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import isNil from 'lodash/isNil'
import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import withApollo from '@/components/apollo/withApollo'
import TopNavBanner from '@/components/elements/molecules/TopNavBanner/TopNavBanner'
import SimplifiedPlansPageRouting from '@/components/pages/SimplifiedPlansPage/SimplifiedPlansPageRouting'
import GUEST_QUERY from '@/components/pages/SimplifiedPlansPage/queries/GuestQuery'
import type { SimplifiedGuestQuery } from '@/components/pages/SimplifiedPlansPage/queries/__generated__/SimplifiedGuestQuery'

import { InitialState as WizardState } from '../SignupWizardPage/SignupWizardPage'
import { Routes as PlansRoutes } from './types/routes'

type Props = {
  language: Language
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
  shouldSeeSkipPlanPage: boolean
}

type SelectedProductVariant = {
  id: string
  discountedPrice: number
}

type Flavour = { id: string; slug: string }

type PlanState = {
  flavours: Array<Flavour> | null
  productVariants: Array<SelectedProductVariant>
  planId: string | null
}

const initialState: PlanState = {
  flavours: null,
  productVariants: [],
  planId: null
}

const plansStateVersion = '0.2.0'
const versionStorageKey = 'plansStateVersion'
const versionStorageState = localStorage.getItem(versionStorageKey)
const isSupportedPlansStateVersion = versionStorageState === plansStateVersion

const localStorageKey = 'simplifiedPendingSubscription'
const storageState: string | null = localStorage.getItem(localStorageKey)
const plansPageState = makeVar<PlanState>(
  !isNil(storageState) ? JSON.parse(storageState) : initialState
)

const wizardLocalStorageKey = 'wizardState'
const wizardState: string | null = localStorage.getItem(wizardLocalStorageKey)
const plansPageWizardState = makeVar<WizardState | null>(
  !isNil(wizardState) ? JSON.parse(wizardState) : null
)

const cadenceStorageKey = 'deliveryCadence'
const cadenceState: string | null = localStorage.getItem(cadenceStorageKey)
const deliveryCadenceState = makeVar(
  !isNil(cadenceState) ? JSON.parse(cadenceState) : 'default'
)

const analyticStorageKey = 'plansAnalyticState'
const analyticState: string | null = localStorage.getItem(analyticStorageKey)
const plansPageAnalyticState = makeVar(
  !isNil(analyticState) ? JSON.parse(analyticState) : null
)

const SimplifiedPlansPage = ({
  language,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans,
  shouldSeeSkipPlanPage
}: Props): JSX.Element => {
  const namespace = 'simplified_plans_flow'
  const plansState = useReactiveVar(plansPageState)
  const deliveryCadence = useReactiveVar(deliveryCadenceState)
  const plansAnalyticState = useReactiveVar(plansPageAnalyticState)

  useEffect(() => {
    if (!isSupportedPlansStateVersion) {
      localStorage.setItem('plansStateVersion', plansStateVersion)
    }
    if (!isSupportedPlansStateVersion && storageState) {
      plansPageState(initialState)
      window.location.href = PlansRoutes.Recipes
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(plansState))
  }, [plansState])

  useEffect(() => {
    localStorage.setItem(cadenceStorageKey, JSON.stringify(deliveryCadence))
  }, [deliveryCadence])

  useEffect(() => {
    localStorage.setItem(analyticStorageKey, JSON.stringify(plansAnalyticState))
  }, [plansAnalyticState])

  const { loading, data } = useQuery<SimplifiedGuestQuery>(GUEST_QUERY)

  // TODO: Replace with Loading Screen
  if (loading || !data) {
    return <div />
  }

  if (!i18next.isInitialized) {
    initI18n(language)
  }

  return (
    <>
      {data?.currentUser?.__typename === 'Guest' && (
        <TopNavBanner
          shippingCountryCode={data.currentUser.assumedShippingCountry.code}
          preferredLanguage={data.currentUser.preferredLanguage}
        />
      )}
      <SimplifiedPlansPageRouting
        namespace={namespace}
        hasRecommendedExtras={hasRecommendedExtras}
        shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
        shouldSeeSkipPlanPage={shouldSeeSkipPlanPage}
      />
    </>
  )
}

const SimplifiedPlansPageRouter = ({
  language,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans,
  shouldSeeSkipPlanPage
}: Props): JSX.Element => (
  <Router>
    <SimplifiedPlansPage
      language={language}
      hasRecommendedExtras={hasRecommendedExtras}
      shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
      shouldSeeSkipPlanPage={shouldSeeSkipPlanPage}
    />
  </Router>
)

export {
  initialState,
  plansPageState,
  plansPageWizardState,
  deliveryCadenceState,
  plansPageAnalyticState,
  SelectedProductVariant,
  Flavour
}
export type { PlanState }

export default withApollo(SimplifiedPlansPageRouter)
