import { gql } from '@apollo/client'

// eslint-disable-next-line i18next/no-literal-string
const THANK_YOU_QUERY_V3 = gql`
  query thankYouQueryV3(
    $headerSlug: String!
    $whatsappSingleDogSlug: String!
    $whatsappMultipleDogsSlug: String!
  ) {
    systemShouldShowAppContent: featureFlag(
      flag: "system_should_show_app_content"
      fallback: "not_in_cohort"
    )
    user {
      id
      shippingCountryCode
      preferredLanguage
      dogs {
        gender
        name
        id
        ageInMonths
        dogProfile {
          id
          avatarUrl
          description
        }
        possessivePronoun
      }
      subscription {
        referralLink {
          referralLinkTypeName
          referralDiscountsTotalValue
          url
          referralDiscount {
            id
            code
            discountCodeParts {
              value
              discountType
              discountBasis
              n
            }
          }
          referrerDiscount {
            applicableDiscountParts {
              value
              discountBasis
              n
              discountType
            }
          }
          referrals {
            refereeFirstName
            refereeDogNames
            refereeSignUpDate
          }
        }
      }
    }

    header: copyEntry(slug: $headerSlug) {
      text
    }
    whatsAppSingleDog: copyEntry(slug: $whatsappSingleDogSlug) {
      text
    }
    whatsAppMultipleDogs: copyEntry(slug: $whatsappMultipleDogsSlug) {
      text
    }
  }
`

export { THANK_YOU_QUERY_V3 }
