// @noflow
import React, { Fragment, useCallback } from 'react'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import { OptionSelector } from '@/components/elements/molecules/OptionSelector'

import STYLES from './FrequencySelector.module.sass'

import type { EditDeliveryFrequencyQuery_user_subscription_plan as Plan } from '../../queries/__generated__/EditDeliveryFrequencyQuery'

import type { FrequencyOption } from '../../utils'

type Props = {
  frequencyOptions: Array<FrequencyOption>
  currentPlan: Plan | null
  chosenPlan: Plan | null
  setChosenPlanId: React.Dispatch<React.SetStateAction<string | null>>
  loading?: boolean
  mutationLoading?: boolean
}

const FrequencySelector = ({
  frequencyOptions,
  chosenPlan,
  currentPlan,
  setChosenPlanId,
  loading = false,
  mutationLoading = false
}: Props): React.JSX.Element => {
  const selectedOption = frequencyOptions.find(
    ({ id }) => id === chosenPlan?.id
  )

  const handleOptionChange = useCallback(
    ({ id }: FrequencyOption) => setChosenPlanId(id),
    [setChosenPlanId]
  )

  return (
    <SectionWrapper
      {...(loading
        ? {}
        : {
            headerTypography: {
              text: 'edit_delivery_frequency.frequency_selector.title',
              namespace: 'account',
              variant: 'textRegular16',
              variables: {
                durationInWeeks: currentPlan?.durationInWeeks ?? 0,
                count: currentPlan?.durationInWeeks ?? 0
              },
              margin: false
            }
          })}
      headerClassName={STYLES.header}
      bgColour="brandYellow200"
      padding={24}
      borderRadius={16}
      margin={{ top: 0, bottom: 0 }}
    >
      <Fragment>
        {loading && (
          <SkeletonParagraph
            align="left"
            count={1}
            shortLine={false}
            width="43%"
            height={17}
            margin="0 0 .8rem"
          />
        )}
        <OptionSelector
          options={frequencyOptions}
          selectedOption={selectedOption}
          onOptionChange={handleOptionChange}
          loading={loading}
          disabled={mutationLoading}
        />
      </Fragment>
    </SectionWrapper>
  )
}

export { FrequencySelector }
