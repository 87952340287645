// @noflow
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import ShareModalButton from '@/components/elements/molecules/ShareModalButton/ShareModalButton'

type Props = {
  copyLinkMessageNative: string
}

const Referal = ({ copyLinkMessageNative }: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const [allowedNativeShare, setAllowedNativeShare] = useState(false)
  const isMobile = windowWidth < BREAKPOINTS.sm

  const shareData = useMemo(() => {
    return {
      text: copyLinkMessageNative
    }
  }, [copyLinkMessageNative])

  const showNativeShare = useCallback(async () => {
    navigator
      .share(shareData)
      .then(() => {
        segmentTrack('Referral Link Copy Share Event', {
          location: 'Thank You',
          method: 'Native Share'
        })
      })
      .catch((error) => {
        segmentTrack('Referral Link Copy Share Event Failed', {
          location: 'Thank You',
          method: 'Native Share',
          error: `${error}`
        })
      })
  }, [shareData])

  useEffect(() => {
    setAllowedNativeShare(isMobile && !!navigator.canShare(shareData))
  }, [isMobile, shareData])

  if (allowedNativeShare) {
    return (
      <Button
        typography={{
          text: 'refer_a_friend.give_and_get_tab.give_and_get_card.cta',
          namespace: 'dashboard'
        }}
        fullWidth
        onClick={showNativeShare}
        identifier="native_share.share_code_button"
        screenIdentifier="thank_you_page_v3"
      />
    )
  } else {
    return (
      <ShareModalButton
        namespace="dashboard"
        buttonText="refer_a_friend.give_and_get_tab.give_and_get_card.cta"
        partialLocation="Thank You"
      />
    )
  }
}

export default Referal
